import React from "react";
import { Toolbar, IconButton, Box, Typography } from "@mui/material";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import { AppName } from "../utils/Constants"
const Header = () => {
  return (
    <Box>
      <Toolbar>
        <IconButton>
          <BusinessCenterIcon sx={{ fill: "#e07f7e" }} size="large" />
        </IconButton>
        <Typography fontSize="1.5rem" fontWeight="bold">
          {AppName}
        </Typography>
      </Toolbar>
    </Box>
  );
};

export default Header;
