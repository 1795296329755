import { Box, List, Typography } from "@mui/material";
import React from "react";
import Logo from "./Logo";
import NavItem from "./NavItem";
import ProfileCard from "../../components/ProfileCard";
import styled from "@emotion/styled";
import theme from "../../Themes/Default";
import { adminNavItems } from "../../utils/adminNavitems";
import { waiterNavItems } from "../../utils/waiterNavItems";
import { shiftManagerNavitems } from "../../utils/shiftManagerNavItems";

const StyledSidebar = styled(Box)({
  backgroundColor: theme.palette.background.main,
  borderRight: "2px solid",
  borderColor: theme.palette.background.dark,
  padding: "0.75rem",
  gridRow: "1 / -1",
  height: "100vh",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
});

export const SideBar = () => {
  const user = JSON.parse(localStorage.getItem("user"));

  let navitems = [];
  if (
    user.userRole.toLowerCase() === "waiter" ||
    user.userRole.toLowerCase() === "waitress"
  ) {
    navitems = waiterNavItems;
  } else if (user.userRole.toLowerCase() === "administrator") {
    navitems = adminNavItems;
  } else {
    navitems = shiftManagerNavitems;
  }
  return (
    <StyledSidebar component={"aside"}>
      <Box direction={"column"}>
        <Box>
          <Logo />
        </Box>
        <Box>
          <List>
            {navitems.map((item) =>
              item.id < 8 ? (
                <NavItem
                  key={item.id}
                  name={item.name}
                  icon={item.icon}
                  path={item.path}
                  childName={item.childName}
                />
              ) : (
                ""
              )
            )}
          </List>
        </Box>
        <Box>
          {navitems.length > 8 ? (
            <Typography
              variant="subtitle1"
              sx={{
                color: "gray",
                fontSize: "1rem",
              }}
            >
              Others
            </Typography>
          ) : (
            <></>
          )}
          <List>
            {navitems.map((item) =>
              item.id >= 8 ? (
                <NavItem
                  key={item.id}
                  name={item.name}
                  icon={item.icon}
                  path={item.path}
                />
              ) : (
                ""
              )
            )}
          </List>
        </Box>
      </Box>
      <Box
        gap={2}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      >
        {/* profile card */}
        <ProfileCard />
        {/* footer */}
        <Typography
          sx={{ color: theme.palette.text.light, fontSize: "1rem" }}
          variant="span"
        >
          &copy; {new Date().getFullYear()} SmartPOS App
        </Typography>
      </Box>
    </StyledSidebar>
  );
};
