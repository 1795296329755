import { Box, Stack } from "@mui/material";
import React from "react";
import Sales from "./Sales";
import Income from "./Income";
import Orders from "./Orders";
import Customers from "./Customers";

function ChartSection() {
  return (
    <Box
      sx={{
        height: "45%",
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "5px",
        gap: "20px",
      }}
      component="section"
    >
      <Sales />
      <Stack
        direction="row"
        flex={1}
        sx={{ height: "100%", width: "100%" }}
        gap="20px"
        alignItems="center"
      >
        <Income />
        <Stack direction="column" flex={0.6} sx={{ height: "100%" }} gap="20px">
          <Orders />
          <Customers />
        </Stack>
      </Stack>
    </Box>
  );
}

export default ChartSection;
