import { createTheme } from "@mui/material";

const theme = createTheme({
  breakpoints: {
    values: {
      sm: 600,
      md: 992,
      lg: 1200,
      xl: 1440,
      xxl: 1536,
      xxxl: 1920,
    },
  },
  palette: {
    primary: {
      main: "#FFCA40",
    },
    secondary: {
      main: "#C8161D",
    },
    text:{
      main: '#000000',
      light: '#808080'
    },
    accent: {
      main: "#BAF1DC",
      dark: "#74AF9B",
      light: "#D9F4FB",
    },
    background: {
      main: "#FFFFFF",
      dark: "#F8F8F7",
    },
  },
  typography: {
    h1: {
      fontSize: "1.5rem",
    },
    h2: {
      fontSize: "1.25rem",
    },
    h3: {
      fontSize: "1.15rem",
    },
    h4: {
      fontSize: "1rem",
    },
    h5: {
      fontSize: "0.75rem",
    },
    h6: {
      fontSize: "0.5rem",
    },
    body1:{
      fontWeight:"regular"
    },
    body2:{
      fontWeight:"medium"
    },
    fontFamily: ["Gilroy", "san-serif"].join(","),
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
      },
    },
  },
});

export default theme;
