import { Avatar, Box, Stack, Typography } from "@mui/material";
import theme from "../../../Themes/Default";

export const ProductIngredient = ({ name, price, quantity, imageUrl }) => {
  return (
    <Box
      sx={{
        display: "flex",
        border: "2px solid",
        borderColor: theme.palette.background.dark,
        alignItems: "center",
        justifyContent: "space-between",
        width: "20rem",
        height: "4rem",
        borderRadius: "10px",
      }}
    >
      <Avatar
        sx={{ borderRadius: "10px", height: "100%", width: "auto" }}
        src={`${imageUrl}`}
        alt={name}
      />
      <Stack>
        <Typography variant="h3" sx={{ textTransform: "capitalize" }}>
          {name}
        </Typography>
        <Typography variant="h2" sx={{ fontWeight: "bold" }}>
          Price : {price}
        </Typography>
      </Stack>
      <Box
        sx={{
          border: "2px solid",
          borderColor: theme.palette.primary.main,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "0.25rem",
          padding: "0.125rem",
        }}
      >
        <Typography variant="h3" sx={{ fontWeight: "bold" }}>
          {quantity}
        </Typography>
      </Box>
    </Box>
  );
};
