import toast from "react-hot-toast";
import { DeleteModal } from "../../features";
import { useDeleteCategoryMutation } from "../../store/apiSlice";
import { useDispatch } from "react-redux";
import { setDeleteModalClose } from "../../store/admin/shared/deleteModal";

export const DeleteCategory = ({ handleClose, open, category }) => {
  const [deleteCategory] = useDeleteCategoryMutation();
  const dispatch = useDispatch();
  const handleDelete = async () => {
    try {
      const result = await deleteCategory({ id: category.id });
      toast.loading("Deleting category...");
      if (result.data.status.toLowerCase() === "success") {
        dispatch(setDeleteModalClose());
        toast.dismiss();
        toast.success("Category deleted successfully.");
      } else {
        throw new Error("Unable to delete category");
      }
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <DeleteModal
      handleClose={handleClose}
      handleDelete={handleDelete}
      open={open}
      item={category.name}
    />
  );
};
