import { Box } from "@mui/material";
import { Puff } from "react-loader-spinner";

export function Loader() {
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Puff
        visible={true}
        height="80"
        width="80"
        color="#FFCA40"
        ariaLabel="puff-loading"
        wrapperStyle={{}}
        wrapperClass=""
      />
    </Box>
  );
}
