import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  //   name: null,
  //  token: null,
  data: null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUser: (state, action) => {
      localStorage.setItem(
        "user",
        JSON.stringify({
          userToken: action.payload.token,
          userIdentification: action.payload.id,
          userRole: action.payload.role,
          hotelId: action.payload.hotelId,
        })
      );
      state.data = action.payload;
      //   state.token = action.payload.token;
      // state = action.payload;
      //   state.lastName = action.payload.lastName;
      //   state.role = action.payload.role;
    },
    logoutUser: (state, action) => {
      state.data = null;
      localStorage.clear();
    },
  },
});

export const selectAuth = (RootState) => RootState.auth;

export const { setUser, logoutUser } = authSlice.actions;

export default authSlice.reducer;
