import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import theme from "./Themes/Default";
import { ThemeProvider, CssBaseline } from "@mui/material";
import { store } from "./store";
import { Provider } from "react-redux";
import { Toaster } from "react-hot-toast";
import App from "./App";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Toaster />
        <App />
      </ThemeProvider>
    </Provider>
  </>
);
