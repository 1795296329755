import { Box, Stack } from "@mui/material";
import React from "react";
import { AdminLayout } from "../../../layout";
import Form from "./Form";
import IngredientPreview from "./IngredientPreview";

export function NewIngredient() {
  return (
    <AdminLayout title="New Ingredient">
      <Stack direction="row" justifyContent="center">
        <Box sx={{ width: "40%" }}>
          <Form />
        </Box>
        <Box sx={{ width: "30%" }}>
          <IngredientPreview />
        </Box>
      </Stack>
    </AdminLayout>
  );
}
