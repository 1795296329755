import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { setUser } from "../../store/authSlice.js";
import {
  Box,
  Link,
  TextField,
  Typography,
  IconButton,
  InputAdornment,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import SmartPOSContainedButton from "./components/Buttons/SmartPOSContainedButton";
import { useDispatch } from "react-redux";
import { loginPageStyles } from "./assets/Jss/LoginpageStyles.js";
import { useLoginUserMutation } from "../../store/apiSlice.js";
import toast from "react-hot-toast";
import { routesEnum } from "../../utils/routes.js";

const LoginForm = () => {
  const { classes } = loginPageStyles();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const navigate = useNavigate();
  const initialState = {
    email: "",
    password: "",
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  //login user
  const [
    loginUser,

    {
      isLoading: isloggingLoading,
      data: loginData,
      isSuccess: isLoginSuccess,
      isError: isLoginError,
      error: loginError,
    },
  ] = useLoginUserMutation();
  const validationSchema = Yup.object().shape({
    email: Yup.string().email("please enter valid email").required("Required"),
    password: Yup.string()
      .required("Required")
      .min(8, "Password must have at least 8 characters"),
  });
  const handleUserLogin = async (values, props) => {
    if (values) {
      const email = values.email;
      const password = values.password;
      //  const res = await  loginUser({ email, password });
      //  console.log('res',JSON.parse(res))
      await loginUser({ email, password });
    }
  };
  useEffect(() => {
    if (isLoginError) {
      toast.error(loginError.message);
    }
    // eslint-disable-next-line
  }, [isLoginError]);
  useEffect(() => {
    if (isLoginSuccess) {
      if (loginData.message === "Change Initial Password") {
        navigate(routesEnum.RESET_PASSWORD);
        toast.error("Kindly reset your initial password ", {
          toastId: "error1",
        });
      }
      // useEffect(() => {
      //   if (loginData.message === "Change Initial Password") {
      //     navigate("/resetpassword");
      //     toast.error("Kindly reset your initial password ");
      //   }
      // }, [loginData.message === "Change Initial Password"]);

      if (loginData.message === "Login Successfull") {
        dispatch(setUser(loginData.details));
        toast.success("User logged in successfully", {
          toastId: "success1",
        });
        navigate(routesEnum.DASHBOARD);
      }
    }
    // eslint-disable-next-line
  }, [isLoginSuccess]);

  //   useEffect(() => {
  //     if (isLoginSuccess) {
  //       toast.success("User logged in successfully");
  //     }
  //   }, [isLoginSuccess]);

  return (
    <Box sx={{ width: "100%" }}>
      <Typography fontSize="1.5rem" fontWeight="bold">
        Welcome Back!
      </Typography>
      <Typography color="textSecondary" fontSize="1rem">
        Please sign in to continue
      </Typography>
      <Formik
        initialValues={initialState}
        onSubmit={handleUserLogin}
        validationSchema={validationSchema}
      >
        {(props) => (
          <Form>
            <Field
              as={TextField}
              variant="outlined"
              margin="dense"
              fullWidth
              id="email"
              error={props.errors.email}
              name="email"
              helperText={<ErrorMessage name="email" />}
              className={classes.loginInput}
              placeholder="Email"
            />
            <Field
              as={TextField}
              variant="outlined"
              margin="dense"
              error={props.errors.password}
              helperText={<ErrorMessage name="password" />}
              fullWidth
              name="password"
              id="loginPass"
              type={showPassword ? "text" : "password"}
              className={classes.loginInput}
              placeholder="Password"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <SmartPOSContainedButton
              color="primary"
              disableRipple
              disableElevation
              loadingIndicator="Loading…"
              variant="contained"
              className={classes.loginBtn}
              loading={isloggingLoading}
              type="submit"
            >
              <Typography fontWeight="bold">Sign in</Typography>
            </SmartPOSContainedButton>
          </Form>
        )}
      </Formik>
      <Box className={classes.forgotPassBox} textAlign="center">
        <Link
          href="#"
          sx={{ color: "#1976d2", textDecoration: "none" }}
          variant="body2"
          onClick={() => {
            navigate(routesEnum.RESET_PASSWORD_TOKEN);
          }}
        >
          Reset Password
        </Link>
      </Box>
      <Box marginY={2} textAlign="center">
        <Typography variant="body2" color="textSecondary">
          Don't have an account?
          <Link
            sx={{
              color: "#1976d2",
              textDecoration: "none",
              cursor: "pointer",
            }}
            variant="body2"
            onClick={() => {
              navigate(routesEnum.REGISTER);
            }}
          >
            Go to Registration
          </Link>
        </Typography>
      </Box>
      <Box marginY={2} textAlign="center">
        <Typography variant="body2" color="textSecondary">
          Already have an account?
          <Link
            sx={{
              color: "#1976d2",
              textDecoration: "none",
              cursor: "pointer",
            }}
            variant="body2"
            onClick={() => {
              navigate(routesEnum.LOGIN);
            }}
          >
            Sign in
          </Link>
        </Typography>
      </Box>
    </Box>
  );
};

export default LoginForm;
