import React from "react";

import ChartSection from "./components/ChartSection";
import HighlightsSection from "./components/HighlightsSection";
import { Box, Stack, Tab, Tabs, Typography } from "@mui/material";
import styled from "@emotion/styled";
import theme from "../../Themes/Default";
import { useDispatch, useSelector } from "react-redux";
import { changeFilter } from "../../store/dashboardSlice";

const CustomTab = styled(Tab)({
  color: "gray",
  fontSize: "0.75rem",
  textTransform: "none",
});

const CustomTabs = styled(Tabs)({
  borderBottom: "1px solid #e8e8e8",
  "& .MuiTabs-indicator": {
    backgroundColor: theme.palette.primary.main,
  },
});

export function TimePeriodTabs() {
  const filter = useSelector((state) => state.dashboard.filter);
  const dispatch = useDispatch();
  const [value, setValue] = React.useState(filter);

  const handleChange = (event, newValue) => {
    dispatch(changeFilter(newValue));
    setValue(newValue);
  };

  return (
    <Box>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          backgroundColor: theme.palette.background.main,
        }}
      >
        <CustomTabs
          indicatorColor="none"
          value={value}
          onChange={handleChange}
          aria-label="TimePeriodTabs"
        >
          <CustomTab label="Yesterday" />
          <CustomTab label="Today" />
          <CustomTab label="Week" />
          <CustomTab label="Month" />
          <CustomTab label="Year" />
        </CustomTabs>
      </Box>
    </Box>
  );
}

function Dashboard() {
  return (
    <Box sx={{width: '100%', height: '100%', display: 'flex', flexDirection: 'column', gap: '10px'}}>
      {/* <Header /> */}
      <Stack direction="column" width="100%">
        <Stack
          direction="row"
          sx={{ justifyContent: "space-between", alignItems: "center" }}
        >
          <Typography variant="h4" fontWeight="600">
            Dashboard
          </Typography>
          <TimePeriodTabs />
        </Stack>
      </Stack>
      <ChartSection />
      <HighlightsSection />
    </Box>
  );
}

export default Dashboard;
