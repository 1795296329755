import React from "react";
import { createBrowserRouter } from "react-router-dom";
import { Layout } from "../layout";
import userProfileLoader from "../pages/userprofile/components/UserProfileLoader";
import ProtectedRoutes from "./protectedRoutes";
import LoginPage from "../pages/login/LoginPage";
import LoginForm from "../pages/login/LoginForm";
import RegisterForm from "../pages/login/RegisterForm";
import ResetPassToken from "../pages/login/ResetPassToken";
import ResetPassword from "../pages/login/ResetPassword";
import ResetWithToken from "../pages/login/ResetWithToken";
import UserProfile from "../pages/userprofile";
import Dashboard from "../pages/dashboard";
import FoodAndDrinks from "../pages/foodAndDrinks";
import UserAdmin from "../pages/useradmin";
import userAdminLoader from "../pages/useradmin/UserAdminLoader";
import Orders from "../pages/orders";
import Settings from "../pages/setings";
import Support from "../pages/support";
import ErrorBoundary from "../components/ErrorBoundary";
import Category from "../pages/category";
import Menu from "../pages/menu";
import Transactions from "../pages/transactions";
import Invoices from "../pages/invoices";
import RestaurantProfile from "../pages/restaurantProfile";
import { SpecialMenu } from "../pages/specialMenu";
import { Shifts } from "../pages/shifts";
import { Payments } from "../pages/payments";
import { routesEnum } from "../utils/routes";
import NewCategory from "../pages/category/new-category/NewCategory";
import { IngredientsListHome, NewIngredient } from "../pages/ingredients";
import IngredientDetails from "../pages/ingredients/ingredient/IngredientDetails";
import NewProduct from "../pages/products/new-products/NewProduct";
import Products from "../pages/products";
import Product from "../pages/products/product-details/Product";
import { EditProduct } from "../pages/products/product-details/EditProduct";
import { AddProductImage } from "../pages/products/product-details/AddProductImage";
import { AddIngredientImage } from "../pages/ingredients/ingredient/AddIngredientImage";
import { EditCategory } from "../pages/category/edit-category/EditCategory";
import { OrderDetails } from "../pages/orders/order-details";

const router = createBrowserRouter([
  {
    element: <LoginPage />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        path: routesEnum.LOGIN,
        element: <LoginForm />,
      },
      {
        path: routesEnum.REGISTER,
        element: <RegisterForm />,
      },
      {
        path: routesEnum.RESET_PASSWORD,
        element: <ResetPassword />,
      },
      {
        path: routesEnum.RESET_PASSWORD_TOKEN,
        element: <ResetPassToken />,
      },
      {
        path: routesEnum.RESET_PASSWORD_WITH_TOKEN,
        element: <ResetWithToken />,
      },
    ],
  },
  {
    element: <ProtectedRoutes />,
    children: [
      {
        element: <Layout />,
        children: [
          {
            path: routesEnum.DASHBOARD,
            element: <Dashboard />,
          },
          {
            path: routesEnum.FOOD_AND_DRINKS,
            element: <FoodAndDrinks />,
          },
          {
            path: routesEnum.ORDERS,
            element: <Orders />,
          },
          { path: routesEnum.SPECIAL_MENU, element: <SpecialMenu /> },
          { path: routesEnum.SHIFTS, element: <Shifts /> },
          {
            path: routesEnum.DASHBOARD,
            element: <Dashboard />,
          },
          {
            path: routesEnum.USERS,
            loader: userAdminLoader,
            element: <UserAdmin />,
          },
          {
            path: routesEnum.DASHBOARD,
            element: <Dashboard />,
          },

          {
            path: routesEnum.ALL_CATEGORIES,
            element: <Category />,
          },
          {
            path: routesEnum.NEW_CATEGORY,
            element: <NewCategory />,
          },
          {
            path: routesEnum.EDIT_CATEGORY,
            element: <EditCategory />,
          },
          {
            path: routesEnum.ALL_PRODUCTS,
            element: <Products />,
          },
          {
            path: routesEnum.NEW_PRODUCT,
            element: <NewProduct />,
          },
          {
            path: routesEnum.PRODUCT_DETAILS,
            element: <Product />,
          },
          {
            path: routesEnum.PRODUCT_IMAGE_UPLOAD,
            element: <AddProductImage />,
          },
          {
            path: routesEnum.EDIT_PRODUCT,
            element: <EditProduct />,
          },
          {
            path: routesEnum.ALL_INGREDIENTS,
            element: <IngredientsListHome />,
          },
          {
            path: routesEnum.NEW_INGREDIENT,
            element: <NewIngredient />,
          },
          {
            path: routesEnum.INGREDIENT_DETAILS,
            element: <IngredientDetails />,
          },
          {
            path: routesEnum.INGREDIENT_IMAGE_UPLOAD,
            element: <AddIngredientImage />,
          },
          {
            path: routesEnum.MENU,
            element: <Menu />,
          },
          {
            path: routesEnum.TRANSACTIONS,
            element: <Transactions />,
          },
          {
            path: routesEnum.INVOICES,
            element: <Invoices />,
          },
          {
            path: routesEnum.RESTAURANT_PROFILE,
            element: <RestaurantProfile />,
          },
          {
            path: routesEnum.SUPPORT,
            element: <Support />,
          },
          {
            path: routesEnum.SETTINGS,
            element: <Settings />,
          },
          { path: routesEnum.ORDERS, element: <Orders /> },
          { path: routesEnum.ORDER_DETAILS, element: <OrderDetails /> },

          { path: routesEnum.DASHBOARD, element: <Dashboard /> },
          { path: routesEnum.SHIFTS, element: <Shifts /> },
          { path: routesEnum.PAYMENTS, element: <Payments /> },

          {
            path: routesEnum.USER_PROFILE,
            loader: userProfileLoader,
            element: <UserProfile />,
          },
        ],
      },
    ],
  },
]);

export default router;
