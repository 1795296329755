import React, { useEffect, useState } from "react";

import {
  Box,
  TextField,
  Typography,
  IconButton,
  InputAdornment,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";
import SmartPOSContainedButton from "./components/Buttons/SmartPOSContainedButton";
import { loginPageStyles } from "./assets/Jss/LoginpageStyles";
import { useResetPasswordMutation } from "../../store/apiSlice.js";
import { useNavigate } from "react-router-dom/dist/index.js";
const ResetWithToken = () => {
  const { classes } = loginPageStyles();
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
 const navigate = useNavigate()
  const initialState = {
    newPassword: "",
    confirmPassword: "",
    email: "",
    resetToken:""
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  //validation
  const validationSchema = Yup.object().shape({
    newPassword: Yup.string()
      .required("Required")
      .min(8, "Password must have at least 8 characters"),
    confirmPassword: Yup.string()
      .required("Required")
      .oneOf([Yup.ref("newPassword")], "Passwords do not match")
      .min(8, "Password must have at least 8 characters"),
    email: Yup.string().email("please enter valid email").required("Required"),
    resetToken: Yup.string()
      .required("Required")
  });
  //reset password
  const [
    resetPassword,
    {
      isLoading: isresetPasswordLoading,
      isSuccess: isresetPasswordSuccess,
      isError: isresetPasswordError,
      error: resetPasswordError,
    },
  ] = useResetPasswordMutation();

  const handleResetPassword = async (values, props) => {
    if (values) {
      const newPassword = values.newPassword;
      const confirmPassword = values.confirmPassword;
      const email = values.email;
      const resetToken = values.resetToken;
      await resetPassword({
        newPassword,
        confirmPassword,
        email,
        resetToken
      });
    }
  };
  useEffect(() => {
    if (isresetPasswordSuccess) {
      toast.success("Password reset successfully");
      navigate('/')
    }
  }, [isresetPasswordSuccess]);
  useEffect(() => {
    if (isresetPasswordError) {
      toast.error(resetPasswordError.data.message);
    }
  }, [isresetPasswordError]);

  return (
    <Box sx={{ width: "100%" }}>
      <Typography fontSize="1.5rem" fontWeight="bold">
        Reset Password
      </Typography>
      <Typography color="textSecondary" fontSize="1rem">
        Reset your password
      </Typography>
      <Formik
        initialValues={initialState}
        onSubmit={handleResetPassword}
        validationSchema={validationSchema}
      >
        {(props) => (
          <Form>
            <Field
              as={TextField}
              variant="outlined"
              margin="dense"
              error={props.errors.newPassword}
              helperText={<ErrorMessage name="newPassword" />}
              name="newPassword"
              fullWidth
              id="resetPass"
              type={showPassword ? "text" : "password"}
              className={classes.loginInput}
              placeholder="newPassword"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Field
              as={TextField}
              variant="outlined"
              margin="dense"
              error={props.errors.confirmPassword}
              helperText={<ErrorMessage name="confirmPassword" />}
              name="confirmPassword"
              fullWidth
              type={showPassword ? "text" : "password"}
              className={classes.loginInput}
              placeholder="Confirm Password"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Field
              as={TextField}
              variant="outlined"
              margin="dense"
              fullWidth
              name="email"
              error={props.errors.email}
              helperText={<ErrorMessage name="email" />}
              className={classes.loginInput}
              placeholder="Email"
            />
            <Field
              as={TextField}
              variant="outlined"
              margin="dense"
              error={props.errors.resetToken}
              helperText={<ErrorMessage name="resetToken" />}
              name="resetToken"
              fullWidth
              id="resetToken"
              className={classes.loginInput}
              placeholder="resetToken"
            />

            <SmartPOSContainedButton
              color="primary"
              disableRipple
              loading={isresetPasswordLoading}
              loadingIndicator="Loading…"
              disableElevation
              className={classes.loginBtn}
              type="submit"
            >
              <Typography fontWeight="bold">Submit</Typography>
            </SmartPOSContainedButton>
          </Form>
        )}
      </Formik>
      {/* <Box className={classes.forgotPassBox} textAlign="center">
        <Link
          href="#"
          sx={{ color: "#1976d2", textDecoration: "none" }}
          variant="body2"
          onClick={() => {}}
        >
          Reset Password
        </Link>
      </Box>
      <Box marginY={2} textAlign="center">
        <Typography variant="body2" color="textSecondary">
          Don't have an account?
          <Link
            sx={{
              color: "#1976d2",
              textDecoration: "none",
              cursor: "pointer",
            }}
            variant="body2"
            onClick={() => {}}
          >
            Go to Registration
          </Link>
        </Typography>
      </Box>
      <Box marginY={2} textAlign="center">
        <Typography variant="body2" color="textSecondary">
          Already have an account?
          <Link
            sx={{
              color: "#1976d2",
              textDecoration: "none",
              cursor: "pointer",
            }}
            variant="body2"
            onClick={() => {}}
          >
            Sign in
          </Link>
        </Typography>
      </Box> */}
    </Box>
  );
};

export default ResetWithToken;
