import { Stack, Typography } from "@mui/material";
import { CustomButton, CustomModal } from "../../components";

export const DeleteModal = ({ handleClose, open, item, handleDelete }) => {
  return (
    <CustomModal handleClose={handleClose} open={open}>
      <Stack
        sx={{
          padding: "1rem",
          alignItems: "center",
          justifyContent: "space-between",
          height: "200px",
        }}
      >
        <Typography variant="h2" sx={{ display: "flex" }}>
          Are you sure you want to delete
          <span
            style={{
              color: "red",
              fontWeight: "700",
              paddingLeft: "1rem",
              textTransform: "capitalize",
            }}
          >
            {item ? item : ""}
          </span>
          ?
        </Typography>
        <Stack direction={"row"} gap={4}>
          <CustomButton
            onClick={handleClose}
            variant="contained"
            color="success"
          >
            Cancel
          </CustomButton>
          <CustomButton
            onClick={handleDelete}
            variant="contained"
            color="error"
          >
            Confirm
          </CustomButton>
        </Stack>
      </Stack>
    </CustomModal>
  );
};
