import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  open: false,
};

const importProductsModalSlice = createSlice({
  name: "importProductsModal",
  initialState,
  reducers: {
    setOpen: (state) => {
      state.open = true;
    },
    setClose: (state) => {
      state.open = false;
    },
  },
});

export const { setOpen, setClose } = importProductsModalSlice.actions;
export default importProductsModalSlice.reducer;
