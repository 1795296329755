import { Button } from "@mui/material";
import styled from "@emotion/styled";
import theme from "../../Themes/Default";

const StyledButton = styled(Button)({
  width: "max-content",
  boxShadow: "none",
  padding: "5px 15px",
  borderRadius: "8px",
  fontWeight: "600",
  fontSize: "1.2rem",
  gap: "10px",
  "&:hover": {
    backgroundColor: theme.palette.background.main,
    color: theme.palette.text.main,
  },
});

export function CustomButton(props) {
  return <StyledButton {...props}>{props.children}</StyledButton>;
}
