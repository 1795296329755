import Unauthorized from "../pages/login/assets/401 Error Unauthorized-cuate.svg";
import { Box } from "@mui/material";
import { NavLink, Outlet } from "react-router-dom";

const ProtectedRoutes = () => {
  const storedUserData = localStorage.getItem("user");
  const userInfo = storedUserData ? JSON.parse(storedUserData) : {};
  if (!userInfo.userToken) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        sx={{ maxHeight: "100vh" }}
      >
        <span>
          <NavLink to="/" sx={{ color: "blue", textDecoraion: "none" }}>
            Login
          </NavLink>{" "}
          to gain access
        </span>
        <Box sx={{ height: "50vh", width: "50vw" }}>
          <img src={Unauthorized} alt="access denied" />
        </Box>
      </Box>
    );
  }
  return <Outlet />;
};
export default ProtectedRoutes;
