import { Box, Button, Stack } from "@mui/material";
import InputBase from "@mui/material/InputBase";
import FilterListIcon from "@mui/icons-material/FilterList";
import DownloadIcon from "@mui/icons-material/Download";
import SearchIcon from "@mui/icons-material/Search";
import { styled, alpha } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import theme from "../../Themes/Default";
import { categoryItems } from "./categoryItems";
import { CustomButton } from "../../components";
import { CategoryDetailsModal } from "./category-details/CategoryDetailsModal";
import { handleExportCategoriesData } from "../../utils/exportCategoriesData";
import { CategoriesList } from "./CategoriesList";
import { useCategoriesListQuery } from "../../store/apiSlice";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  border: "2px solid",
  borderColor: theme.palette.primary.main,
  borderRadius: "20px",
  backgroundColor: alpha(theme.palette.background.main, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.background.main, 0.25),
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  width: "100%",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    color: theme.palette.primary.main,
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
  },
}));
const ProductsCategories = () => {
  const [categoryItems, setCategoryItems] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleSetCategoryItems = (items) => {
    setCategoryItems(items);
  };
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };
  const hotelId = JSON.parse(localStorage.getItem("user")).hotelId;
  const { data, isSuccess, isLoading, refetch } = useCategoriesListQuery({
    hotelId: hotelId,
  });

  useEffect(() => {
    refetch();
  }, [open]);
  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isSuccess) {
    const categories = data.$values;

    return (
      <>
        <Stack gap={2}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box>
              <CustomButton
                edge="start"
                sx={{ mr: 2 }}
                variant="contained"
                endIcon={<DownloadIcon />}
                onClick={() => handleExportCategoriesData(categories)}
              >
                Export
              </CustomButton>
              <Button endIcon={<FilterListIcon />} variant="outlined">
                Filter
              </Button>
            </Box>
            <Search>
              <SearchIconWrapper>
                <SearchIcon sx={{ color: theme.palette.primary.main }} />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder="Search Categories"
                inputProps={{ "aria-label": "search" }}
                onChange={handleSearchChange}
              />
            </Search>
          </Box>
          <CategoriesList categories={categories} handleOpen={handleOpen} />
          <CategoryDetailsModal
            open={open}
            handleClose={handleClose}
            categoryItems={categoryItems}
            // handleSetCategoryItems={handleSetCategoryItems}
          />
        </Stack>
      </>
    );
  }
};

export default ProductsCategories;
