import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  open: false,
};

const editProductModalSlice = createSlice({
  name: "editProductModalSlice",
  initialState,
  reducers: {
    setEditModalOpen: (state) => {
      state.open = true;
    },
    setEditModalClose: (state) => {
      state.open = false;
    },
  },
});

export const { setEditModalClose, setEditModalOpen } =
  editProductModalSlice.actions;
export default editProductModalSlice.reducer;
