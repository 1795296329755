import React from "react";
import { AdminLayout } from "../../layout";
import AddIcon from "@mui/icons-material/Add";
import { Typography } from "@mui/material";
import { useNavigate } from "react-router";
import { CustomButton } from "../../components";
import { routesEnum } from "../../utils/routes";
import ProductsList from "./products-list/ProductsList";

const ButtonComponent = () => {
  const navigate = useNavigate();
  return (
    <CustomButton
      variant="contained"
      onClick={() => navigate(routesEnum.NEW_PRODUCT)}
    >
      <AddIcon /> <Typography>New Product</Typography>
    </CustomButton>
  );
};

const Products = () => {
  return (
    <AdminLayout title={"Products"} ButtonComponent={ButtonComponent}>
      <ProductsList />
    </AdminLayout>
  );
};

export default Products;
