import { Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router";
import { CustomButton } from "../../../components";
import AddIcon from "@mui/icons-material/Add";
import { useDispatch } from "react-redux";
import { setOpen } from "../../../store/admin/products/importProductsModal";
import { routesEnum } from "../../../utils/routes";

export function ButtonComponent() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return (
    <Stack direction="row" spacing={2}>
      <CustomButton variant="outlined" onClick={() => dispatch(setOpen())}>
        Import
      </CustomButton>
      <CustomButton
        variant="contained"
        onClick={() => navigate(routesEnum.NEW_INGREDIENT)}
      >
        <AddIcon /> <Typography>New</Typography>
      </CustomButton>
    </Stack>
  );
}
