import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
  Stack,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import moment from "moment/moment";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import {
  setDeleteModalClose,
  setDeleteModalOpen,
} from "../../store/admin/shared/deleteModal";
import { DeleteCategory } from "./DeleteCategory";
import { useState } from "react";

export const CategoriesList = ({
  categories,
  handleOpen,
  handleSetCategoryItems,
}) => {
  const [selectedCategory, setSelectedCategory] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isDeleteModalOpen = useSelector((state) => state.deleteModal.open);
  const handleDeleteModalClose = () => {
    dispatch(setDeleteModalClose());
  };
  return (
    <>
      <Stack
        direction={"row"}
        gap={4}
        justifyContent={"start"}
        sx={{ flexWrap: "wrap" }}
      >
        {categories.map((category) => (
          <Card
            key={category.id}
            sx={{ marginBottom: "20px", width: "25rem", height: "auto" }}
          >
            <CardContent>
              <Typography
                gutterBottom
                variant="h2"
                fontWeight="bold"
                sx={{ textTransform: "capitalize" }}
              >
                {category.name}
              </Typography>
              <Typography>{category.description}</Typography>
              <Box sx={{ marginBottom: "10px", marginTop: "10px" }}>
                <Typography sx={{ fontWeight: "bold" }}>
                  Added date:
                  <span style={{ padding: "0px 10px" }}>
                    {moment(category.updatedOn).format("Do MMM YY")}
                  </span>
                </Typography>
              </Box>
              <CardActions>
                <Box sx={{ marginRight: "50px" }}>
                  <Button
                    disabled
                    onClick={(e) => {
                      handleOpen();
                      // handleSetCategoryItems(category.products.$values);
                    }}
                    variant="contained"
                    sx={{ fontWeight: "bold" }}
                    endIcon={<KeyboardArrowDownOutlinedIcon />}
                  >
                    Products
                    <span style={{ marginLeft: "6px", fontWeight: "bold" }}>
                      {category.products.$values.length}
                    </span>
                  </Button>
                </Box>
                <Box sx={{ marginRight: "10px" }}>
                  <Button
                    variant="outlined"
                    sx={{ color: "#808080", border: "1px solid #808080" }}
                    onClick={() => {
                      navigate(`/categories/${category.id}/edit`);
                    }}
                  >
                    <EditIcon />
                  </Button>
                </Box>
                <Button
                  onClick={() => {
                    setSelectedCategory(category);
                    dispatch(setDeleteModalOpen());
                  }}
                  variant="outlined"
                  sx={{ color: "#808080", border: "1px solid #808080" }}
                >
                  <DeleteIcon />
                </Button>
              </CardActions>
            </CardContent>
          </Card>
        ))}
      </Stack>
      {isDeleteModalOpen && (
        <DeleteCategory
          handleClose={handleDeleteModalClose}
          open={isDeleteModalOpen}
          category={selectedCategory}
        />
      )}
    </>
  );
};
