import React from "react";
import UsersList from "./UsersList";
import RegisterUser from "./RegisterUser";
import { Box } from "@mui/material";
function UserAdmin() {
  return (
    <Box>
      <RegisterUser />
      <UsersList />
    </Box>
  );
}

export default UserAdmin;
