import DashboardIcon from "@mui/icons-material/Dashboard";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import MarkEmailUnreadIcon from "@mui/icons-material/MarkEmailUnread";
import { routesEnum } from "./routes";

export const shiftManagerNavitems = [
  {
    id: 1,
    icon: DashboardIcon,
    name: "Dashboard",
    path: routesEnum.DASHBOARD,
    childName: "sales statistics",
  },
  {
    id: 2,
    icon: MarkEmailUnreadIcon,
    name: "Shifts",
    childName: "Shifts",
    path: routesEnum.SHIFTS,
  },
  {
    id: 3,
    icon: RequestQuoteIcon,
    name: "Orders",
    childName: "Orders",
    path: routesEnum.ORDERS,
  },
  {
    id: 4,
    icon: RequestQuoteIcon,
    name: "Payments",
    childName: "Payments",
    path: routesEnum.PAYMENTS,
  },
];
