export const routesEnum = Object.freeze({
  // Auth
  LOGIN: "/",
  REGISTER: "/register",
  RESET_PASSWORD: "/reset-password",
  RESET_PASSWORD_TOKEN: "/reset-password/get-token",
  RESET_PASSWORD_WITH_TOKEN: "/reset-password-with-token",

  // users
  USERS: "/users/all",
  USER_PROFILE: "/user-profile/:id",

  // CATEGORIES
  ALL_CATEGORIES: "/categories/all",
  CATEGORY_DETAILS: "/categories/:id",
  NEW_CATEGORY: "/categories/new",
  EDIT_CATEGORY: "/categories/:id/edit",
  DELETE_CATEGORY: "/categories/:id/delete",

  // PRODUCTS
  ALL_PRODUCTS: "/products/all",
  PRODUCT_DETAILS: "/products/:id",
  PRODUCT_IMAGE_UPLOAD: "/products/:id/image",
  NEW_PRODUCT: "/products/new",
  EDIT_PRODUCT: "/products/:id/edit",
  DELETE_PRODUCT: "/products/:id/delete",

  // SUB CATEGORIES
  ALL_INGREDIENTS: "/ingredients/all",
  INGREDIENT_DETAILS: "/ingredients/:id",
  INGREDIENT_IMAGE_UPLOAD: "/ingredients/:id/image",
  EDIT_INGREDIENT: "/ingredients/:id/edit",
  NEW_INGREDIENT: "/ingredients/new",
  DELETE_INGREDIENT: "/ingredients/:id/delete",

  // ORDERS
  ORDERS: "/orders/all",
  ORDER_DETAILS: "/orders/:id",

  // OTHERS
  TRANSACTIONS: "/transactions",
  INVOICES: "/invoices",
  RESTAURANT_PROFILE: "/retaurant-profile",
  SUPPORT: "/support",
  SETTINGS: "/settings",
  PAYMENTS: "/payments",
  MENU: "/menu",
  DASHBOARD: "/dashboard",
  FOOD_AND_DRINKS: "/food-and-drinks/all",
  SPECIAL_MENU: "/special-menu",
  SHIFTS: "/shifts",
});
