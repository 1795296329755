import { Avatar, Button, Typography } from "@mui/material";
import theme from "../../../Themes/Default";
import { currency } from "../../../utils/currency";
import { useDispatch, useSelector } from "react-redux";
import { addProduct } from "../../../store/order-slice";

export const ProductCard = ({ product }) => {
  const dispatch = useDispatch();
  // const order = useSelector((state) => state.order);
  const handleProductClick = () => {
    const payload = {
      id: product.id,
      name: product.name,
      unitPrice: product.price,
      quantity: 1,
    };
    dispatch(addProduct(payload));
  };
  return (
    <Button
      sx={{
        width: "12rem",
        height: "16rem",
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        border: "2px solid",
        borderColor: theme.palette.background.dark,
        borderRadius: "1rem",
      }}
      onClick={handleProductClick}
    >
      <Avatar
        sx={{ width: "9rem", height: "9rem" }}
        src={product.productImageUrl}
        alt={product.name}
      />
      <Typography
        sx={{
          fontweight: "bold",
          color: theme.palette.text.light,
          textTransform: "capitalize",
        }}
      >
        {product.name}
      </Typography>
      <Typography sx={{ fontWeight: "bold", color: "black" }}>
        {currency(product.price)}
      </Typography>
    </Button>
  );
};
