import { IconButton, Menu, MenuItem, useTheme } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import React from "react";
import { useNavigate } from "react-router";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import DeleteIcon from "@mui/icons-material/Delete";

export const ActionMenu = ({ row, editRoute, viewRoute, deleteRoute }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (route) => {
    if (route) {
      navigate(route);
    }
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} open={open} onClose={() => handleClose()}>
        {editRoute && (
          <MenuItem
            sx={{ backgroundColor: theme.palette.primary.main }}
            onClick={() => handleClose(editRoute)}
          >
            <ModeEditIcon /> Edit
          </MenuItem>
        )}
        {viewRoute && (
          <MenuItem
            sx={{ backgroundColor: theme.palette.primary.main }}
            onClick={() => handleClose(viewRoute)}
          >
            <RemoveRedEyeIcon /> View
          </MenuItem>
        )}
        {deleteRoute && (
          <MenuItem
            sx={{ backgroundColor: theme.palette.primary.main }}
            onClick={() => handleClose(deleteRoute)}
          >
            <DeleteIcon /> Delete
          </MenuItem>
        )}
      </Menu>
    </>
  );
};
