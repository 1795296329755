import React from "react";
import { Button } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
const SmartPOSContainedButton = ({ children, ...rest }) => {
  return (
    <LoadingButton
      {...rest}
      
      sx={{
        width: "100%",
        ":hover": {
          bgcolor: "white", // theme.palette.primary.main
          border: "3px solid #FFCA40",
          // color: "white",
          
        },
      }}
      size="large"
      variant="contained"
    >
      {children}
    </LoadingButton>
  );
};

export default SmartPOSContainedButton;
