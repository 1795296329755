import React from "react";
import {
  Box,
  Typography,
  Grid,
} from "@mui/material";

import GlideSlider from "./components/GlideSlider.js";
import HeroImage from "./assets/hero1.svg";
import Header from "./components/Header.jsx";
import Footer from "./components/Footer.jsx";
import { Outlet } from "react-router-dom";
//IMPORT STYLES//
import { loginPageStyles } from "./assets/Jss/LoginpageStyles.js";



const LoginPage = () => {
  const { classes } = loginPageStyles();

  // const LoginSection = () => (
  //   <Box
  //     className={classes.loginFormSection}
  //     display="flex"
  //     alignItems="center"
  //     justifyContent="center"
  //     flexDirection="column"
  //   >
  //     <Box sx={{ width: "100%" }}>
  //       <Typography fontSize="1.5rem" fontWeight="bold">
  //         {action}
  //       </Typography>
  //       <Typography color="textSecondary" fontSize="1rem">
  //         {text}
  //       </Typography>

  //       {action.toString() === "Sign Up" ? (
  //         <RegisterForm />
  //       ) : action.toString() === "Get Password Reset Token" ? (
  //         <ResetPassToken />
  //       ) : action.toString() === "Welcome Back!" ? (
  //         <LoginForm />
  //       ) : action.toString() === "Reset Initial Password" ? (
  //         <ResetPassword />
  //       ) : action.toString() === "Reset Password" ? (
  //         <ResetWithToken />
  //       ) : (
  //         <Box>Welcome Back!</Box>
  //       )}
  //       <Box className={classes.forgotPassBox} textAlign="center">
  //         <Link
  //           href="#"
  //           sx={{ color: "#1976d2", textDecoration: "none" }}
  //           variant="body2"
  //           onClick={() => {
  //             setAction("Reset Initial Password");
  //             setText("Please reset your Initial Password");
  //           }}
  //         >
  //           Reset Initial Password
  //         </Link>
  //       </Box>
  //       <Box className={classes.forgotPassBox} textAlign="center">
  //         <Link
  //           href="#"
  //           sx={{ color: "#1976d2", textDecoration: "none" }}
  //           variant="body2"
  //           onClick={() => {
  //             setAction("Get Password Reset Token");
  //             setText("Enter your Email To get A Password Reset Token");
  //           }}
  //         >
  //           Get Password Reset Token
  //         </Link>
  //       </Box>
  //       <Box className={classes.forgotPassBox} textAlign="center">
  //         <Link
  //           href="#"
  //           sx={{ color: "#1976d2", textDecoration: "none" }}
  //           variant="body2"
  //           onClick={() => {
  //             setAction("Reset Password");
  //             setText("Reset your password");
  //           }}
  //         >
  //           Reset Password
  //         </Link>
  //       </Box>
  //       <Box marginY={2} textAlign="center">
  //         <Typography variant="body2" color="textSecondary">
  //           Don't have an account?
  //           <Link
  //             sx={{
  //               color: "#1976d2",
  //               textDecoration: "none",
  //               cursor: "pointer",
  //             }}
  //             variant="body2"
  //             onClick={() => {
  //               setAction("Sign Up");
  //               setText("Please sign up to continue");
  //             }}
  //           >
  //             Go to Registration
  //           </Link>
  //         </Typography>
  //       </Box>
  //       <Box marginY={2} textAlign="center">
  //         <Typography variant="body2" color="textSecondary">
  //           Already have an account?
  //           <Link
  //             sx={{
  //               color: "#1976d2",
  //               textDecoration: "none",
  //               cursor: "pointer",
  //             }}
  //             variant="body2"
  //             onClick={() => {
  //               setAction("Welcome Back!");
  //               setText("Please sign in to continue");
  //             }}
  //           >
  //             Sign in
  //           </Link>
  //         </Typography>
  //       </Box>
  //     </Box>
  //     <Footer />
  //   </Box>
  // );
  return (
    <Grid container direction="row">
      <Grid item lg={6} xl={6} className={classes.loginSliderSection}>
        <Header />

        <GlideSlider>
          <Box className="glide__slide" textAlign="center" component="div">
            <img src={HeroImage} alt="heroimage" />
            <Typography fontWeight="bold" variant="h2" textAlign="center">
              Manage Sales, inventory <br />
              and other transactions
            </Typography>
          </Box>
          <Box className="glide__slide" textAlign="center" component="div">
            <img src={HeroImage} alt="heroimage" />
            <Typography fontWeight="bold" variant="h2" textAlign="center">
              Manage Sales, inventory <br />
              and other transactions
            </Typography>
          </Box>
          <Box className="glide__slide" textAlign="center" component="div">
            <img src={HeroImage} alt="heroimage" />
            <Typography fontWeight="bold" variant="h2" textAlign="center">
              Manage Sales, inventory <br />
              and other transactions
            </Typography>
          </Box>
        </GlideSlider>
      </Grid>
      <Grid item lg={6} xl={6}>
        <Box
          className={classes.loginFormSection}
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
        >
          <Outlet />
          <Footer />
        </Box>
      </Grid>
    </Grid>
  );
};

export default LoginPage;
