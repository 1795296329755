import React from "react";
import ReusableStatsCard from "./ReusableStatsCard";
import MonetizationOn from "@mui/icons-material/MonetizationOn";

function Orders() {
  return (
    <ReusableStatsCard
      icon={MonetizationOn}
      title={"Total Orders"}
      percentage={-2.34}
      value={22375}
      progressValue={54}
      progressColor={"warning"}
      color={"#CB181E"}
      iconColor={"#CB181E"}
    />
  );
}

export default Orders;
