import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";
import { useRegisterUserMutation } from "../../store/apiSlice.js";
import { loginPageStyles } from "../login/assets/Jss/LoginpageStyles.js";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Typography,
  
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel,
  Switch,
} from "@mui/material";
import SmartPOSContainedButton from "../login/components/Buttons/SmartPOSContainedButton";
import { CustomModal } from "../../components/index.js";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  //   border: "2px solid #000",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
};

const RegisterUser = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { classes } = loginPageStyles();
  const [showPassword, setShowPassword] = useState(false);
  const [toggle, setToggle] = useState();
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const initialState = {
    firstName: "",
    lastName: "",
    password: "",
    email: "",
    role: "",
    isAutogen: false,
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const navigate = useNavigate();
  //register user
  const [
    registerUser,

    {
      isLoading: isregisterLoading,
      isSuccess: isregisterSuccess,
      isError: isregisterError,
      error: registerError,
    },
  ] = useRegisterUserMutation();
  const validationSchema = Yup.object().shape({
    email: Yup.string().email("please enter valid email").required("Required"),
    firstName: Yup.string()
      .required("Required")
      .min(3, "name must have at least 3 characters"),
    lastName: Yup.string()
      .required("Required")
      .min(3, "name must have at least 3 characters"),
    role: Yup.string().required("Required"),

    password: Yup.string().min(8, "Password must have at least 8 characters"),
  });
  const handleToggle = (event) => {
    console.log(event.target.checked);
    setToggle(event.target.checked);
  };
  const handleUserRegistration = async (values, props) => {
    if (values) {
      const email = values.email;
      const firstName = values.firstName;
      const lastName = values.lastName;
      const role = values.role;
      const password = values.password;
      //  const password = values.password ? values.password : "";
      const isAutogen = toggle;
      console.log(email, firstName, lastName, role, password, isAutogen);
      await registerUser({
        email,
        firstName,
        lastName,
        role,
        password,
        isAutogen,
      });
    }
  };
  useEffect(() => {
    if (isregisterSuccess) {
      toast.success("User registered successfully");
      navigate("/admin/users")
    }
  }, [isregisterSuccess]);

  useEffect(() => {
    if (isregisterError) {
      toast.error(registerError.data.message);
    }
  }, [isregisterError]);

  const roles = [
    {
      value: "Waitress",
      label: "Waitress",
    },
    {
      value: "Admin",
      label: "Admin",
    },
    {
      value: "Waiter",
      label: "Waiter",
    },
    {
      value: "Bartender",
      label: "Bartender",
    },
    {
      value: "Chef",
      label: "Chef",
    },
  ];
  return (
    <Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "start",
        }}
      >
        <Typography
          variant="h1"
          sx={{
            textTransform: "capitalize",
            fontWeight: "bold",
            fontSize: "1rem",
          }}
        >
          UserAdmin
        </Typography>

        <Button
          sx={{
            width: "max-content",
            paddingTop: "10p",
            paddingBottom: "10px",
            paddingLeft: "20px",
            paddingRight: "20px",
          }}
          disableRipple
          disableElevetion
          variant="contained"
          onClick={handleOpen}
        >
          Register User
        </Button>
      </Box>
      <CustomModal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {/* <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Text in a modal
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
          </Typography>
        </Box> */}
        <Box sx={style}>
          <Formik
            initialValues={initialState}
            onSubmit={handleUserRegistration}
            validationSchema={validationSchema}
          >
            {(props) => (
              <Form>
                <Box
                  sx={{ width: 350 }}
                  // direction="row"
                  // spacing={2}
                  // marginTop="10px"
                  // alignItems="center"
                >
                  <Field
                    as={TextField}
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    name="firstName"
                    error={props.errors.firstName}
                    helperText={<ErrorMessage name="firstName" />}
                    className={classes.loginInput}
                    placeholder="First Name"
                  />
                  <Field
                    as={TextField}
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    name="lastName"
                    error={props.errors.lastName}
                    helperText={<ErrorMessage name="lastName" />}
                    className={classes.loginInput}
                    placeholder="Last Name"
                  />

                  {/* <Box> */}
                  <Field
                    as={TextField}
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    name="email"
                    error={props.errors.email}
                    helperText={<ErrorMessage name="email" />}
                    className={classes.loginInput}
                    placeholder="Email"
                  />
                  <Field
                    as={TextField}
                    width="50%"
                    fullWidth
                    id="outlined-select-currency-native"
                    // className={classes.loginInput}

                    variant="filled"
                    select
                    name="role"
                    error={props.errors.role}
                    helperText={<ErrorMessage name="role" />}
                    inputProps={{
                      sx: {
                        backgroundColor: "#F5F4F2",
                        "&::placeholder": {
                          color: "#c9c9c9",
                          fontWeight: "bold",
                          opacity: 1, // otherwise firefox shows a lighter color
                        },
                      },
                    }}
                    placeholder="Role"
                    InputProps={{
                      disableUnderline: true,
                    }}
                    defaultValue="Role"
                    SelectProps={{
                      native: true,
                    }}
                  >
                    {roles.map((option) => (
                      <option
                        key={option.value}
                        value={option.value}
                        sx={{ fontWeight: "bold" }}
                      >
                        {option.label}
                      </option>
                    ))}
                  </Field>
                  {/* </Box> */}
                  <FormControlLabel
                    label="Autogenerate Password"
                    control={
                      <Field
                        as={Switch}
                        name="isAutogen"
                        checked={toggle}
                        onChange={handleToggle}
                      />
                    }
                  />
                  {!toggle ? (
                    <Field
                      as={TextField}
                      variant="outlined"
                      margin="dense"
                      fullWidth
                      id="newPass"
                      type={showPassword ? "text" : "password"}
                      name="password"
                      error={props.errors.password}
                      helperText={<ErrorMessage name="password" />}
                      className={classes.loginInput}
                      placeholder="Password"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                            >
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  ) : (
                    <Box></Box>
                  )}

                  <SmartPOSContainedButton
                    color="primary"
                    loading={isregisterLoading}
                    loadingIndicator="Loading…"
                    disableRipple
                    disableElevation
                    type="submit"
                    className={classes.loginBtn}
                  >
                    <Typography fontWeight="bold">Sign Up</Typography>
                  </SmartPOSContainedButton>
                </Box>
              </Form>
            )}
          </Formik>
        </Box>
      </CustomModal>
    </Box>
  );
};

export default RegisterUser;
