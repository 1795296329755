import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  name: "",
  description: "",
};

const editCategorySlice = createSlice({
  name: "editCategoryData",
  initialState,
  reducers: {
    setEditCategoryData: (state, action) => {
      state.name = action.payload.name;
      state.description = action.payload.description;
    },
    resetEditCategoryData: (state) => {
      state.name = "";
      state.description = "";
    },
  },
});

export const { setEditCategoryData, resetEditCategoryData } =
  editCategorySlice.actions;
export default editCategorySlice.reducer;
