import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  title: "",
  description: "",
  category: "",
  subCategory: "",
  price: 0,
  status: "",
  quantity: "",
};

const NewProductFromDataSlice = createSlice({
  initialState,
  name: "newProductFormData",
  reducers: {
    updateFormData: (state, action) => {
      if (action.payload.name === "title") {
        state.title = action.payload.value;
      }
      if (action.payload.name === "description") {
        state.description = action.payload.value;
      }
      if (action.payload.name === "category") {
        state.category = action.payload.value;
      }
      if (action.payload.name === "subCategory") {
        state.subCategory = action.payload.value;
      }
      if (action.payload.name === "price") {
        state.price = action.payload.value;
      }
      if (action.payload.name === "status") {
        state.status = action.payload.value;
      }
      if (action.payload.name === "quantity") {
        state.quantity = action.payload.value;
      }
    },
  },
});

export default NewProductFromDataSlice.reducer;

export const { updateFormData } = NewProductFromDataSlice.actions;
