import React from "react";
import { CustomModal, CustomButton } from "../../../components";
import { Typography, Stack } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

function ImportIngredientsModal({ handleClose, open }) {
  return (
    <CustomModal handleClose={handleClose} open={open}>
      <Stack spacing={3} sx={{ padding: "20px" }}>
        <Typography variant="body1" color="initial">
          Import New Ingredients
        </Typography>
        <CustomButton variant="outlined">
          <Typography variant="body1" color="initial">
            Download our excel template from here
          </Typography>
          <DownloadIcon />
        </CustomButton>
        <CustomButton variant="contained">
          <Typography variant="body1" color="initial">
            Upload File
          </Typography>
          <CloudUploadIcon />
        </CustomButton>
      </Stack>
    </CustomModal>
  );
}

export default ImportIngredientsModal;
