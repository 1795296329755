import { makeStyles } from "tss-react/mui";
import { alpha } from "@mui/material";
export const loginPageStyles = makeStyles()((theme) => ({
  loginSliderSection: {
    height: "100vh",
    width: "100%",
    backgroundColor: "#F5F4F2",
  },
  loginFormSection: {
    height: "100vh",
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8),
  },

  loginInput: {
    "& .MuiOutlinedInput-root": {
      backgroundColor: "#F5F4F2",
      "& fieldset": {
        border: "none",
      },
      "&.Mui-focused fieldset": {
        border: "2px solid  #FFD049",
        boxShadow: `${alpha("#FFD049", 0.25)} 0 0 0 0.2rem`,
      },
    },
  },
  loginBtn: {
    margin: "1.5rem 0",
    padding: 8,
    borderRadius: 4,
  },
  oAuthBtn: {
    border: "2px solid #F5F4F2",
  },
  forgotPassBox: {
    paddingTop: theme.spacing(2),
    "& .MuiLink-root": {
      color: "#1976d2",
    },
  },
}));
