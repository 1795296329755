import React from "react";
import Chart from "react-google-charts";
import Wrapper from "./UI/Wrapper";
import theme from "../../../Themes/Default";
import { Box, Typography } from "@mui/material";

const DailySalesChart = () => {
  const generateDummyData = () => {
    const data = [["Time", "Sales"]];
    const startTime = new Date().setHours(9, 0, 0, 0); // 9 am
    const endTime = new Date().setHours(18, 0, 0, 0); // 6 pm
    const interval = 60 * 60 * 1000;

    data.push([
      new Date(startTime).toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      }),
      0, //initial data point set to 0
    ]);

    for (let time = startTime + interval; time <= endTime; time += interval) {
      const formattedTime = new Date(time).toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      });
      const sales = Math.floor(Math.random() * 250); // Random sales value for demo
      data.push([formattedTime, sales]);
    }

    localStorage.setItem("data", JSON.stringify(data));
    return data;
  };

  const options = {
    legend: "none",
    vAxis: {
      minValue: 0,
      maxValue: 300,
      gridlines: { count: 6},
      viewWindow: { min: 0, max: 300 },
    },
    hAxis: {
      slantedText: true,
    },
    curveType: "function",
    pointSize: 1  ,
    colors: [theme.palette.secondary.main],
    areaOpacity: 0.3,
    intervals: { style: "area" },
    chartArea: { left: 70, top: 10, width: "90%", height: "70%" },
    enableInteractivity: true
  };

  return (
    <Chart
      style={{ width: "100%", height: "100%", position: 'relative' }}
      chartType="LineChart"
      loader={<div>Loading Chart</div>}
      data={generateDummyData()}
      options={options}
    />
  );
};

function Sales() {
  return (
    <Wrapper>
      <Box
        sx={{
          height: "100%",
          width: "100%",
          padding: "0.5rem",
          display: "flex",
          flexDirection: "column", // Ensure a column layout
        }}
      >
        <Typography
          variant="h6"
          sx={{
            fontWeight: "bold",
            fontSize: "1rem",
            padding: "0.5rem 1.75rem",
          }}
        >
          Daily Sales
        </Typography>
        <DailySalesChart
          style={{ flex: 1, width: "100%", position: "relative" }}
        />
      </Box>
    </Wrapper>
  );
}


export default Sales;
