import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const apiSlice = createApi({
  reducerPath: "apiSlice",
  baseQuery: fetchBaseQuery({
    baseUrl: "https://sp-api.azgard.co.ke/api/",
    //start of added code
    prepareHeaders: (headers) => {
      // const token = getState().auth.userToken;
      const storedUserData = localStorage.getItem("user");
      const userInfo = storedUserData ? JSON.parse(storedUserData) : {};
      const token = userInfo.userToken;
      // const token = getState().auth.userToken;
      if (token) {
        // include token in req header
        headers.set("Authorization", `Bearer ${token}`);
        return headers;
      }
    },
    //end of added code
  }),
  endpoints: (build) => ({
    loginUser: build.mutation({
      query: (body) => {
        return {
          url: "Auth/Login",
          method: "POST",
          headers: {
            "content-type": "application/json",
          },
          body: body,
        };
      },
    }),
    registerHotel: build.mutation({
      query: (body) => {
        return {
          url: "Hotel/AddHotel",
          method: "POST",
          headers: {
            "content-type": "application/json",
          },
          body: body,
        };
      },
    }),
    // other end points
    registerUser: build.mutation({
      query: (body) => {
        return {
          url: "Auth/Register",
          method: "POST",
          headers: {
            "content-type": "application/json",
          },
          body: body,
        };
      },
    }),

    resetInitialPassword: build.mutation({
      query: (body) => {
        return {
          url: "Auth/InitalPasswordReset",
          method: "POST",
          headers: {
            "content-type": "application/json",
          },
          body: body,
        };
      },
    }),
    resetPasswordToken: build.mutation({
      query: (body) => {
        return {
          url: `Auth/GetPasswordResetToken?email=${body}`,
          method: "POST",
          // headers: {
          //   "content-type": "application/json",
          // },
        };
      },
    }),
    resetPassword: build.mutation({
      query: (body) => {
        return {
          url: "Auth/ResetPassword",
          method: "POST",
          headers: {
            "content-type": "application/json",
          },
          body: body,
        };
      },
    }),
    uploadProfileImage: build.mutation({
      query: (userId, body) => {
        return {
          url: `Auth/GetPasswordResetToken?email=${userId}`,
          method: "POST",
          // headers: {
          //   "content-type": "application/json",
          // },
          body: body,
        };
      },
    }),
    userProfile: build.query({
      query(userId) {
        return {
          url: `Auth/GetUserProfile?userId=${userId}`,
          method: "GET",
          // headers: {
          //   "content-type": "application/json",
          // },
        };
      },
    }),
    updateUserProfile: build.mutation({
      query: (body) => {
        return {
          url: "Auth/UpdateUserProfile",
          method: "PUT",
          headers: {
            "content-type": "application/json",
          },
          body: body,
        };
      },
    }),
    deleteUser: build.mutation({
      query: (userID) => {
        return {
          url: `Auth/DeleteUser?userId=${userID}`,
          method: "DELETE",
        };
      },
    }),
    usersList: build.query({
      query() {
        return {
          method: "GET",
          url: "Auth/GetUsers",
        };
      },
    }),
    categoriesList: build.query({
      query: ({ hotelId }) => {
        return {
          method: "GET",
          url: `Categories/GetCategories?hotelId=${hotelId}`,
        };
      },
    }),
    categoryItemsList: build.query({
      query({ categoryId }) {
        return {
          method: "GET",
          url: `Products/GetProducts?productCategoryId=${categoryId}`,
        };
      },
    }),
    addCategory: build.mutation({
      query: ({ body }) => {
        return {
          url: "Categories/AddCategory",
          method: "POST",
          body: body,
        };
      },
    }),
    uploadCategoryImage: build.mutation({
      query: ({ body, id }) => {
        return {
          url: `Categories/UploadCategoriesImage?categoryId=${id}`,
          method: "POST",
          body: body,
        };
      },
    }),
    editCategory: build.mutation({
      query: ({ body, id }) => {
        return {
          url: `Categories/UpdateProductCategory?categoryId=${id}`,
          method: "PUT",
          body: body,
        };
      },
    }),
    deleteCategory: build.mutation({
      query: ({ id }) => {
        return {
          url: `Categories/DeleteCategory?id=${id}`,
          method: "DELETE",
        };
      },
    }),
    productsList: build.query({
      query: ({ hotelId }) => {
        return {
          url: `Products/GetProducts?HotelId=${hotelId}`,
          method: "GET",
        };
      },
    }),
    productDetails: build.query({
      query: ({ hotelId, productId }) => {
        return {
          url: `Products/GetProduct?HotelId=${hotelId}&ProductId=${productId}`,
          method: "GET",
        };
      },
    }),
    addProduct: build.mutation({
      query: ({ body }) => {
        return {
          url: `Products/AddProduct`,
          method: "POST",
          body: body,
        };
      },
    }),
    addProductIngredient: build.mutation({
      query: ({ body }) => {
        return {
          url: "Products/AddProductIngredient",
          method: "POST",
          body: body,
        };
      },
    }),
    uploadProductImage: build.mutation({
      query: ({ body, id }) => {
        return {
          url: `Products/UploadProductImage?productId=${id}`,
          method: "POST",
          body: body,
        };
      },
    }),
    updateProduct: build.mutation({
      query: ({ body, id }) => {
        return {
          url: `Products/UpdateProduct?productId=${id}`,
          method: "PUT",
          body: body,
        };
      },
    }),
    deleteProduct: build.mutation({
      query: ({ id }) => {
        return {
          url: `Products/DeleteProduct?id=${id}`,
          method: "DELETE",
        };
      },
    }),
    order: build.query({
      query({ id }) {
        return {
          url: `Orders/GetSingleOrder?orderId=${id}`,
          method: "GET",
        };
      },
    }),
    ordersList: build.query({
      query({ hotelId }) {
        return { method: "GET", url: `Orders/GetAllOrders?HotelId=${hotelId}` };
      },
    }),
    createOrderItem: build.mutation({
      query: (body) => {
        return {
          url: "Orders/AddOrderItem",
          method: "POST",
          headers: {
            "content-type": "application/json",
          },
          body: body,
        };
      },
    }),
    createOrder: build.mutation({
      query: (body) => {
        return {
          url: "Orders/CreateNewOrder",
          method: "POST",
          headers: {
            "content-type": "application/json",
          },
          body: body,
        };
      },
    }),
    updateOrderStatus: build.mutation({
      query: ({ id }) => {
        return {
          url: `Orders/UpdateOrderStatus?orderId=${id}&status=done`,
          method: "PUT",
        };
      },
    }),
    createOrderItemsRange: build.mutation({
      query: (body) => {
        return {
          url: "Orders/AddOrderItemsRange",
          method: "POST",
          headers: {
            "content-type": "application/json",
          },
          body: body,
        };
      },
    }),

    ingredientList: build.query({
      query: ({ hotelId }) => {
        return {
          url: `Ingredients/GetIngredients?HotelId=${hotelId}`,
          method: "GET",
        };
      },
    }),
    ingredientDetails: build.query({
      query: ({ id }) => {
        return {
          url: `Ingredients/GetIngredient?id=${id}`,
          method: "GET",
        };
      },
    }),
    addIngredient: build.mutation({
      query: ({ body }) => {
        return {
          url: `Ingredients/AddIngredient`,
          method: "POST",
          body: body,
        };
      },
    }),
    uploadIngredientImage: build.mutation({
      query: ({ id, body }) => {
        return {
          url: `Ingredients/UploadIngredientImage?ingredientId=${id}`,
          method: "POST",
          body: body,
        };
      },
    }),
    updateIngredient: build.mutation({
      query: ({ id, body }) => {
        return {
          url: `Ingredients/UpdateIngredient?ingredientId=${id}`,
          method: "PUT",
          body: body,
        };
      },
    }),
    deleteIngredient: build.mutation({
      query: ({ id }) => {
        return {
          url: `Ingredients/DeleteIngredient?id=${id}`,
          method: "DELETE",
        };
      },
    }),
  }),
});

export const {
  useOrderQuery,
  useLoginUserMutation,
  useRegisterHotelMutation,
  useRegisterUserMutation,
  useUsersListQuery,
  useCategoriesListQuery,
  useCategoryItemsListQuery,
  useEditCategoryMutation,
  useDeleteCategoryMutation,
  useProductsListQuery,
  useProductDetailsQuery,
  useAddProductMutation,
  useAddProductIngredientMutation,
  useUpdateProductMutation,
  useUploadProductImageMutation,
  useDeleteProductMutation,
  useAddCategoryMutation,
  useUploadCategoryImageMutation,
  useOrdersListQuery,
  useUpdateOrderStatusMutation,
  useCreateOrderItemMutation,
  useCreateOrderItemsRangeMutation,
  useCreateOrderMutation,
  useResetPasswordTokenMutation,
  useResetPasswordMutation,
  useResetInitialPasswordMutation,
  useDeleteUserMutation,
  useUploadProfileImageMutation,
  useUserProfileQuery,
  useUpdateUserProfileMutation,
  useIngredientListQuery,
  useAddIngredientMutation,
  useUploadIngredientImageMutation,
  useIngredientDetailsQuery,
  useUpdateIngredientMutation,
  useDeleteIngredientMutation,
} = apiSlice;
