import React from "react";
import { makeStyles } from "tss-react/mui";
import { Box } from "@mui/material";
import { CurrentYear } from "../utils/DateUtils"
import { AppName } from "../utils/Constants";
const useStyles = makeStyles()(() => ({
  root: {
    position: "fixed",
    bottom: "3%",
    color:"textSecondary"
  },
}));
const Footer = () => {
  const { classes } = useStyles();
  return (
    <Box component="div" className={classes.root} >
      &copy; {CurrentYear} {AppName} App
    </Box>
  );
};

export default Footer;
