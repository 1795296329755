import theme from "../../../Themes/Default";
import moment from "moment/moment";
import { currency } from "../../../utils/currency";
import { CustomButton } from "../../../components";
import PaymentIcon from "@mui/icons-material/Payment";
import { List, ListItem, ListItemText, Stack, Typography } from "@mui/material";
import { useUpdateOrderStatusMutation } from "../../../store/apiSlice";
import toast from "react-hot-toast";

export const OrderDetailCard = ({ data, handleStatusChange }) => {
  const [updateOrderStatus] = useUpdateOrderStatusMutation();
  const handleUpdateOrderStatus = async () => {
    try {
      toast.loading("sending...");
      await updateOrderStatus({ id: data.id });
      handleStatusChange();
      toast.dismiss();
      toast.success("Payment completed.");
    } catch (error) {
      toast.dismiss();
      toast.error("Error occured.");
    }
  };
  return (
    <Stack
      sx={{
        gridArea: "order",
        border: "2px solid",
        borderColor: theme.palette.background.dark,
        borderRadius: "1rem",
        height: "90%",
        padding: "1rem",
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{
          padding: "1rem",
          borderBottom: "2px solid",
          borderColor: theme.palette.background.dark,
        }}
      >
        <Stack>
          <Typography sx={{ textTransform: "capitalize" }}>
            {data.orderType}
          </Typography>
          <Typography variant="h1" sx={{ fontWeight: "bold" }}>
            Order #{data.orderNumber}
          </Typography>
        </Stack>
        <Stack>
          <Typography sx={{ textTransform: "capitalize" }}>Status</Typography>
          <Typography
            variant="h1"
            sx={{
              fontWeight: "bold",
              textTransform: "capitalize",
              color: data.status === "done" ? "green" : "red",
            }}
          >
            {data.status}
          </Typography>
        </Stack>
      </Stack>
      <List
        sx={{
          width: "100%",
          height: "90%",
          overflowY: "auto",
        }}
      >
        <ListItem>
          <ListItemText
            primary="Table"
            secondaryTypographyProps={{
              sx: {
                fontWeight: "bold",
              },
            }}
            secondary={data.table}
          />
          <ListItemText
            primary="Guests"
            secondaryTypographyProps={{
              sx: {
                fontWeight: "bold",
              },
            }}
            secondary={data.guests}
          />
          <ListItemText
            primary="Payment"
            secondaryTypographyProps={{
              sx: {
                fontWeight: "bold",
              },
            }}
            secondary={data.paymentStatus}
          />
          <ListItemText
            primary="Time"
            secondaryTypographyProps={{
              sx: {
                fontWeight: "bold",
              },
            }}
            secondary={moment(data.time).format("do-MMM hh:mm a")}
          />
        </ListItem>

        <ListItem secondaryAction={<ListItemText primary="Price (KES)" />}>
          <ListItemText primary="Item" />
        </ListItem>

        {data.orderItems.$values.map((item) => (
          <ListItem
            key={item.products.id}
            secondaryAction={
              <ListItemText
                primaryTypographyProps={{
                  sx: {
                    fontWeight: "bold",
                  },
                }}
                primary={item.products.price * item.quantity}
              />
            }
            sx={{
              borderBottom: "2px solid",
              borderColor: theme.palette.background.dark,
            }}
          >
            <ListItemText
              primaryTypographyProps={{
                sx: {
                  fontWeight: "bold",
                  textTransform: "capitalize",
                },
              }}
              primary={`${item.products.name} x ${item.quantity}`}
            />
          </ListItem>
        ))}
        <ListItem
          secondaryAction={
            <ListItemText
              primaryTypographyProps={{
                sx: {
                  fontWeight: "bold",
                },
              }}
              primary={currency(data.totalPrice - data.totalVAT)}
            />
          }
        >
          <ListItemText primary="Sub Total" />
        </ListItem>
        <ListItem
          secondaryAction={
            <ListItemText
              primaryTypographyProps={{
                sx: {
                  fontWeight: "bold",
                },
              }}
              primary={currency(data.totalVAT)}
            />
          }
        >
          <ListItemText primary="Tax" />
        </ListItem>
        <ListItem
          secondaryAction={
            <ListItemText
              primaryTypographyProps={{
                sx: {
                  fontWeight: "bold",
                },
              }}
              primary={currency(data.totalPrice)}
            />
          }
        >
          <ListItemText primary="Grand Total" />
        </ListItem>
        <ListItem>
          <CustomButton
            variant="contained"
            color="success"
            sx={{ width: "100%" }}
          >
            Confirm Payment <PaymentIcon />
          </CustomButton>
        </ListItem>
        <ListItem>
          <CustomButton
            variant="contained"
            color="primary"
            onClick={handleUpdateOrderStatus}
          >
            Complete Payment <PaymentIcon />
          </CustomButton>
        </ListItem>
      </List>
    </Stack>
  );
};
