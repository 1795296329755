import React, { useEffect } from "react";
import Glide from "@glidejs/glide";
import "@glidejs/glide/dist/css/glide.core.css";
import "@glidejs/glide/dist/css/glide.theme.css";
import { Box } from "@mui/material";
import { common } from "@mui/material/colors";

const GlideSlider = ({ children }) => {
  useEffect(() => {
    new Glide(".glide").mount();
  }, []);

  const renderDots = ({ children }) => {
    const total = React.Children.count(children);
    const dots = [];

    for (let i = 0; i < total; i += 1) {
      dots.push(
        <Box
          key={i}
          className="glide__bullet"
          data-glide-dir={`=${i}`}
          sx={{
            "&.glide__bullet": {
              backgroundColor: (theme) => theme.palette.common.white,
              width: "16px",
              height: "8px",
              borderRadius: 2,
            },

            "&.glide__bullet--active": {
              backgroundColor: "#FFD049",
            },
          }}
        />
      );
    }
    return dots;
  };
  return (
    <Box className="glide">
      <Box className="glide__track" data-glide-el="track">
        <Box className="glide__slides">{children}</Box>
      </Box>
      <Box
        className="glide__bullets"
        data-glide-el="controls[nav]"
        sx={{
          "&.glide__bullets": {
            position: "relative ",
            bottom: "0em",
          },
        }}
      >
        {renderDots({ children })}
      </Box>
    </Box>
  );
};

export default GlideSlider;
