import React, { useEffect } from "react";
import { Stack, Button, Typography } from "@mui/material";
import { AdminLayout } from "../../../layout";
import { CustomButton } from "../../../components";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useIngredientDetailsQuery } from "../../../store/apiSlice";
import { useLocation, useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";

import {
  setEditModalClose,
  setEditModalOpen,
} from "../../../store/admin/products/editmodal";
import {
  setDeleteModalClose,
  setDeleteModalOpen,
} from "../../../store/admin/shared/deleteModal";
import IngredientImageList from "../new-ingredient/IngredientImageList";
import { DeleteIngredientModal } from "./DeleteIngredientModal";
import { EditIngredientModal } from "./EditIngredientModal";
import { IngredientCard } from "./IngredientCard";

const ButtonComponent = () => {
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <CustomButton
      variant="outlined"
      onClick={() =>
        navigate(`/ingredients/${location.pathname.split("/")[2]}/image`)
      }
    >
      Upload Image
    </CustomButton>
  );
};

function IngredientDetails() {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  let productId = pathname.split("/")[2];
  const { data, isSuccess, refetch } = useIngredientDetailsQuery({
    id: productId,
  });
  const isDeleteModalOpen = useSelector((state) => state.deleteModal.open);
  const isEditModalOpen = useSelector((state) => state.editProductModal.open);

  const handleEditModalClose = () => {
    dispatch(setEditModalClose());
  };
  const handleDeleteModalClose = () => {
    dispatch(setDeleteModalClose());
  };

  useEffect(() => {
    refetch();
  }, [isEditModalOpen]);

  if (isSuccess) {
    const ingredient = data.message;

    return (
      <>
        <AdminLayout
          title={"Ingredient Details"}
          ButtonComponent={ButtonComponent}
        >
          <Stack
            direction="column"
            alignItems="center"
            justifyContent="center"
            sx={{ width: "100%" }}
          >
            <Stack justifyContent="center" gap={4} sx={{ width: "70%" }}>
              <IngredientCard ingredient={ingredient} />

              <IngredientImageList
                height={"200px"}
                width={"200px"}
                imageUrl={`${
                  ingredient.image
                    ? ingredient.image
                    : "https://placehold.co/600x400/png"
                }`}
              />
              <Stack
                direction="row"
                spacing={4}
                sx={{
                  width: "70%",
                  alignItems: "start",
                  padding: "10px 0px",
                }}
              >
                <Button variant="outlined" color="error">
                  <Typography
                    variant="button"
                    color="initial"
                    onClick={() => dispatch(setDeleteModalOpen())}
                  >
                    Delete
                  </Typography>
                  <DeleteIcon />
                </Button>
                <CustomButton
                  variant="contained"
                  onClick={() => dispatch(setEditModalOpen())}
                >
                  <Typography variant="button" color="initial">
                    Edit
                  </Typography>{" "}
                  <ModeEditIcon />
                </CustomButton>
              </Stack>
            </Stack>
          </Stack>
        </AdminLayout>
        {isDeleteModalOpen && (
          <DeleteIngredientModal
            handleClose={handleDeleteModalClose}
            open={isDeleteModalOpen}
            product={ingredient}
          />
        )}
        {isEditModalOpen && (
          <EditIngredientModal
            handleClose={handleEditModalClose}
            open={isEditModalOpen}
            ingredient={ingredient}
          />
        )}
      </>
    );
  }
}

export default IngredientDetails;
