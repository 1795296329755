import React from 'react'

const RestaurantProfile = () => {
  return (
    <div>
      Restaurant Profile
    </div>
  )
}

export default RestaurantProfile
