import React,{useEffect} from "react";
import {
  Typography,
  Stack,
  Box,
  Radio,
  RadioGroup,
  FormLabel,
  FormControlLabel,
  TextField,
  Card,
  Button,
  InputAdornment,
  IconButton,
} from "@mui/material";

import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";
import TaskAltOutlinedIcon from "@mui/icons-material/TaskAltOutlined";
import moment from "moment";
import { useUpdateUserProfileMutation } from "../../../store/apiSlice";

const RightSideForm = (props) => {
  const id = props.data.userId
  const initialState = {
    gender: props.data.gender,
    firstName: props.data.firstName,
    lastName: props.data.lastName,
    email: props.data.email,
    phoneNumber: props.data.phoneNumber,
    employeeId: props.data.employeeId,
    // employeeId: props.data.userId,
    location: props.data.location,
    dateOfBirth: moment(props.data.dateOfBirth).format(" YYYY-MM-DD "),
  };

  const locations = [
    {
      value: "Bar",
      label: "Bar",
    },
    {
      value: "Restaurant",
      label: "Restaurant",
    },
  ];
  const [
    updateUserProfile,

    {
      isSuccess: isUpdateInfoSuccess,
      isError: isUpdateInfoError,
      error: UpdateInfoError,
    },
  ] = useUpdateUserProfileMutation();
  const validationSchema = Yup.object().shape({
    gender: Yup.string().required("Required"),
    firstName: Yup.string().required("Required"),
    lastName: Yup.string().required("Required"),
    email: Yup.string().email("please enter valid email").required("Required"),
    phoneNumber: Yup.string()
      .required("Required")
      .matches(/^[0-9]+$/, "Phone number must contain only digits")
      .min(10, "Phone number must be at least 10 digits")
      .max(15, "Phone number must be at most 15 digits"),
    employeeId: Yup.string().required("Required"),
    // dateOfBirth: Yup.date()
    //   .transform((value) => {
    //     return value ? moment(value).toDate() : value;
    //   })
    //   .max(dateToday, "Future date not allowed"),
      // .test(
      //   "date validation",
      //   "Invalid date or format. The string should be a valid  YYYY MMMM Do format.",
      //   (val) => {
      //     if (!val) {
      //       return true;
      //     }
      //     return moment(val, " YYYY-MM-DD", true).isValid();
      //   }
      // ),
    location: Yup.string().required("Required"),
  });
  const updateUserInformation = async (values, props) => {
    if (values) {
      console.log(values);
      // const id = values.employeeId;
      const email = values.email;
      const firstName = values.firstName;
      const lastName = values.lastName;
      const gender = values.gender;
      const phoneNumber = values.phoneNumber;
      const location = values.location;
      const dateOfBirth = moment(values.dateOfBirth).format(
        "YYYY-MM-DDTHH:mm:ss"
      );
      await updateUserProfile({
        id,
        email,
        firstName,
        lastName,
        gender,
        phoneNumber,
        location,
        dateOfBirth,
      });
    }
  };
   useEffect(() => {
     if (isUpdateInfoSuccess) {
       toast.success("User details updated successfully");
       console.log("success")
     }
   }, [isUpdateInfoSuccess]);
    // useEffect(() => {
     if (isUpdateInfoError) {
          toast.error(UpdateInfoError);
          console.log(UpdateInfoError)
        }
      // }, [isUpdateInfoError]);
  return (
    <Card
      elevation={0}
      sx={{ paddingLeft: 8, paddingRight: 8, height: "100%" }}
    >
      <Formik
        initialValues={initialState}
        onSubmit={updateUserInformation}
        validationSchema={validationSchema}
      >
        {(props) => (
          <Form>
            <Stack spacing={2}>
              <Stack>
                <Typography fontSize="1.3rem" fontWeight="bolder">
                  Personal Information
                </Typography>
                <Field name="gender">
                  {({ field }) => (
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      // name="gender"
                      {...field}
                      // onChange={handleChange}
                      // onChange={handleChange}
                      // error={props.errors.gender}
                      // helperText={<ErrorMessage name="gender" />}
                    >
                      <Stack direction="row" spacing={10}>
                        <FormControlLabel
                          label={<Box sx={{ fontWeight: "medium" }}>Male</Box>}
                          value="male"
                          // name="male"
                          control={<Radio />}
                        />
                        <FormControlLabel
                          label={
                            <Box sx={{ fontWeight: "medium" }}>Female</Box>
                          }
                          value="female"
                          // name="female"
                          control={<Radio />}
                        />
                      </Stack>
                    </RadioGroup>
                  )}
                </Field>
              </Stack>
              <Stack direction="row" spacing={6} sx={{ width: "100%" }}>
                <Stack sx={{ width: "50%" }}>
                  <FormLabel sx={{ fontSize: "0.8rem", fontWeight: "medium" }}>
                    First Name
                  </FormLabel>
                  <Field
                    as={TextField}
                    id="outlined-basic"
                    error={props.errors.firstName}
                    name="firstName"
                    helperText={<ErrorMessage name="firstName" />}
                    variant="filled"
                    margin="dense"
                    sx={{
                      fontColor: "black",
                      fontWeight: "medium",
                    }}
                    placeholder="Theresa"
                    inputProps={{
                      sx: {
                        color: "black",
                        fontWeight: "medium",
                        opacity: 1, // otherwise firefox shows a lighter color
                      },
                    }}
                    InputProps={{
                      disableUnderline: true,
                    }}
                    size="small"
                  />
                </Stack>
                <Stack sx={{ width: "50%" }}>
                  <FormLabel sx={{ fontSize: "0.8rem", fontWeight: "medium" }}>
                    Last Name
                  </FormLabel>
                  <Field
                    as={TextField}
                    id="outlined-basic"
                    error={props.errors.lastName}
                    name="lastName"
                    helperText={<ErrorMessage name="lastName" />}
                    variant="filled"
                    margin="dense"
                    fullWidth
                    placeholder="Webb"
                    size="small"
                    inputProps={{
                      sx: {
                        color: "black",
                        fontWeight: "medium",
                        opacity: 1, // otherwise firefox shows a lighter color
                      },
                    }}
                    InputProps={{
                      disableUnderline: true,
                    }}
                  />
                </Stack>
              </Stack>
              <Stack spacing={1}>
                <FormLabel sx={{ fontSize: "0.8rem", fontWeight: "medium" }}>
                  Email
                </FormLabel>
                <Field
                  as={TextField}
                  id="outlined-basic"
                  error={props.errors.email}
                  name="email"
                  helperText={<ErrorMessage name="email" />}
                  variant="filled"
                  fullWidth
                  placeholder="Theresawebb@gmail.com"
                  size="small"
                  inputProps={{
                    sx: {
                      color: "black",
                      fontWeight: "medium",
                      opacity: 1, // otherwise firefox shows a lighter color
                    },
                  }}
                  InputProps={{
                    disableUnderline: true,
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="email verification"
                          edge="end"
                          Size="small"
                        >
                          <TaskAltOutlinedIcon
                            style={{ fill: "#9dd2be", fontSize: "1rem" }}
                          />
                          <Typography
                            variant="h4"
                            sx={{
                              marginLeft: "6px",
                              color: "#97cebb",
                              fontSize: "0.8rem",
                            }}
                          >
                            Verified
                          </Typography>
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Stack>
              <Stack direction="row" spacing={6} sx={{ width: "100%" }}>
                <Stack sx={{ width: "50%" }}>
                  <FormLabel sx={{ fontSize: "0.8rem", fontWeight: "medium" }}>
                    Phone number
                  </FormLabel>
                  <Field
                    as={TextField}
                    id="outlined-basic"
                    error={props.errors.phoneNumber}
                    name="phoneNumber"
                    helperText={<ErrorMessage name="phoneNumber" />}
                    variant="filled"
                    margin="dense"
                    fullWidth
                    placeholder="(302)785-3156"
                    size="small"
                    inputProps={{
                      sx: {
                        color: "black",
                        fontWeight: "medium",
                        opacity: 1, // otherwise firefox shows a lighter color
                      },
                    }}
                    InputProps={{
                      disableUnderline: true,
                    }}
                  />
                </Stack>
                <Stack sx={{ width: "50%" }}>
                  <FormLabel sx={{ fontSize: "0.8rem", fontWeight: "medium" }}>
                    Employee Id
                  </FormLabel>
                  <Field
                    as={TextField}
                    id="outlined-basic"
                    error={props.errors.employeeId}
                    name="employeeId"
                    helperText={<ErrorMessage name="employeeId" />}
                    variant="filled"
                    margin="dense"
                    fullWidth
                    placeholder="2"
                    size="small"
                    inputProps={{
                      sx: {
                        color: "black",
                        fontWeight: "medium",
                        opacity: 1, // otherwise firefox shows a lighter color
                      },
                    }}
                    InputProps={{
                      disableUnderline: true,
                      readOnly: true,
                      // endAdornment: (
                      //   <InputAdornment position="end">
                      //     <IconButton
                      //       aria-label="choose date"
                      //       edge="end"
                      //       Size="small"
                      //     >
                      //       <CalendarTodayOutlinedIcon />
                      //     </IconButton>
                      //   </InputAdornment>
                      // ),
                    }}
                  />
                </Stack>
              </Stack>
              <Stack direction="row" spacing={6} sx={{ width: "100%" }}>
                <Stack sx={{ width: "50%" }}>
                  <FormLabel sx={{ fontSize: "0.8rem", fontWeight: "medium" }}>
                    Date of Birth
                  </FormLabel>
                  {/* <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                      disableFuture
                      label="Date"
                      format="DD/MM/YYYY"
                      value={Formik.values.dateOfBirth}
                      onChange={(value) =>
                        Formik.setFieldValue("date", value, true)
                      }
                      slotProps={{
                        textField: {
                          variant: "outlined",
                          name:"dateOfBirth",
                          error:
                            props.errors.dateOfBirth
                        },
                      }}
                    />
                  </LocalizationProvider> */}
                  <Field
                    as={TextField}
                    id="outlined-basic"
                    error={props.errors.dateOfBirth}
                    helperText={<ErrorMessage name="dateOfBirth" />}
                    name="dateOfBirth"
                    variant="filled"
                    margin="dense"
                    fullWidth
                    placeholder="2000-09-20"
                    size="small"
                    inputProps={{
                      sx: {
                        color: "black",
                        fontWeight: "medium",
                        opacity: 1, // otherwise firefox shows a lighter color
                      },
                    }}
                    InputProps={{
                      disableUnderline: true,
                      // readOnly: true,
                    }}
                  />
                  {/* <Field
                    as={TextField}
                    // id="outlined-basic"
                    // variant="outlined"
                    // margin="dense"
                    // fullWidth
                    // placeholder="New York,USA"
                    // size="small"
                    id="outlined-select-currency-native"
                    error={props.errors.role}
                    name="role"
                    helperText={<ErrorMessage name="role" />}
                    variant="filled"
                    select
                    inputProps={{
                      sx: {
                        color: "black",
                        fontWeight: "bold",
                        opacity: 1, // otherwise firefox shows a lighter color
                      },
                    }}
                    InputProps={{
                      disableUnderline: true,
                    }}
                    defaultValue="EUR"
                    SelectProps={{
                      native: true,
                    }}
                  >
                    {roles.map((option) => (
                      <option
                        key={option.value}
                        value={option.value}
                        sx={{ fontWeight: "bold" }}
                      >
                        {option.label}
                      </option>
                    ))}
                  </Field> */}
                </Stack>
                <Stack sx={{ width: "50%" }}>
                  <FormLabel sx={{ fontSize: "0.8rem", fontWeight: "medium" }}>
                    Work Station
                  </FormLabel>
                  <Field
                    as={TextField}
                    // id="outlined-basic"
                    // variant="outlined"
                    // margin="dense"
                    // fullWidth
                    // placeholder="New York,USA"
                    // size="small"
                    id="outlined-select-currency-native"
                    error={props.errors.location}
                    name="location"
                    helperText={<ErrorMessage name="location" />}
                    variant="filled"
                    select
                    inputProps={{
                      sx: {
                        color: "black",
                        fontWeight: "bold",
                        opacity: 1, // otherwise firefox shows a lighter color
                      },
                    }}
                    InputProps={{
                      disableUnderline: true,
                    }}
                    defaultValue="EUR"
                    SelectProps={{
                      native: true,
                    }}
                  >
                    {locations.map((option) => (
                      <option
                        key={option.value}
                        value={option.value}
                        sx={{ fontWeight: "bold" }}
                      >
                        {option.label}
                      </option>
                    ))}
                  </Field>
                </Stack>
              </Stack>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                sx={{ fontWeight: "medium" }}
              >
                Save Changes
              </Button>
            </Stack>
          </Form>
        )}
      </Formik>
    </Card>
  );
};

export default RightSideForm;
