import React from "react";
import { useDeleteIngredientMutation } from "../../../store/apiSlice";
import toast from "react-hot-toast";
import { useNavigate } from "react-router";
import { routesEnum } from "../../../utils/routes";
import { useDispatch } from "react-redux";
import { setDeleteModalClose } from "../../../store/admin/shared/deleteModal";
import { DeleteModal } from "../../../features";

export const DeleteIngredientModal = ({ handleClose, open, product }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [deleteIngredient] = useDeleteIngredientMutation();
  const handleDelete = async () => {
    try {
      const res = await deleteIngredient({
        id: product.id,
      });
      if (res.data.status.toLowerCase() === "success") {
        dispatch(setDeleteModalClose());
        toast.success("Item deleted successfully.");
        navigate(routesEnum.ALL_PRODUCTS);
      }
    } catch (e) {
      console.error(e);
    }
  };
  return (
    <DeleteModal
      handleClose={handleClose}
      open={open}
      handleDelete={handleDelete}
      item={product.name}
    />
  );
};
