import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  view: "grid",
};

const itemsViewSlice = createSlice({
  name: "itemsView",
  initialState,
  reducers: {
    setList: (state) => {
      state.view = "list";
    },
    setGrid: (state) => {
      state.view = "grid";
    },
  },
});

export const { setList, setGrid } = itemsViewSlice.actions;

export default itemsViewSlice.reducer;
