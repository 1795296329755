import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  open: false,
};

const deleteModalSlice = createSlice({
  name: "deleteModalSlice",
  initialState,
  reducers: {
    setDeleteModalOpen: (state) => {
      state.open = true;
    },
    setDeleteModalClose: (state) => {
      state.open = false;
    },
  },
});

export const { setDeleteModalClose, setDeleteModalOpen } =
  deleteModalSlice.actions;
export default deleteModalSlice.reducer;
