import { Stack, Typography } from "@mui/material";
import moment from "moment/moment";
import theme from "../../../Themes/Default";
import { useNavigate } from "react-router";

export const OrderCard = ({ order }) => {
  const navigate = useNavigate();

  return (
    <Stack
      key={order.id}
      onClick={() => navigate(`/orders/${order.id}`)}
      sx={{
        width: "300px",
        border: "1px solid",
        borderColor: theme.palette.text.light,
        backgroundColor: theme.palette.background.dark,
        padding: "1.5rem 1rem",
        borderRadius: "1rem",
        cursor: "pointer",
        "&:hover": {
          borderColor: theme.palette.primary.main,
        },
      }}
    >
      <Stack direction="row" justifyContent="space-between">
        <Typography sx={{ fontWeight: "bold" }} variant="h2">
          Order #{order.orderNumber}
        </Typography>
        <Typography
          sx={{
            fontWeight: "bold",
            textTransform: "capitalize",
            color: order.status === "active" ? "red" : "green",
          }}
        >
          {order.status}
        </Typography>
        <Typography sx={{ fontWeight: "bold" }}>
          Kes {order.totalPrice}
        </Typography>
      </Stack>
      <Stack direction="row" justifyContent="space-between">
        {order.orderType === "dine in" ? (
          <>
            <Typography>Table {order.table}</Typography>
            <Typography>{order.guests} Guests</Typography>
          </>
        ) : (
          <Typography sx={{ textTransform: "capitalize" }}>
            {order.orderType}
          </Typography>
        )}
        <Typography>{moment(order.time).format("hh:mm a")}</Typography>
      </Stack>
    </Stack>
  );
};
