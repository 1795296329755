const userProfileLoader = async ({ params }) => {
  const { id } = params;
  const storedUserData = localStorage.getItem("user");
  const userInfo = storedUserData ? JSON.parse(storedUserData) : {};
  const token = userInfo.userToken;

  const res = await fetch(
    `https://sp-api.azgard.co.ke/api/Auth/GetUserProfile?userId=${id}`,
    {
      headers: {
        Authorization: token ? `Bearer ${token}` : "",
      },
    }
  );
  return await res.json();
};

export default userProfileLoader;
