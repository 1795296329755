import React from "react";
import { Stack } from "@mui/material";
import { useIngredientListQuery } from "../../../store/apiSlice";
import { Loader } from "../../../components";
import { DataTable } from "../../../features";
import { columns } from "./columns";

function IngredientsList() {
  const hotelId = JSON.parse(localStorage.getItem("user")).hotelId;
  const { data, isLoading, isSuccess } = useIngredientListQuery({
    hotelId,
  });

  return (
    <Stack spacing={2} sx={{ height: "max-content" }} justifyContent="center">
      {/* {isSuccess && <DataTable products={data.$values} />} */}
      {isSuccess && (
        <DataTable
          data={data.message.$values}
          columns={columns}
          exportFileName="products"
        />
      )}
      {isLoading && <Loader />}
    </Stack>
  );
}

export default IngredientsList;
