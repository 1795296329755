import React from 'react'
import { Typography } from '@mui/material';
const Header = () => {
  return (
    <div>
      <Typography
        fontSize="2rem"
        fontWeight="bolder"
        sx={{ paddingLeft: 5, marginBottom: 6, paddingTop: 2 }}
      >
        Profile
      </Typography>
    </div>
  );
}

export default Header
