import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  // orderType: "dine in",
  orderType: "take away",
  table: "",
  guests: 0,
  products: [],
  subtotal: 0,
  tax: 0,
  payableAmount: 0,
};

const calculateTotals = (state) => {
  state.subtotal = state.products.reduce(
    (acc, product) => acc + product.totalPrice,
    0
  );
  state.tax = state.subtotal * 0.16;
  state.payableAmount = state.subtotal + state.tax;
};

const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    setOrderType: (state, action) => {
      state.orderType = action.payload;
    },
    setOrderGuests: (state, action) => {
      state.guests = action.payload;
    },
    setOrderTable: (state, action) => {
      state.table = action.payload;
    },
    addProduct: (state, action) => {
      const existingProduct = state.products.find(
        (item) => item.id === action.payload.id
      );
      if (existingProduct) {
        existingProduct.quantity += action.payload.quantity;
        existingProduct.totalPrice =
          existingProduct.unitPrice * existingProduct.quantity;
      } else {
        state.products.push({
          ...action.payload,
          totalPrice: action.payload.unitPrice * action.payload.quantity,
        });
      }
      calculateTotals(state);
    },
    updateProduct: (state, action) => {
      const product = state.products.find(
        (item) => item.id === action.payload.id
      );
      if (product) {
        product.quantity = action.payload.quantity;
        product.totalPrice = product.unitPrice * product.quantity;
        if (product.quantity === 0) {
          state.products = state.products.filter(
            (item) => item.id !== product.id
          );
        }
      }
      calculateTotals(state);
    },
    updateProducts: (state, action) => {
      state.products = action.payload;
    },
  },
});

export const {
  setOrderType,
  setOrderTable,
  setOrderGuests,
  addProduct,
  updateProduct,
  updateProducts,
} = orderSlice.actions;

export const orderReducer = orderSlice.reducer;
