import { logoutUser } from "../../../store/authSlice";
import {
  Box,
  Card,
  Typography,
  Stack,
  Avatar,
  Divider,
  Badge,
  List,
  ListItem,
  ListItemText,
  ListItemButton,
  ListItemIcon,
  Button,
  
  IconButton,
} from "@mui/material";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import {
  useUploadProfileImageMutation,
} from "../../../store/apiSlice";
import SmartPOSContainedButton from "../../login/components/Buttons/SmartPOSContainedButton";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import LockIcon from "@mui/icons-material/Lock";
import LogoutIcon from "@mui/icons-material/Logout";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { useNavigate } from "react-router-dom";
import { useDispatch} from "react-redux";
import { styled } from "@mui/material/styles";
import React, { useState } from "react";
import axios from "axios";
import { useDeleteUserMutation } from "../../../store/apiSlice";
import { loginPageStyles } from "../../login/assets/Jss/LoginpageStyles";
import toast from "react-hot-toast";
import { CustomModal } from "../../../components";

const styleObj = {
  color: "#9E9E9E",
  fontSize: "0.9rem",
  "&:active": {
    borderRight: "3px solid #FFCA40",
    fontWeight: "bold",
    color: "black",
  },
};
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const LeftSideProfile = (props) => {
  const [selectedImage, setSelectedImage] = useState(null);
  const navigate = useNavigate();
  const { classes } = loginPageStyles();
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // const loadedData = useLoaderData();
  // console.log("loaderData", loadedData);
  // const userInfo = useSelector((state) => state.auth.data);
  // console.log(userInfo.details.id);
  // const userInfoFromGlobal = useSelector((state) => state.auth.data);
  // console.log("userIdInfo",userInfoFromGlobal);
  // const userInfo = localStorage.getItem("user");
  // console.log(JSON.parse(userInfo));
  // const userIDInformation = JSON.parse(userInfo);
  // const userID = userIDInformation.userIdentification;
  // console.log("userId",userID)

  // const userID = "08dc3c23-7f29-469d-83c8-36f53038dce7";
  // console.log(userID);

  // const { data, isLoading, isError, isSuccess, error } =
  //   useUserProfileQuery(userID);
  // console.log("userprofile data",data);

  // const showPersonalinfo = () => {
  //   navigate("/settings/userprofile");
  // };
  const handleUserlogout = () => {
    dispatch(logoutUser());
    navigate("/");
  };
  // const showLogindetails = () => {
  //   navigate("/settings/userprofile");
  // };

  const SmallAvatar = styled(Avatar)(({ theme }) => ({
    width: 22,
    height: 22,
    //   border: `2px solid ${theme.palette.background.paper}`,
  }));

  //delete user
  const [
    deleteUser,
    {
      isSuccess: isdeleteUserSuccess,
      isError: isdeleteUserError,
      error: deleteUserError,
    },
  ] = useDeleteUserMutation();
  const handleDelete = async () => {
    if (props.data.userId) {
      await deleteUser(props.data.userId);
    }
  };
  if (isdeleteUserSuccess) {
    toast.success("User Account deleted Successfully");
    navigate("/");
  }
  if (isdeleteUserError) {
    toast.error(deleteUserError);
    console.log(deleteUserError);
  }
  const [
    // uploadProfileImage,

    {
      isSuccess: isuploadSuccess,
      isError: isuploadError,
      error: uploadError,
    },
  ] = useUploadProfileImageMutation();
  const handleInputChange = (event) => {
    
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setSelectedImage(reader.result);
      };
    }
  };
  const handleImageUpload = async (event) => {
    // setFile(event.target.files[0])
    // const userId = props.data.userId
    // if(file){
    //   uploadProfileImage(userId,file)
    // }
    // setFile(event.target.files[0])
    const userId = props.data.userId;
    event.preventDefault();
    // const reader = new FileReader();
    // reader.onload = async (event) => {
    //   const binaryString = event.target.result;
    //   const data = {
    //     profileImage: binaryString,
    //   };
    //   console.log(binaryString);
    if (selectedImage) {
      console.log(selectedImage)
      try {
        const response = await axios.post(
          `https://sp-api.azgard.co.ke/api/Auth/UploadUserProfileImage?userId=${userId}`,
          {profileImage:selectedImage}
        );
        console.log(response)
        
      } catch (error) {
        console.error("Error:", error);
      }
    // };
    }
  };
  if (isuploadSuccess) {
    toast.success("User Account deleted Successfully");
    navigate("/");
  }
  if (isuploadError) {
    toast.error(uploadError);
    console.log(uploadError);
  }

  return (
    <Card
      elevation={1}
      sx={{
        marginLeft: 4,
        boxShadow: "0 20px 20px #D3D3D3",
        height: "100%",
        paddingTop: "3rem",
      }}
    >
      <Stack alignItems="center" spacing={2} sx={{ marginBottom: 2 }}>
        <Badge
          overlap="circular"
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          badgeContent={
            <SmallAvatar
              alt="Remy Sharp"
              sx={{ backgroundColor: "#FAD44F", padding: 2 }}
            >
              <IconButton>
                <ModeEditOutlineOutlinedIcon
                  size="small"
                  color="white"
                  onClick={handleOpen}
                />
              </IconButton>
            </SmallAvatar>
          }
        >
          <Avatar
            sx={{
              width: 96,
              height: 96,
            }}
            // src="https://randomuser.me/api/portraits/women/57.jpg"
            alt="profile"
            src={props.data.profileImageUrl}
          />
        </Badge>

        {/* {data && (
          <> */}
        <Stack direction="row" spacing={2}>
          <Typography fontWeight="bold" fontSize="1.1rem">
            {props.data.firstName}
          </Typography>
          <Typography fontWeight="bold" fontSize="1.1rem">
            {props.data.lastName}
          </Typography>
        </Stack>

        <Typography fontSize="0.9rem" sx={{ color: "#9E9E9E" }}>
          {/* {role} */}
          {props.data.role}
        </Typography>
        {/* </>
        )} */}
      </Stack>
      <CustomModal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <form>
            <input
              accept="image/*"
              // className={classes.input}
              id="contained-button-file"
              multiple
              type="file"
              placeholder="Email"
              onChange={handleInputChange}
              // style={{zIndex: 10000}}
            />
            <SmartPOSContainedButton
              color="primary"
              disableRipple
              disableElevation
              onClick={handleImageUpload}
              className={classes.loginBtn}
            >
              <Typography fontWeight="bold">Upload Image</Typography>
            </SmartPOSContainedButton>
          </form>
        </Box>
      </CustomModal>
      <Stack
        direction="row"
        spacing={6}
        justifyContent="center"
        divider={<Divider orientation="vertical" flexItem />}
      >
        <Stack>
          <Typography fontWeight="bold" fontSize="1.5rem">
            $1600
          </Typography>
          <Typography fontSize="0.9rem" sx={{ color: "#9E9E9E" }}>
            Earned
          </Typography>
        </Stack>
        <Stack>
          <Typography fontWeight="bold" fontSize="1.5rem">
            54
          </Typography>
          <Typography fontSize="0.9rem" sx={{ color: "#9E9E9E" }}>
            Orders
          </Typography>
        </Stack>
      </Stack>
      <Stack
        spacing={2}
        sx={{ paddingTop: 2, paddingLeft: 8, marginBottom: 2 }}
      >
        <List sx={{ textAlign: "center" }}>
          <ListItem disablePadding>
            <ListItemButton
              disableTouchRipple
              sx={styleObj}
              // onClick={() => showPersonalinfo()}
            >
              <ListItemIcon>
                <AccountCircleIcon />
              </ListItemIcon>
              <ListItemText>
                <Typography sx={{ fontSize: "0.9rem" }}>
                  Personal information
                </Typography>
              </ListItemText>
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton
              disableTouchRipple
              sx={styleObj}
              // onClick={() => showLogindetails()}
            >
              <ListItemIcon>
                <LockIcon />
              </ListItemIcon>
              <ListItemText>
                <Typography sx={{ fontSize: "0.9rem" }}>
                  Login & Password
                </Typography>
              </ListItemText>
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton
              disableTouchRipple
              sx={styleObj}
              onClick={() => handleUserlogout()}
            >
              <ListItemIcon>
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText>
                <Typography sx={{ fontSize: "0.9rem" }}>Log out</Typography>
              </ListItemText>
            </ListItemButton>
          </ListItem>
        </List>
      </Stack>

      <Stack
        direction="row"
        sx={{ paddingLeft: 10, paddingBottom: 2 }}
        spacing={2}
        alignItems="center"
      >
        <DeleteForeverOutlinedIcon fontSize="small" style={{ fill: "red" }} />
        <Button
          variant="h6"
          fontSize={16}
          sx={{ color: "red" }}
          onClick={handleDelete}
        >
          Delete account
        </Button>
      </Stack>
    </Card>
  );
};

export default React.memo(LeftSideProfile);
