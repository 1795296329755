import React from "react";
import { Box, Stack, Typography, useTheme } from "@mui/material";
import ProductImageList from "./IngredientImageList";
import { useSelector } from "react-redux";

function CaptionTitle({ caption, value }) {
  return (
    <Box sx={{ textAlign: "start", padding: "10px" }}>
      <Typography variant="caption" sx={{ textTransform: "capitalize" }}>
        {caption}
      </Typography>
      <Typography
        variant="h3"
        sx={{ fontWeight: "600", textTransform: "capitalize" }}
      >
        {value}
      </Typography>
    </Box>
  );
}

function IngredientPreview() {
  const theme = useTheme();
  const formData = useSelector((state) => state.newProductFormData);
  return (
    <Box
      sx={{
        borderRadius: "10px",
        padding: "20px 40px",
        backgroundColor: theme.palette.background.main,
        border: "2px solid",
        borderColor: theme.palette.background.dark,
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        spacing={3}
        sx={{ padding: "20px 0px", textAlign: "start" }}
      >
        <Box>
          <CaptionTitle caption="Price" value={formData.price} />
          <CaptionTitle caption="product name" value={formData.title} />
        </Box>
        <Box>
          <CaptionTitle caption="status" value={formData.status} />
          <CaptionTitle caption="quantity" value={formData.quantity} />
        </Box>
      </Stack>
      {/* <CaptionTitle caption="description" value={formData.description} /> */}
    </Box>
  );
}

export default IngredientPreview;
