import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  parent: {
    name: "dashboard",
    path: "/dashboard",
  },
  child: {
    name: "sale statistics",
    path: "/dashboard",
  },
};

export const navigationSlice = createSlice({
  name: "navigation",
  initialState,
  reducers: {
    add: (state, action) => {
      state.parent = action.payload.parent
        ? action.payload.parent
        : state.parent;
      state.child = action.payload.child ? action.payload.child : "";
    },
  },
});
export const { add } = navigationSlice.actions;

export default navigationSlice.reducer;
