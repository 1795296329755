import { Stack } from "@mui/material";
import { AdminLayout } from "../../../layout/admin/AdminLayout";
import { useLocation } from "react-router";
import { useOrderQuery } from "../../../store/apiSlice";

import { OrderDetailCard } from "../order-details-card/index";
import { OrderInvoicePreview } from "../order-invoice-preview";
import theme from "../../../Themes/Default";
import { useEffect, useState } from "react";

export const OrderDetails = () => {
  const [statusUpdated, setStatusUpdated] = useState(false);
  const location = useLocation();
  const id = location.pathname.split("/").pop();
  const { data, isSuccess, refetch } = useOrderQuery({ id });

  const handleStatusChange = () => {
    setStatusUpdated(true);
  };
  useEffect(() => {
    refetch();
  }, [statusUpdated, refetch]);
  if (isSuccess) {
    return (
      <AdminLayout title="Order Details">
        <Stack
          sx={{
            height: "100%",
            backgroundColor: theme.palette.background.light,
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gridTemplateRows: "1fr",
            gridTemplateAreas: `
            "order receipt"`,
            padding: "0rem 1.5rem",
          }}
          gap={3}
        >
          <OrderDetailCard
            data={data}
            handleStatusChange={handleStatusChange}
          />
          <OrderInvoicePreview order={data} />
        </Stack>
      </AdminLayout>
    );
  }
};
