import { Box } from "@mui/material";
import React from "react";
import { SideBar } from "./SideBar";
import styled from "@emotion/styled";
import Header from "./Header";
import { Outlet } from "react-router-dom";
import theme from "../../Themes/Default";

const StyledLayout = styled(Box)({
  display: "grid",
  gridTemplateColumns: "15rem 1fr",
  gridTemplateRows: "auto 1fr",
  height: "90vh",
});

const Main = styled(Box)({
  backgroundColor: theme.palette.background.dark,
  padding: "0.75rem",
  height: "92vh",
  overflow: "auto",
});

export function Layout() {
  return (
    <StyledLayout>
      <Header />
      <SideBar />
      <Main component={"main"}>
        <Outlet />
      </Main>
    </StyledLayout>
  );
}