import React, { useState } from "react";
import {
  List,
  ListItem,
  ListItemText,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { CustomButton } from "../../../components";
import theme from "../../../Themes/Default";
import { currency } from "../../../utils/currency";
import { useDispatch, useSelector } from "react-redux";
import {
  setOrderGuests,
  setOrderTable,
  updateProducts,
} from "../../../store/order-slice";
import {
  useCreateOrderItemsRangeMutation,
  useCreateOrderMutation,
} from "../../../store/apiSlice";
import toast from "react-hot-toast";

export const Order = () => {
  const hotelId = JSON.parse(localStorage.getItem("user")).hotelId;
  const order = useSelector((state) => state.order);
  const [table, setTable] = useState("");
  const [guests, setGuests] = useState("");
  const dispatch = useDispatch();
  const [createOrder] = useCreateOrderMutation();
  const [createOrderitems] = useCreateOrderItemsRangeMutation();

  const handleTableChange = (e) => {
    setTable(e.target.value);
  };
  const handleGuestsChange = (e) => {
    setGuests(e.target.value);
  };
  dispatch(setOrderTable(table));
  dispatch(setOrderGuests(parseInt(guests)));
  const handleRemoveProduct = (id) => {
    const products = order.products.filter((item) => item.id !== id);
    dispatch(updateProducts(products));
  };
  const handlePlaceOrder = async () => {
    try {
      // console.log(order);
      toast.loading("creating order...");
      const orderResult = await createOrder({
        time: new Date().toISOString(),
        status: "active",
        orderType: order.orderType,
        guests: order.orderType === "dine in" ? order.guests : 0,
        table: order.orderType === "dine in" ? order.table : "",
        totalPrice: 0,
        paymentType: "cash",
        paymentStatus: "pending",
        totalVAT: 0,
        hotelId: hotelId,
      });
      // console.log(orderResult);
      if (orderResult.data.message === "Order Created Successfully") {
        toast.dismiss();
        toast.success("order created.");

        toast.loading("Adding order items");
        const orderItemsResult = await createOrderitems({
          orderId: orderResult.data.order.id,
          orderItems: order.products.map((item) => {
            return { quantity: item.quantity, productId: item.id };
          }),
        });

        if (orderItemsResult.data.status === "Success") {
          toast.dismiss();
          toast.success("Order Items added.");
        } else {
          throw new Error(orderItemsResult);
        }
      } else {
        throw new Error(orderResult);
      }
    } catch (error) {
      toast.dismiss();
      toast.error("Unexpected error occured");
    }
  };

  const handleCancleOrder = () => {
    dispatch(updateProducts([]));
  };

  return (
    <Stack
      flex={1.5}
      sx={{
        border: "2px solid",
        borderColor: theme.palette.background.dark,
        borderRadius: "1rem",
        padding: "1.5rem",
        height: "100%",
      }}
      gap={2}
    >
      <Stack
        sx={{
          borderBottom: "4px solid",
          borderColor: theme.palette.background.dark,
          paddingY: "1rem",
        }}
      >
        <Typography variant="h2" sx={{ fontWeight: "bold" }}>
          New Order
        </Typography>
      </Stack>
      <Stack direction="row" gap={2}>
        <CustomButton
          variant={`${order.orderType === "dine in" ? "contained" : "oulined"}`}
          color={`${order.orderType === "dine in" ? "primary" : "error"}`}
        >
          Dine
        </CustomButton>
        <CustomButton
          variant={`${
            order.orderType === "take away" ? "contained" : "oulined"
          }`}
          color={`${order.orderType === "take away" ? "primary" : "error"}`}
        >
          Take Away
        </CustomButton>
      </Stack>
      {order.orderType === "dine in" && (
        <Stack direction={"row"} gap={3}>
          <TextField
            label="Table Number"
            value={table}
            onChange={handleTableChange}
          />
          <TextField
            label="No of Guests"
            value={guests}
            onChange={handleGuestsChange}
          />
        </Stack>
      )}

      <List
        sx={{
          width: "100%",
          height: "80%",
          overflowY: "auto",
        }}
      >
        <ListItem
          sx={{
            width: "100%",
            justifyContent: "space-between",
            borderBottom: "2px solid",
            borderColor: theme.palette.background.dark,
          }}
          disablePadding
        >
          <ListItemText
            primary="Items"
            primaryTypographyProps={{
              sx: {
                fontWeight: "bold",
              },
            }}
          />
          <ListItemText
            primary="Price"
            primaryTypographyProps={{
              sx: {
                fontWeight: "bold",
              },
            }}
          />
        </ListItem>
        {order.products.map((item) => (
          <ListItem
            key={item.id}
            sx={{
              width: "100%",
              justifyContent: "space-between",
              marginY: "0.25rem",
            }}
            disablePadding
            secondaryAction={
              <CustomButton
                edge="end"
                color="error"
                onClick={() => handleRemoveProduct(item.id)}
              >
                <CloseIcon />
              </CustomButton>
            }
          >
            <ListItemText
              primary={item.name}
              secondary={`x ${item.quantity}`}
              sx={{ textTransform: "capitalize" }}
            />
            <ListItemText primary={currency(item.totalPrice)} />
          </ListItem>
        ))}
        <ListItem
          sx={{
            display: "flex",
            justifyContent: "space-between",
            borderTop: "2px solid",
            paddingTop: "2rem",
            borderColor: theme.palette.background.dark,
          }}
          disablePadding
        >
          <ListItemText
            primary="Sub Total"
            primaryTypographyProps={{
              sx: {
                fontWeight: "bold",
              },
            }}
          />
          <ListItemText primary={currency(order.subtotal)} />
        </ListItem>
        <ListItem
          sx={{ display: "flex", justifyContent: "space-between" }}
          disablePadding
        >
          <ListItemText
            primary="Tax"
            primaryTypographyProps={{
              sx: {
                fontWeight: "bold",
              },
            }}
          />
          <ListItemText primary={currency(order.tax)} />
        </ListItem>
        <ListItem
          sx={{ display: "flex", justifyContent: "space-between" }}
          disablePadding
        >
          <ListItemText
            primary="Payable Amount"
            primaryTypographyProps={{
              sx: {
                fontWeight: "bold",
              },
            }}
          />
          <ListItemText primary={currency(order.payableAmount)} />
        </ListItem>

        <Stack
          direction={"row"}
          gap={3}
          sx={{ marginTop: "1rem" }}
          alignItems={"center"}
        >
          <CustomButton
            onClick={handleCancleOrder}
            color="primary"
            variant="outlined"
          >
            Cancel
          </CustomButton>
          <CustomButton
            onClick={handlePlaceOrder}
            color="success"
            variant="contained"
          >
            Place Order
          </CustomButton>
        </Stack>
      </List>
    </Stack>
  );
};
