import React, { useEffect} from "react";

import {
  Box,
  TextField,
  Typography,
  Link
} from "@mui/material";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";
import SmartPOSContainedButton from "./components/Buttons/SmartPOSContainedButton";
import { loginPageStyles } from "./assets/Jss/LoginpageStyles";
import { useResetPasswordTokenMutation } from "../../store/apiSlice.js";
import { useNavigate } from "react-router-dom";
const ResetPassToken = () => {
  const { classes } = loginPageStyles();
  const navigate = useNavigate()
  const initialState = {
    email: ""
  };

  //validation
  const validationSchema = Yup.object().shape({
    email: Yup.string().email("please enter valid email").required("Required"),
  });
  //reset password
  const [
    resetPasswordToken,
    {
      isLoading: isgetTokenLoading,
      isSuccess: isgetTokenSuccess,
      isError: isgetTokenError,
      error: getTokenError,
    },
  ] = useResetPasswordTokenMutation();

  const handleGetResetPassToken = async (value, props) => {
    if (value) {
      const email = value.email;
      await resetPasswordToken(email);
    }
  };
  useEffect(() => {
    if (isgetTokenSuccess) {
      toast.success("Password reset Token has been sent to your email");
      navigate("/resetwithtoken");
    }
  }, [isgetTokenSuccess]);
  useEffect(() => {
    if (isgetTokenError) {
       toast.error(getTokenError.data.message);
    }
  }, [isgetTokenError]);

  return (
    <Box sx={{ width: "100%" }}>
      <Typography fontSize="1.5rem" fontWeight="bold">
        Get Password Reset Token
      </Typography>
      <Typography color="textSecondary" fontSize="1rem">
        Enter your Email To get A Password Reset Token
      </Typography>
      <Formik
        initialValues={initialState}
        onSubmit={handleGetResetPassToken}
        validationSchema={validationSchema}
      >
        {(props) => (
          <Form>
            <Field
              as={TextField}
              variant="outlined"
              margin="dense"
              fullWidth
              name="email"
              error={props.errors.email}
              helperText={<ErrorMessage name="email" />}
              className={classes.loginInput}
              placeholder="Email"
            />
            <SmartPOSContainedButton
              color="primary"
              disableRipple
              loading={isgetTokenLoading}
              loadingIndicator="Loading…"
              disableElevation
              className={classes.loginBtn}
              type="submit"
            >
              <Typography fontWeight="bold">Submit</Typography>
            </SmartPOSContainedButton>
          </Form>
        )}
      </Formik>
      <Box marginY={2} textAlign="center">
        <Typography variant="body2" color="textSecondary">
          Don't have an account?
          <Link
            sx={{
              color: "#1976d2",
              textDecoration: "none",
              cursor: "pointer",
            }}
            variant="body2"
            onClick={() => {
              navigate("/register");
            }}
          >
            Go to Registration
          </Link>
        </Typography>
      </Box>
      <Box marginY={2} textAlign="center">
        <Typography variant="body2" color="textSecondary">
          Already have an account?
          <Link
            sx={{
              color: "#1976d2",
              textDecoration: "none",
              cursor: "pointer",
            }}
            variant="body2"
            onClick={() => {
              navigate("/");
            }}
          >
            Sign in
          </Link>
        </Typography>
      </Box>
    </Box>
  );
};

export default ResetPassToken;
