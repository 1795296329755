import { Stack } from "@mui/material";
import React from "react";
import { AdminHeader } from "./AdminHeader";
import { useTheme } from "@emotion/react";

export function AdminLayout({ title, ButtonComponent, children }) {
  const theme = useTheme();
  return (
    <Stack
      spacing={1}
      sx={{ padding: "10px", backgroundColor: theme.palette.background.dark }}
    >
      {ButtonComponent ? (
        <AdminHeader title={title} ButtonComponent={ButtonComponent} />
      ) : (
        <AdminHeader title={title} />
      )}

      <Stack
        spacing={2}
        sx={{
          height: "80vh",
          backgroundColor: "white",
          padding: "20px 40px",
          borderRadius: "10px",
        }}
      >
        {children}
      </Stack>
    </Stack>
  );
}
