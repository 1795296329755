import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  filter: 1, //today
};

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    changeFilter: (state, action) => {
      state.filter = action.payload;
    },
  },
});

export const { changeFilter } = dashboardSlice.actions;

export default dashboardSlice.reducer;
