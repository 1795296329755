import React from "react";
import { DataTable } from "../../../features";
import { columns } from "../column";
import { useProductsListQuery } from "../../../store/apiSlice";
import { Box } from "@mui/material";

const ProductsList = () => {
  const hotelId = JSON.parse(localStorage.getItem("user")).hotelId;
  const { data, isSuccess, isLoading, refetch } = useProductsListQuery({
    hotelId: hotelId,
  });

  if (isLoading) {
    return <Box>Loading....</Box>;
  }
  if (isSuccess) {
    return (
      <DataTable
        data={data.$values}
        columns={columns}
        exportFileName="products"
      />
    );
  }
};

export default ProductsList;
