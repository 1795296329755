import { Avatar, Typography } from "@mui/material";
import { ActionMenu } from "../../features";
import { CheckBox } from "../../components";
import moment from "moment";

export const columns = [
  {
    id: "select",
    header: ({ table }) => (
      <CheckBox
        {...{
          checked: table.getIsAllRowsSelected(),
          indeterminate: table.getIsSomeRowsSelected(),
          onChange: table.getToggleAllRowsSelectedHandler(),
        }}
      />
    ),
    cell: ({ row }) => (
      <CheckBox
        {...{
          checked: row.getIsSelected(),
          disabled: !row.getCanSelect(),
          indeterminate: row.getIsSomeSelected(),
          onChange: row.getToggleSelectedHandler(),
        }}
      />
    ),
  },
  {
    header: "Image",
    accessorKey: "productImageUrl",
    // cell: (info) => <Avatar src={placeHolderImage} alt={info?.name} />,
    cell: (info) => <Avatar src={info?.getValue()} alt={info?.name} />,
  },
  {
    header: "Name",
    accessorKey: "name",
    cell: (info) => (
      <Typography sx={{ textTransform: "capitalize" }}>
        {info.getValue()}
      </Typography>
    ),
  },
  {
    header: "Category",
    accessorKey: "productCategory",
  },
  {
    header: "Price",
    accessorKey: "price",
  },
  {
    header: "Type",
    accessorKey: "productType",
  },
  {
    header: "Updated On",
    accessorKey: "updatedOn",
    cell: (info) => moment(info?.getValue()).format("Do MMMM, YYYY"),
  },
  {
    header: "Action",
    accessorKey: "id",
    cell: ({ row }) => (
      <ActionMenu row={row} viewRoute={`/products/${row.original.id}`} />
    ),
  },
];
