import {
  Avatar,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material";
import theme from "../../Themes/Default";
import { NavLink } from "react-router-dom";
import { useDispatch } from "react-redux";
import { add } from "../../store/navigationSlice";
import { reset } from "../../store/categorySlice";

export default function NavItem({
  name,
  icon: IconComponent,
  path,
  childName,
}) {
  const dispatch = useDispatch();
  function handleClick() {
    dispatch(reset());

    dispatch(
      add({
        parent: { name: name, path: path },
        child: { name: childName, path: path },
      })
    );
  }
  return (
    <ListItem>
      <NavLink
        onClick={handleClick}
        to={path}
        style={({ isActive, isPending, isTransitioning }) => {
          return {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            textDecoration: "none",
            backgroundColor: isActive ? theme.palette.primary.main : "white",
            color: isPending ? "red" : "black",
            viewTransitionName: isTransitioning ? "slide" : "",
            width: "100%",
            borderRadius: "10px",
          };
        }}
      >
        <ListItemAvatar sx={{}}>
          <Avatar
            sx={{
              width: 28,
              height: 28,
              background: "none",
            }}
          >
            {IconComponent && (
              <IconComponent sx={{ fontSize: 18, color: "gray" }} />
            )}
          </Avatar>
        </ListItemAvatar>
        <ListItemText>
          <Typography
            variant="p"
            sx={{
              fontSize: "1rem",
            }}
          >
            {name}
          </Typography>
        </ListItemText>
      </NavLink>
    </ListItem>
  );
}
