import DashboardIcon from "@mui/icons-material/Dashboard";
import LunchDiningIcon from "@mui/icons-material/LunchDining";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import TuneIcon from "@mui/icons-material/Tune";
import MarkEmailUnreadIcon from "@mui/icons-material/MarkEmailUnread";
import { routesEnum } from "./routes";

export const waiterNavItems = [
  {
    id: 1,
    icon: DashboardIcon,
    name: "Dashboard",
    path: routesEnum.DASHBOARD,
    childName: "sales statistics",
  },
  {
    id: 2,
    icon: LunchDiningIcon,
    name: "Food & Drinks",
    path: routesEnum.FOOD_AND_DRINKS,
    childName: "categories",
  },
  {
    id: 3,
    icon: RequestQuoteIcon,
    name: "Orders",
    childName: "Orders",
    path: routesEnum.ORDERS,
  },

  {
    id: 4,
    icon: TuneIcon,
    name: "Special Menu",
    childName: "Special Menu",
    path: routesEnum.SPECIAL_MENU,
  },
  {
    id: 5,
    icon: MarkEmailUnreadIcon,
    name: "Shifts",
    childName: "Shifts",
    path: routesEnum.SHIFTS,
  },
];
