import { createSlice } from "@reduxjs/toolkit";

const selectedSubcategoriesSlice = createSlice({
  name: "selectedSubcategories",
  initialState: {
    subcategories: 0,
  },
  reducers: {
    updateSubcategories: (state, action) => {
      state.subcategories = action.payload;
    },
  },
});
export const { updateSubcategories } = selectedSubcategoriesSlice.actions;
export default selectedSubcategoriesSlice.reducer;
