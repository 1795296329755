import React, { useState } from "react";
import { Box, Stack, Tab, Tabs } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useCategoriesListQuery } from "../../store/apiSlice";
import { Puff } from "react-loader-spinner";
import { AdminLayout } from "../../layout/admin/AdminLayout";
import PropTypes from "prop-types";
import { CategoryProductsList } from "./category-products-list";
import { Order } from "./order";
import theme from "../../Themes/Default";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`category-tabpanel-${index}`}
      aria-labelledby={`category-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `category-tab-${index}`,
    "aria-controls": `category-tabpanel-${index}`,
  };
}

function FoodAndDrinks() {
  const dispatch = useDispatch();
  const orderProductsCount = useSelector(
    (state) => state.order.products.length
  );
  const isOrder = orderProductsCount > 0 ? true : false;
  const [tabValue, setTabValue] = useState(0);
  const hotelId = JSON.parse(localStorage.getItem("user")).hotelId;
  const { data, isSuccess, isLoading, isError } = useCategoriesListQuery({
    hotelId: hotelId,
  });

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  if (isLoading) {
    return (
      <AdminLayout title="Food And Categories">
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Puff
            visible={true}
            height="80"
            width="80"
            color="#FFCA40"
            ariaLabel="puff-loading"
            wrapperStyle={{}}
            wrapperClass=""
          />
        </Box>
      </AdminLayout>
    );
  }

  if (isError) {
    return "Something went wrong";
  }

  if (isSuccess) {
    return (
      <AdminLayout title="Food And Categories">
        <Stack
          direction="row"
          sx={{
            width: "100%",
            height: "100%",
            flex: 1,
          }}
        >
          <Stack flex={3} sx={{ height: "100%" }}>
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              aria-label="categories"
              sx={{
                borderBottom: "1px solid",
                borderColor: theme.palette.primary.main,
              }}
            >
              {data.$values.map((item, index) => (
                <Tab key={item.id} label={item.name} {...a11yProps(index)} />
              ))}
            </Tabs>
            {data.$values.map((item, index) => (
              <CustomTabPanel key={item.id} value={tabValue} index={index}>
                <CategoryProductsList category={item} />
              </CustomTabPanel>
            ))}
          </Stack>
          {isOrder && <Order />}
        </Stack>
      </AdminLayout>
    );
  }
}

export default FoodAndDrinks;
