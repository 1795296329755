import { Box, Typography, TextField, Grid, Button, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import { AdminLayout } from "../../../layout";
import { useLocation, useNavigate } from "react-router";
import {
  useDeleteProductMutation,
  useProductDetailsQuery,
} from "../../../store/apiSlice";
import { currency } from "../../../utils/currency";
import { CustomButton } from "../../../components";
import { AddProductIngredient } from "./AddProductIngredient";
import { DeleteModal } from "../../../features";
import toast from "react-hot-toast";
import { routesEnum } from "../../../utils/routes";
import { ProductIngredient } from "./ProductIngredient";

const ButtonComponent = () => {
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <CustomButton
      variant="outlined"
      onClick={() =>
        navigate(`/products/${location.pathname.split("/")[2]}/image`)
      }
    >
      Upload Image
    </CustomButton>
  );
};

const Product = () => {
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const productId = location.pathname.split("/")[2];
  const hotelId = JSON.parse(localStorage.getItem("user")).hotelId;
  const [modalOpen, setModalOpen] = useState(false);
  const {
    data: product,
    isSuccess,
    refetch,
  } = useProductDetailsQuery({
    hotelId: hotelId,
    productId: productId,
  });

  useEffect(() => {
    refetch();
  }, [modalOpen]);
  const [deleteProduct] = useDeleteProductMutation();

  const handleClose = () => {
    setModalOpen(false);
  };

  const handleDelete = async () => {
    try {
      const result = await deleteProduct({ id: productId });
      toast.loading("Deleting...");
      if (result.data.status.toLowerCase() === "success") {
        toast.dismiss();
        toast.success("Product Deleted SuccessFully.");
        navigate(routesEnum.ALL_PRODUCTS);
      }
    } catch (error) {
      toast.dismiss();
      toast.error("Unable to delete Product.");
    }
  };
  if (isSuccess) {
    return (
      <AdminLayout title={"Product Details"} ButtonComponent={ButtonComponent}>
        <Box>
          <Box>
            <Grid container spacing={2} sx={{ marginY: 2 }}>
              <Grid item md>
                <TextField
                  margin="dense"
                  variant="standard"
                  label="Category"
                  sx={{ textTransform: "capitalize" }}
                  value={product.productCategory ? product.productCategory : ""}
                  fullWidth
                />
              </Grid>
              <Grid item md>
                <TextField
                  margin="dense"
                  variant="standard"
                  label="Name"
                  sx={{ textTransform: "capitalize" }}
                  value={product.name}
                  fullWidth
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} sx={{ marginY: 2 }}>
              <Grid item md>
                <TextField
                  margin="dense"
                  variant="standard"
                  label="Buying Price"
                  value={currency(product.price)}
                  fullWidth
                />
              </Grid>
              <Grid item md>
                <TextField
                  margin="dense"
                  variant="standard"
                  label="Unit Price"
                  value={currency(product.price)}
                  fullWidth
                />
              </Grid>
            </Grid>
          </Box>

          <Box sx={{ marginY: 2 }}>
            <Stack direction={"row"} gap={4} alignItems={"center"}>
              <Typography variant="body2" color="textSecondary" gutterBottom>
                Ingredients
              </Typography>
              <CustomButton
                variant="outlined"
                onClick={() => setModalOpen(true)}
              >
                <Typography variant="caption" color={"black"}>
                  Add Product Ingredient
                </Typography>
              </CustomButton>
            </Stack>
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                gap: 3,
                padding: "1rem",
              }}
            >
              {product.ingredients
                ? product.ingredients.$values.map((productIgredient, index) => (
                    <ProductIngredient
                      name={productIgredient.ingredient.name}
                      price={productIgredient.ingredient.price}
                      quantity={productIgredient.ingredient.quantity}
                      imageUrl={productIgredient.ingredient.image}
                    />
                  ))
                : null}
            </Box>
          </Box>

          <Box sx={{ marginY: 2 }}>
            <Button
              variant="outlined"
              sx={{ marginRight: 1, borderColor: "#F97324", color: "#F97324" }}
              onClick={() => setIsDeleteModal(true)}
            >
              Delete
            </Button>
            <Button
              variant="contained"
              sx={{
                marginLeft: 2,
                background: "#F97324",
                color: (theme) => theme.palette.background.main,
              }}
              onClick={() => navigate(`/products/${product.id}/edit`)}
            >
              Edit
            </Button>
          </Box>
        </Box>
        {modalOpen && (
          <AddProductIngredient
            open={modalOpen}
            handleClose={handleClose}
            productId={product.id}
            hotelId={hotelId}
          />
        )}
        {isDeleteModal && (
          <DeleteModal
            open={isDeleteModal}
            handleClose={() => setIsDeleteModal(false)}
            handleDelete={handleDelete}
            item={product.name}
          />
        )}
      </AdminLayout>
    );
  }
};

export default Product;
