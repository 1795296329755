import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import { currency } from "../../../utils/currency";

export const IngredientCard = ({ ingredient }) => {
  return (
    <Stack sx={{ marginTop: "2rem" }} gap={2}>
      <Stack direction="row" spacing={2} justifyContent="space-between" mb={2}>
        <Typography variant="h3" gutterBottom>
          Name:{" "}
          <span
            style={{
              fontWeight: "bold",
              textTransform: "capitalize",
              scale: "2",
            }}
          >
            {ingredient.name}
          </span>
        </Typography>
        <Typography variant="h3" gutterBottom>
          Status:{" "}
          <span
            style={{
              fontWeight: "bold",
              textTransform: "capitalize",
              scale: "2",
            }}
          >
            {ingredient.status}
          </span>
        </Typography>
      </Stack>
      <Stack direction="row" spacing={2} justifyContent="space-between" mb={2}>
        <Typography variant="h3">
          Price:{" "}
          <span
            style={{
              fontWeight: "bold",
              textTransform: "capitalize",
              scale: "2",
            }}
          >
            {currency(ingredient.price)}
          </span>
        </Typography>

        <Typography variant="h3" gutterBottom>
          Amount in Stock:{" "}
          <span
            style={{
              fontWeight: "bold",
              textTransform: "capitalize",
              scale: "2",
            }}
          >
            {ingredient.quantity}
          </span>
        </Typography>
      </Stack>
    </Stack>
  );
};
