import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  MenuItem,
  Stack,
  TextField,
} from "@mui/material";
import { useCategoriesListQuery } from "../../../store/apiSlice";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import { uuidv4 } from "../../../utils/uuid";
import {
  addCategoryName,
  addProductName,
  addProductType,
  addSubCategoryPrice,
} from "../../../store/admin/subCategories/newSubCategorySlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { routesEnum } from "../../../utils/routes";

const productTypes = [
  {
    label: "food",
    value: "food",
  },
  {
    label: "drinks",
    value: "drinks",
  },
];

export const ProductsForm = ({ handleFormSubmit, data }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const hotelId = JSON.parse(localStorage.getItem("user")).hotelId;
  const { data: categories, isSuccess } = useCategoriesListQuery({
    hotelId: hotelId,
  });

  const [category, setCategory] = useState(data ? data.productCategory : "");
  const [productName, setProductName] = useState(data ? data.name : "");
  const [categoryId, setCategoryId] = useState("");
  const [price, setPrice] = useState(data ? data.price : 0);
  const [productType, setProductType] = useState(data ? data.productType : "");

  useEffect(() => {
    if (isSuccess && categories && categories.$values) {
      const selectedCategory = categories.$values.find(
        (item) => item.name && item.name === category
      );
      if (selectedCategory) {
        setCategoryId(selectedCategory.id);
      }
    }
  }, [category, categories, isSuccess]);

  const handleCategoryChange = (e) => {
    const categoryName = e.target.value;
    setCategory(categoryName);
    dispatch(addCategoryName(categoryName));
  };

  const handleNameChange = (e) => {
    const name = e.target.value;
    setProductName(name);
    dispatch(addProductName(name));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const body = {
      name: productName,
      category,
      price: Number(price),
      productType,
      productId: uuidv4(),
      productCategoryId: categoryId,
      hotelId,
      vat: 0,
    };
    handleFormSubmit(body);
  };

  return (
    <Card variant="outlined">
      <CardContent>
        <Box
          component={"form"}
          onSubmit={handleSubmit}
          sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}
          encType="multipart/form-data"
        >
          {/* <UploadMediaFile onUpload={onUpload} /> */}
          {/* <TextField
            type="file"
            id="productImage"
            label="Product Image"
            helperText="Click and select image to upload."
            sx={{ cursor: "pointer" }}
            onChange={onUpload}
          ></TextField> */}
          <TextField
            label="Category"
            select
            sx={{ width: "100%" }}
            value={category}
            onChange={handleCategoryChange}
          >
            {categories &&
              categories.$values.map((item, index) => (
                <MenuItem key={index} value={item.name}>
                  {item.name}
                </MenuItem>
              ))}
          </TextField>
          <TextField
            label="Product Name"
            value={productName}
            onChange={handleNameChange}
            sx={{ width: "100%" }}
          />
          <TextField
            type="number"
            label="Price"
            value={price}
            onChange={(e) => {
              const priceValue = e.target.value;
              setPrice(priceValue);
              dispatch(addSubCategoryPrice(priceValue));
            }}
          />
          <TextField
            select
            label="Product Type"
            value={productType}
            onChange={(e) => {
              const type = e.target.value;
              setProductType(type);
              dispatch(addProductType(type));
            }}
          >
            {productTypes.map((item) => (
              <MenuItem key={item.label} value={item.value}>
                {item.value}
              </MenuItem>
            ))}
          </TextField>
          <Stack direction="row" gap={4} sx={{ justifyContent: "flex-end" }}>
            <Button
              variant="outlined"
              endIcon={<CloseIcon />}
              onClick={() => navigate(routesEnum.ALL_SUBCATEGORIES)}
            >
              Cancel
            </Button>
            <Button variant="contained" type="submit" endIcon={<DoneIcon />}>
              Submit
            </Button>
          </Stack>
        </Box>
      </CardContent>
    </Card>
  );
};
