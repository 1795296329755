import React from "react";
import Wrapper from "./UI/Wrapper";
import {
  List,
  ListItem,
  ListItemText,
  Typography,
  Box,
  ListItemAvatar,
  Avatar,
} from "@mui/material";
import { LunchDining } from "@mui/icons-material";
import ReusableCardHeader from "./UI/ReusableCardHeader";

const dishesList = [
  {
    id: 1,
    category: "food",
    name: "cheeseburger",
    orders: 89,
  },
  {
    id: 2,
    category: "food",
    name: "cheeseburger",
    orders: 89,
  },
  {
    id: 3,
    category: "drink",
    name: "mojito",
    orders: 60,
  },
  {
    id: 4,
    category: "drink",
    name: "coffee",
    orders: 100,
  },
];

function Dishes() {
  return (
    <Wrapper>
      <List sx={{ width: "90%" }}>
        <ReusableCardHeader title={'Top Dishes'}/>
        <ListItem
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <ListItemText
            primary={
              <Typography variant="span" sx={{ fontWeight: "bold" }}>
                Dish
              </Typography>
            }
          />
          <Box>
            <ListItemText
              primary={
                <Typography variant="span" sx={{ fontWeight: "bold" }}>
                  Orders
                </Typography>
              }
            />
          </Box>
        </ListItem>
        {dishesList.map((row) => (
          <ListItem
            key={row.id}
            sx={{
              justifyContent: "space-between",
              display: "flex",
              alignItems: "center",
              paddingRight: "30px",
            }}
          >
            <Box sx={{ display: "flex" }}>
              <ListItemAvatar>
                <Avatar
                  sx={{
                    backgroundColor:
                      row.category === "food" ? "#FFCA40" : "#E0F5F6",
                    opacity: "0.4",
                  }}
                >
                  <LunchDining sx={{ color: "gray" }} />
                </Avatar>
              </ListItemAvatar>
              <Box
                sx={{
                  textTransform: "capitalize",
                  flex: 1,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Typography
                  sx={{
                    backgroundColor:
                      row.category === "food" ? "#FFCA40" : "#E0F5F6",
                    width: "max-content",
                    paddingX: "5px",
                    borderRadius: "10px",
                    display: "inline-block",
                  }}
                  variant="span"
                >
                  {row.category}
                </Typography>
                <Typography variant="span" sx={{ flex: 1 }}>
                  {row.name}
                </Typography>
              </Box>
            </Box>
            <Typography variant="span" sx={{}}>
              {row.orders}
            </Typography>
          </ListItem>
        ))}
      </List>
    </Wrapper>
  );
}

export default Dishes;
