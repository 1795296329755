import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";
import { useRegisterHotelMutation } from "../../store/apiSlice.js";
import { loginPageStyles } from "./assets/Jss/LoginpageStyles";
import SmartPOSContainedButton from "./components/Buttons/SmartPOSContainedButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Stack,
  TextField,
  Link,
  Typography,
  IconButton,
  InputAdornment,
  FormControlLabel,
  Switch,
} from "@mui/material";
const RegisterForm = () => {
  const { classes } = loginPageStyles();
  const [showPassword, setShowPassword] = useState(false);
  const [toggle, setToggle] = useState();
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const initialState = {
    hotelName: "",
    adminEmail: "",
    // password: "",
    // email: "",
    // role: "",
    // isAutogen: false,
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const navigate = useNavigate();
  //register user
  const [
    registerUser,

    {
      isLoading: isregisterLoading,
      isSuccess: isregisterSuccess,
      isError: isregisterError,
      error: registerError,
    },
  ] = useRegisterHotelMutation();
  const validationSchema = Yup.object().shape({
    adminEmail: Yup.string()
      .email("please enter valid email")
      .required("Required"),
    hotelName: Yup.string()
      .required("Required")
      .min(3, "name must have at least 3 characters"),
    // lastName: Yup.string()
    //   .required("Required")
    //   .min(3, "name must have at least 3 characters"),
    // role: Yup.string().required("Required"),

    // password: Yup.string()
    //   .min(8, "Password must have at least 8 characters")
  });
  const handleToggle = (event) => {
    console.log(event.target.checked);
    setToggle(event.target.checked);
  };
  const handleUserRegistration = async (values, props) => {
    if (values) {
      const adminEmail = values.adminEmail;
      const hotelName = values.hotelName;
      // const lastName = values.lastName;
      // const role = values.role;
      // const password = values.password;
      //  const password = values.password ? values.password : "";
      const isAutogen = toggle;
      console.log(hotelName,adminEmail);
      await registerUser({
        hotelName,
        adminEmail
        // lastName,
        // role,
        // password,
        // isAutogen,
      });
    }
  };
  useEffect(() => {
    if (isregisterSuccess) {
      toast.success("Success!A password has been sent to your Email");
    }
  }, [isregisterSuccess]);

  useEffect(() => {
    if (isregisterError) {
      toast.error(registerError.data.message);
    }
  }, [isregisterError]);

  const roles = [
    {
      value: "Waitress",
      label: "Waitress",
    },
    {
      value: "Admin",
      label: "Admin",
    },
    {
      value: "Waiter",
      label: "Waiter",
    },
    {
      value: "Bartender",
      label: "Bartender",
    },
    {
      value: "Chef",
      label: "Chef",
    },
  ];

  return (
    <Box sx={{ width: "100%" }}>
      <Typography fontSize="1.5rem" fontWeight="bold">
        Your Restaurant Partner
      </Typography>
      <Typography color="textSecondary" fontSize="1rem">
        Create your account and manage your restaurant effectively
      </Typography>

      <Formik
        initialValues={initialState}
        onSubmit={handleUserRegistration}
        validationSchema={validationSchema}
      >
        {(props) => (
          <Form>
            {/* <Stack
              direction="row"
              spacing={2}
              marginTop="10px"
              alignItems="center"
            >
              <Field
                as={TextField}
                variant="outlined"
                margin="dense"
                fullWidth
                name="firstName"
                error={props.errors.firstName}
                helperText={<ErrorMessage name="firstName" />}
                className={classes.loginInput}
                placeholder="First Name"
              />
              <Field
                as={TextField}
                variant="outlined"
                margin="dense"
                fullWidth
                name="lastName"
                error={props.errors.lastName}
                helperText={<ErrorMessage name="lastName" />}
                className={classes.loginInput}
                placeholder="Last Name"
              />
            </Stack>
            <Stack
              direction="row"
              spacing={2}
              width="100%"
              marginTop="10px"
              alignItems="center"
            >
              <Field
                as={TextField}
                variant="outlined"
                margin="dense"
                fullWidth
                name="email"
                error={props.errors.email}
                helperText={<ErrorMessage name="email" />}
                className={classes.loginInput}
                placeholder="Email"
              />
              <Field
                as={TextField}
                width="50%"
                fullWidth
                id="outlined-select-currency-native"
                // className={classes.loginInput}

                variant="filled"
                select
                name="role"
                error={props.errors.role}
                helperText={<ErrorMessage name="role" />}
                inputProps={{
                  sx: {
                    backgroundColor: "#F5F4F2",
                    "&::placeholder": {
                      color: "#c9c9c9",
                      fontWeight: "bold",
                      opacity: 1, // otherwise firefox shows a lighter color
                    },
                  },
                }}
                placeholder="Role"
                InputProps={{
                  disableUnderline: true,
                }}
                defaultValue="Role"
                SelectProps={{
                  native: true,
                }}
              >
                {roles.map((option) => (
                  <option
                    key={option.value}
                    value={option.value}
                    sx={{ fontWeight: "bold" }}
                  >
                    {option.label}
                  </option>
                ))}
              </Field>
            </Stack>
            <FormControlLabel
              label="Autogenerate Password"
              control={
                <Field
                  as={Switch}
                  name="isAutogen"
                  checked={toggle}
                  onChange={handleToggle}
                />
              }
            />
            {!toggle ? (
              <Field
                as={TextField}
                variant="outlined"
                margin="dense"
                fullWidth
                id="newPass"
                type={showPassword ? "text" : "password"}
                name="password"
                error={props.errors.password}
                helperText={<ErrorMessage name="password" />}
                className={classes.loginInput}
                placeholder="Password"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            ) : (
              <Box></Box>
            )} */}
            <Field
              as={TextField}
              variant="outlined"
              margin="dense"
              fullWidth
              id="hotelName"
              error={props.errors.hotelName}
              name="hotelName"
              helperText={<ErrorMessage name="hotelName" />}
              className={classes.loginInput}
              placeholder="hotelName"
            />
            <Field
              as={TextField}
              variant="outlined"
              margin="dense"
              fullWidth
              id="adminEmail"
              error={props.errors.adminEmail}
              name="adminEmail"
              helperText={<ErrorMessage name="adminEmail" />}
              className={classes.loginInput}
              placeholder="adminEmail"
            />
            <SmartPOSContainedButton
              color="primary"
              loading={isregisterLoading}
              loadingIndicator="Loading…"
              disableRipple
              disableElevation
              type="submit"
              className={classes.loginBtn}
            >
              <Typography fontWeight="bold">Create Account</Typography>
            </SmartPOSContainedButton>
          </Form>
        )}
      </Formik>
      <Box className={classes.forgotPassBox} textAlign="center">
        <Link
          href="#"
          sx={{ color: "#1976d2", textDecoration: "none" }}
          variant="body2"
          onClick={() => {
            navigate("/resetpasstoken");
          }}
        >
          Reset Password
        </Link>
      </Box>
      <Box marginY={2} textAlign="center">
        <Typography variant="body2" color="textSecondary">
          Already have an account?
          <Link
            sx={{
              color: "#1976d2",
              textDecoration: "none",
              cursor: "pointer",
            }}
            variant="body2"
            onClick={() => {
              navigate("/");
            }}
          >
            Sign in
          </Link>
        </Typography>
      </Box>
    </Box>
  );
};

export default RegisterForm;
