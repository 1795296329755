import React, { useEffect, useState } from "react";

import {
  Box,
  TextField,
  Typography,
  IconButton,
  InputAdornment,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";
import SmartPOSContainedButton from "./components/Buttons/SmartPOSContainedButton";
import { useNavigate } from "react-router-dom/dist/index.js";
import { loginPageStyles } from "./assets/Jss/LoginpageStyles";
import { useResetInitialPasswordMutation } from "../../store/apiSlice.js";
const ResetPassword = () => {
  const { classes } = loginPageStyles();
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const navigate = useNavigate()
  const initialState = {
    currentPassword: "",
    password: "",
    confirmPassword: "",
    email: "",
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  //validation
  const validationSchema = Yup.object().shape({
    currentPassword: Yup.string()
      .required("Required")
      .min(8, "Password must have at least 8 characters"),
    password: Yup.string()
      .required("Required")
      .min(8, "Password must have at least 8 characters"),
    confirmPassword: Yup.string()
      .required("Required")
      .oneOf([Yup.ref("password")], "Passwords do not match")
      .min(8, "Password must have at least 8 characters"),
    email: Yup.string().email("please enter valid email").required("Required"),
  });
  //reset password
  const [
    resetPassword,
    {
      isLoading: isresetPasswordLoading,
      isSuccess: isresetPasswordSuccess,
      isError: isresetPasswordError,
      error: resetPasswordError,
    },
  ] = useResetInitialPasswordMutation();

  const handleResetPassword = async (values, props) => {
    if (values) {
      const currentPassword = values.currentPassword;
      const password = values.password;
      const confirmPassword = values.confirmPassword;
      const email = values.email;
      await resetPassword({
        currentPassword,
        password,
        confirmPassword,
        email,
      });
    }
  };
  useEffect(()=>{
    if (isresetPasswordSuccess) {
      navigate("/")
      toast.success("password reset successfully");
    }
  },[isresetPasswordSuccess])
  useEffect(()=>{
    if (isresetPasswordError) {
      toast.error(resetPasswordError.data.message);
    }
  },[isresetPasswordError])
  
  return (
    <Box sx={{ width: "100%" }}>
      <Typography fontSize="1.5rem" fontWeight="bold">
        Reset Initial Password
      </Typography>
      <Typography color="textSecondary" fontSize="1rem">
        Please reset your Initial Password
      </Typography>
      <Formik
        initialValues={initialState}
        onSubmit={handleResetPassword}
        validationSchema={validationSchema}
      >
        {(props) => (
          <Form>
            <Field
              as={TextField}
              variant="outlined"
              margin="dense"
              error={props.errors.currentPassword}
              helperText={<ErrorMessage name="currentPassword" />}
              name="currentPassword"
              fullWidth
              id="resetPass"
              type={showPassword ? "text" : "password"}
              className={classes.loginInput}
              placeholder="CurrentPassword"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Field
              as={TextField}
              variant="outlined"
              margin="dense"
              error={props.errors.password}
              helperText={<ErrorMessage name="password" />}
              name="password"
              fullWidth
              id="resetPass"
              type={showPassword ? "text" : "password"}
              className={classes.loginInput}
              placeholder="Password"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Field
              as={TextField}
              variant="outlined"
              margin="dense"
              error={props.errors.confirmPassword}
              helperText={<ErrorMessage name="confirmPassword" />}
              name="confirmPassword"
              fullWidth
              type={showPassword ? "text" : "password"}
              className={classes.loginInput}
              placeholder="Confirm Password"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Field
              as={TextField}
              variant="outlined"
              margin="dense"
              fullWidth
              name="email"
              error={props.errors.email}
              helperText={<ErrorMessage name="email" />}
              className={classes.loginInput}
              placeholder="Email"
            />
            <SmartPOSContainedButton
              color="primary"
              loading={isresetPasswordLoading}
              loadingIndicator="Loading…"
              disableRipple
              disableElevation
              className={classes.loginBtn}
              type="submit"
            >
              <Typography fontWeight="bold">Submit</Typography>
            </SmartPOSContainedButton>
          </Form>
        )}
      </Formik>
      {/* <Box marginY={2} textAlign="center">
        <Typography variant="body2" color="textSecondary">
          Don't have an account?
          <Link
            sx={{
              color: "#1976d2",
              textDecoration: "none",
              cursor: "pointer",
            }}
            variant="body2"
            onClick={() => {navigate("/register")}}
          >
            Go to Registration
          </Link>
        </Typography>
      </Box>
      <Box marginY={2} textAlign="center">
        <Typography variant="body2" color="textSecondary">
          Already have an account?
          <Link
            sx={{
              color: "#1976d2",
              textDecoration: "none",
              cursor: "pointer",
            }}
            variant="body2"
            onClick={() => {navigate("/")}}
          >
            Sign in
          </Link>
        </Typography>
      </Box> */}
    </Box>
  );
};

export default ResetPassword;
