import React from "react";
import IncomeChart from "./IncomeChart";
import Wrapper from "./UI/Wrapper";
import ReusableCardHeader from "./UI/ReusableCardHeader";
import { List } from "@mui/material";

function IncomeHeader() {
  const title = "Total Income";

  return <ReusableCardHeader title={title} />;
}
function Income() {
  return (
    <Wrapper>
      <List sx={{ width: "90%", height: '90%'}}>
        <IncomeHeader />
        <IncomeChart />
      </List>
    </Wrapper>
  );
}

export default Income;
