import React from "react";
import { Box } from "@mui/material";
import theme from "../../../../Themes/Default";

function Wrapper({ children }) {
  return (
    <Box
      flex={1}
      sx={{
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "start",
        alignItems: "center",
        borderRadius: "40px",
        border: "0.125px solid",
        borderColor:theme.palette.background.dark,
        overflow: "hidden",
        boxShadow: "-2px 2px 14px -1px rgba(114, 114, 114, 0.15)",
        backgroundColor: "white",
      }}
    >
      {children}
    </Box>
  );
}
export default Wrapper;
