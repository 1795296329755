import { Avatar, Box, Button, Typography, Stack } from "@mui/material";
import React from "react";
import { NavLink } from "react-router-dom";
import ProfilePic from "../images/user1.jpg";
import theme from "../Themes/Default";
import { useDispatch } from "react-redux";
import { add } from "../store/navigationSlice";
import { useNavigate } from "react-router-dom";
import { useUserProfileQuery } from "../store/apiSlice";
import { Puff } from "react-loader-spinner";
import placeholderImage from "../pages/useradmin/assets/placeholderImage.png";
function ProfileCard() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userInfo = localStorage.getItem("user");
  const userIDInformation = JSON.parse(userInfo);
  const { data, isLoading, isError, isSuccess } = useUserProfileQuery(
    userIDInformation.userIdentification
  );
  if (isLoading) {
    return (
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Puff
          visible={true}
          height="80"
          width="80"
          color="#FFCA40"
          ariaLabel="puff-loading"
          wrapperStyle={{}}
          wrapperClass=""
        />
      </Box>
    );
  }
  if (isError) {
    return "Something went wrong";
  }
  if (isSuccess) {
    return (
      <Box
        sx={{
          borderRadius: "10px",
          minHeight: "max-content",
          width: "100%",
          boxShadow: "0px 42px 86px -50px rgba(211, 211, 211, 0.75)",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "10px",
          paddingY: "10px",
        }}
      >
        <Avatar
          sx={{
            width: 90,
            height: 90,
          }}
        >
          {data.user.profileImageUrl && (
            <img
              style={{ width: "100%", height: "100%" }}
              src={data.user.profileImageUrl}
              alt={placeholderImage}
            />
          )}
        </Avatar>
        <Stack direction="row" spacing={2}>
          <Typography
            variant="h6"
            sx={{
              fontWeight: "bold",
              fontSize: "1rem",
            }}
          >
            {data.user.firstName}
          </Typography>
          <Typography
            variant="h6"
            sx={{
              fontWeight: "bold",
              fontSize: "1rem",
            }}
          >
            {data.user.lastName}
          </Typography>
        </Stack>

        <Typography
          variant="caption"
          sx={{
            fontWeight: "thin",
            fontSize: "0.75rem",
            color: theme.palette.text.light,
          }}
        >
          {data.user.role} <span>4h 6m</span>
        </Typography>
        <NavLink to={`/user-profile/${userIDInformation.userIdentification}`}>
          <Button
            // onClick={userProfileHandler}
            onClick={() =>
              dispatch(
                add({
                  parent: { name: "settings", path: "/settings" },
                  child: { name: "profile", path: "/settings/userProfile" },
                })
              )
            }
            sx={{
              background: theme.palette.background.dark,
              borderRadius: "10px",
              color: "black",
              textTransform: "capitalize",
              fontWeight: "bold",
              fontSize: "0.75rem",
            }}
          >
            Open Profile
          </Button>
        </NavLink>
      </Box>
    );
  }
}

export default ProfileCard;
