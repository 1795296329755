import React, { useEffect, useState } from "react";
import { AdminLayout } from "../../layout/admin/AdminLayout";
import { CustomButton } from "../../components";
import {
  MenuItem,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { Add } from "@mui/icons-material";
import PropTypes from "prop-types";
import theme from "../../Themes/Default";
import { CustomTabPanel } from "./order-tab-panel/index";
import { useOrdersListQuery } from "../../store/apiSlice";
import { OrderCard } from "./order-card";
import { useNavigate } from "react-router";
import { routesEnum } from "../../utils/routes";

const ButtonComponent = () => {
  const navigate = useNavigate();
  return (
    <Stack direction="row" gap={2}>
      <CustomButton variant="text" color="error">
        Export Orders
      </CustomButton>
      <CustomButton
        variant="contained"
        onClick={() => navigate(routesEnum.FOOD_AND_DRINKS)}
      >
        <Add /> New Order
      </CustomButton>
    </Stack>
  );
};

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `order-tab-${index}`,
    "aria-controls": `order-tabpanel-${index}`,
  };
}

function Orders() {
  const hotelId = JSON.parse(localStorage.getItem("user")).hotelId;
  const { data, isSuccess, isLoading } = useOrdersListQuery({
    hotelId: hotelId,
  });
  const [date, setDate] = useState("today");
  const [status, setStatus] = useState("active");
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {}, [date, status, value]);
  if (isLoading) {
    return (
      <AdminLayout title="Orders" ButtonComponent={ButtonComponent}>
        <Stack>Loading...</Stack>
      </AdminLayout>
    );
  }
  if (isSuccess) {
    const orders = data.filter((order) => {
      if (date === "today") {
        const date =
          new Date(order.time).toDateString() === new Date().toDateString();
        return order.status === status && date;
      }
      if (date === "yesterday") {
        const today = new Date();
        const yesterday = today.setDate(today.getDate() - 1);
        const date =
          new Date(order.time).toDateString() ===
          new Date(yesterday).toDateString();
        return order.status === status && date;
      }
      return order.status === status;
    });

    return (
      <AdminLayout title="Orders" ButtonComponent={ButtonComponent}>
        <Stack>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            sx={{
              borderBottom: "1px solid",
              borderColor: theme.palette.primary.main,
            }}
          >
            <Tab label="All" {...a11yProps(0)} />
            <Tab label="Dine In" {...a11yProps(1)} />
            <Tab label="Take Away" {...a11yProps(2)} />
          </Tabs>
        </Stack>
        <Stack direction="row" justifyContent="space-between">
          <TextField
            select
            value={date}
            onChange={(e) => setDate(e.target.value)}
          >
            <MenuItem value="today">Today</MenuItem>
            <MenuItem value="yesterday">Yesterday</MenuItem>
          </TextField>
          <TextField
            select
            value={status}
            onChange={(e) => setStatus(e.target.value)}
          >
            <MenuItem value="active">Active</MenuItem>
            <MenuItem value="done">Done</MenuItem>
          </TextField>
        </Stack>
        {[0, 1, 2].map((item) => (
          <CustomTabPanel value={value} index={item}>
            {orders.length === 0 && (
              <Stack
                justifyContent="center"
                alignItems="center"
                sx={{ width: "100%", height: "100%" }}
              >
                <Typography sx={{ fontWeight: "bold" }} variant="h1">
                  No Orders available
                </Typography>
              </Stack>
            )}
            {orders.map((order) => {
              if (item === 0) {
                return <OrderCard order={order} />;
              } else if (item === 1 && order.orderType === "dine in") {
                return <OrderCard order={order} />;
              } else if (item === 2 && order.orderType === "take away") {
                return <OrderCard order={order} />;
              }
              return <></>;
            })}
          </CustomTabPanel>
        ))}
      </AdminLayout>
    );
  }
}

export default Orders;
