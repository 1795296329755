import React from "react";
import ProductsCategories from "./ProductsCategories";
import { Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router";
import { CustomButton } from "../../components";
import { AdminLayout } from "../../layout";
import { routesEnum } from "../../utils/routes";

const ButtonComponent = () => {
  const navigate = useNavigate();
  return (
    <CustomButton
      variant="contained"
      onClick={() => navigate(routesEnum.NEW_CATEGORY)}
    >
      <AddIcon /> <Typography>New Category</Typography>
    </CustomButton>
  );
};

const Category = () => {
  return (
    <AdminLayout ButtonComponent={ButtonComponent} title={"Product Categories"}>
      <ProductsCategories />
    </AdminLayout>
  );
};

export default Category;
