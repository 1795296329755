import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  productName: "",
  category: "",
  price: "",
  productType: "",
};

const newSubCategorySlice = createSlice({
  name: "newSubCategry",
  initialState: initialState,
  reducers: {
    addProductName: (state, action) => {
      state.productName = action.payload;
    },
    addCategoryName: (state, action) => {
      state.category = action.payload;
    },
    addSubCategoryPrice: (state, action) => {
      state.price = action.payload;
    },
    addProductType: (state, action) => {
      state.productType = action.payload;
    },
  },
});

export const {
  addCategoryName,
  addProductName,
  addProductType,
  addSubCategoryPrice,
} = newSubCategorySlice.actions;

const newSubCategoryReducer = newSubCategorySlice.reducer;
export default newSubCategoryReducer;
