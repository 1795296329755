import { createSlice } from "@reduxjs/toolkit";

const selectedUsersSlice = createSlice({
  name: "selectedUsers",
  initialState: {
    users: 0,
  },
  reducers: {
    updateUsers: (state, action) => {
      state.users = action.payload;
    },
  },
});
export const { updateUsers } = selectedUsersSlice.actions;
export default selectedUsersSlice.reducer;
