import React, { useState } from "react";
import { Box, TextField, MenuItem, useTheme } from "@mui/material";
import { CustomButton } from "../../../components";
import { UploadMediaFile } from "../../../features";
import { useDispatch } from "react-redux";
import { updateFormData } from "../../../store/admin/products/NewProductFormData";
import { useAddIngredientMutation } from "../../../store/apiSlice";
import toast from "react-hot-toast";
import { useNavigate } from "react-router";
import { routesEnum } from "../../../utils/routes";
import { NavigationRounded } from "@mui/icons-material";

const statusList = [
  {
    value: "In Stock",
    label: "In Stock",
  },
  {
    value: "Out of Stock",
    label: "Out of Stock",
  },
];

const Form = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [addIngredient] = useAddIngredientMutation();

  const [title, setTitle] = useState("");
  const [status, setStatus] = useState("");
  const [quantity, setQuantity] = useState("");
  const [price, setPrice] = useState(null);

  const handleTitleChange = (e) => {
    dispatch(updateFormData({ name: "title", value: e.target.value }));
    setTitle(e.target.value);
  };
  const handleStatusChange = (e) => {
    dispatch(updateFormData({ name: "status", value: e.target.value }));
    setStatus(e.target.value);
  };

  const handleQuantityChange = (e) => {
    dispatch(updateFormData({ name: "quantity", value: e.target.value }));
    setQuantity(e.target.value);
  };
  const handlePriceChange = (e) => {
    dispatch(updateFormData({ name: "price", value: e.target.value }));

    setPrice(e.target.value);
  };

  const hotelId = JSON.parse(localStorage.getItem("user")).hotelId;

  const handleSubmit = async (e) => {
    e.preventDefault();
    toast.loading("Uploading....");
    const body = {
      name: title,
      image: "string",
      price: price,
      updatedOn: new Date().toISOString(),
      quantity: quantity,
      status: status,
      hotelId: hotelId,
    };

    try {
      const res = await addIngredient({ body: body });

      if (res.data.status.toLowerCase() === "success") {
        toast.dismiss();
        toast.success("Ingredient Added successfully.");
        navigate(routesEnum.ALL_INGREDIENTS);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleImageUpload = (e) => {
    console.log("Uploaded");
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{
        width: "90%",
        border: "2px solid",
        borderColor: theme.palette.background.dark,
        borderRadius: "10px",
        padding: "20px",
        cursor: "pointer",
      }}
    >
      <TextField
        label="Name"
        id="prodName"
        value={title}
        onChange={handleTitleChange}
        fullWidth
        margin="normal"
      />
      <Box display="flex" gap={2} marginBottom={2}>
        <TextField
          fullWidth
          label="Price"
          value={price}
          onChange={handlePriceChange}
          type="number"
        />
        <TextField
          label="Quantity"
          value={quantity}
          onChange={handleQuantityChange}
          type="number"
        />
      </Box>
      <TextField
        value={status}
        onChange={handleStatusChange}
        fullWidth
        select
        label="Status"
      >
        {statusList.map((item, index) => (
          <MenuItem key={index} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </TextField>
      <Box display="flex" justifyContent="end" gap={4} padding="1rem">
        <CustomButton
          variant="outlined"
          onClick={() => navigate(routesEnum.ALL_INGREDIENTS)}
        >
          Cancel
        </CustomButton>
        <CustomButton variant="contained" type="submit">
          Submit
        </CustomButton>
      </Box>
    </Box>
  );
};

export default Form;
