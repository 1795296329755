import React from "react";
import moment from "moment";
import { Stack, Typography } from "@mui/material";

export function AdminHeader({ title, ButtonComponent }) {
  return (
    <Stack
      spacing={1}
      sx={{
        backgroundColor: "white",
        padding: "20px 40px",
        borderRadius: "10px",
      }}
    >
      <Typography variant="h4">{moment().format("Do MMMM, YYYY")}</Typography>
      <Stack direction={"row"} justifyContent={"space-between"}>
        <Typography variant="h1" sx={{ textTransform: "capitalize" }}>
          {title}
        </Typography>
        {ButtonComponent && <ButtonComponent />}
      </Stack>
    </Stack>
  );
}
