import React, { useState } from "react";
import {
  useReactTable,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  getFilteredRowModel,
} from "@tanstack/react-table";
import {
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  InputBase,
  Paper,
  useTheme,
} from "@mui/material";
import { CustomButton } from "../../components";
import SearchIcon from "@mui/icons-material/Search";
import DownloadIcon from "@mui/icons-material/Download";
import { FilterList, ImportExport } from "@mui/icons-material";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import LastPageIcon from "@mui/icons-material/LastPage";
import SkipPreviousIcon from "@mui/icons-material/SkipPrevious";
import SkipNextIcon from "@mui/icons-material/SkipNext";
import * as XLSX from "xlsx/xlsx.mjs";

export function DataTable({
  data,
  columns,
  exportFileName,
  defaultPageSize = 10,
}) {
  const theme = useTheme();
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: defaultPageSize,
  });
  const [sorting, setSorting] = useState([]);
  const [filtering, setFiltering] = useState("");
  const [rowSelection, setRowSelection] = useState([]);

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setPagination,
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    state: {
      pagination,
      sorting,
      globalFilter: filtering,
      rowSelection,
    },
    onSortingChange: setSorting,
    onGlobalFilterChange: setFiltering,
    onRowSelectionChange: setRowSelection,
    enableRowSelection: true,
  });

  const exportToExcel = () => {
    const datas = data?.length ? data : [];
    const worksheet = XLSX.utils.json_to_sheet(datas);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, `${exportFileName}.xlsx`);
  };

  return (
    <Paper sx={{ borderRadius: "10px" }}>
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{ padding: "10px" }}
      >
        <CustomButton variant="contained" onClick={exportToExcel}>
          <Typography>Export</Typography> <DownloadIcon />
        </CustomButton>
        <CustomButton variant="outlined">
          <Typography>Filter</Typography> <FilterList />
        </CustomButton>
        <CustomButton variant="outlined">
          <Typography>Sort</Typography> <ImportExport />
        </CustomButton>
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={1}
          sx={{
            border: "1px solid",
            borderColor: theme.palette.primary.main,
            borderRadius: "10px",
            padding: "5px 10px",
            width: "300px",
            color: theme.palette.primary.main,
          }}
        >
          <SearchIcon />
          <InputBase
            sx={{ color: "inherit" }}
            placeholder="Search product"
            value={filtering}
            inputProps={{ "aria-label": "search" }}
            onChange={(e) => setFiltering(e.target.value)}
          />
        </Stack>
      </Stack>
      <TableContainer sx={{ maxHeight: 400 }}>
        <Table stickyHeader>
          <TableHead color="primary">
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <TableCell key={header.id}>
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody>
            {table.getRowModel().rows.map((row) => (
              <TableRow
                sx={{
                  cursor: "pointer",
                  "&:hover": {
                    backgroundColor: theme.palette.accent.light,
                  },
                }}
                key={row.id}
              >
                {row.getVisibleCells().map((cell) => (
                  <TableCell key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Stack
        spacing={2}
        direction="row"
        justifyContent="space-between"
        sx={{ padding: "20px" }}
      >
        <Typography>Total Rows : {table.getRowCount()}</Typography>
        <Typography>
          {table.getSelectedRowModel().rows.length} rows selected.
        </Typography>
        <Typography></Typography>
        <Stack direction="row">
          <CustomButton
            variant="text"
            disabled={!table.getCanPreviousPage()}
            onClick={() => table.setPageIndex(0)}
          >
            <FirstPageIcon />
          </CustomButton>
          <CustomButton
            variant="text"
            disabled={!table.getCanPreviousPage()}
            onClick={() => table.previousPage()}
          >
            <SkipPreviousIcon />
          </CustomButton>
          <CustomButton
            variant="text"
            disabled={!table.getCanNextPage()}
            onClick={() => table.nextPage()}
          >
            <SkipNextIcon />
          </CustomButton>
          <CustomButton
            variant="text"
            disabled={!table.getCanNextPage()}
            onClick={() => table.setPageIndex(table.getPageCount() - 1)}
          >
            <LastPageIcon />
          </CustomButton>
        </Stack>
      </Stack>
    </Paper>
  );
}
