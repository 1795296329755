import { Stack } from "@mui/material";
import React from "react";
import Dishes from "./Dishes";
import Employees from "./Employees";

function HighlightsSection() {
  return (
    <Stack
      direction="row"
      sx={{ height: "45%", width: "100%" }}
      component="section"
      gap="20px"
    >
      <Dishes />
      <Employees />
    </Stack>
  );
}

export default HighlightsSection;
