import { Stack } from "@mui/material";

export const CustomTabPanel = (props) => {
  const { children, value, index } = props;

  return (
    <Stack
      direction="row"
      gap={3}
      role="tabpanel"
      id={`order-tabpanel-${index}`}
      sx={{ overflowY: "scroll", flexWrap: "wrap" }}
    >
      {value === index && <>{children}</>}
    </Stack>
  );
};
