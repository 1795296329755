import { Box, MenuItem, Stack, TextField, Typography } from "@mui/material";
import { CustomButton, CustomModal } from "../../../components";
import {
  useAddProductIngredientMutation,
  useIngredientListQuery,
} from "../../../store/apiSlice";
import { useState } from "react";
import toast from "react-hot-toast";

export const AddProductIngredient = ({
  open,
  handleClose,
  productId,
  hotelId,
}) => {
  const { data, isLoading, isSuccess } = useIngredientListQuery({
    hotelId: hotelId,
  });
  const [addProductIngredient] = useAddProductIngredientMutation();
  const [ingredient, setIngredient] = useState("");
  const [quantity, setQuantity] = useState(0);

  if (isLoading) {
    return (
      <CustomModal handleClose={handleClose} open={open}>
        <Typography variant="h4">Loading......</Typography>
      </CustomModal>
    );
  }
  if (isSuccess) {
    const ingredients = data.message.$values;
    const handleFormSubmit = async (e) => {
      e.preventDefault();
      const ingredientId = ingredients.filter(
        (item) => item.name === ingredient
      )[0].id;
      const body = {
        productId: productId,
        ingredientId: ingredientId,
        quantity: +quantity,
      };

      try {
        const result = await addProductIngredient({ body: body });
        toast.loading("Adding product Ingredient...");
        if (result.data.status.toLowerCase() === "success") {
          toast.dismiss();
          toast.success("Product Ingredient added successfully.");
          handleClose();
        }
      } catch (error) {
        console.log(error);
      }
    };

    return (
      <CustomModal handleClose={handleClose} open={open}>
        <Box
          component={"form"}
          onSubmit={handleFormSubmit}
          sx={{
            minWidth: "300px",
            minHeight: "200px",
            display: "flex",
            flexDirection: "column",
            gap: "2rem",
            padding: "1rem",
          }}
        >
          <Typography variant="h3" sx={{ fontWeight: "bold" }}>
            Select Ingredient to Add.
          </Typography>
          <TextField
            id="ingredient"
            value={ingredient}
            onChange={(e) => setIngredient(e.target.value)}
            select
            fullWidth
            label="Ingredient"
          >
            {ingredients.map((item) => (
              <MenuItem key={item.id} value={item.name}>
                {item.name}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            id="quantity"
            label="Quantity"
            value={quantity}
            onChange={(e) => setQuantity(e.target.value)}
            type="number"
          />
          <Stack direction={"row"} gap={4}>
            <CustomButton
              variant="outlined"
              color="primary"
              onClick={handleClose}
            >
              <Typography variant="h3">Cancel</Typography>
            </CustomButton>
            <CustomButton variant="contained" color="success" type="submit">
              <Typography variant="h3">Add</Typography>
            </CustomButton>
          </Stack>
        </Box>
      </CustomModal>
    );
  }
};
