import React from "react";
import { AdminLayout } from "../../../layout";
import { useAddCategoryMutation } from "../../../store/apiSlice";
import toast from "react-hot-toast";
import { CategoryForm } from "../shared/CategoryForm";
import { useNavigate } from "react-router";
import { routesEnum } from "../../../utils/routes";

const NewCategory = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const [addCategory] = useAddCategoryMutation();
  const navigate = useNavigate();

  const handleFormSubmit = async ({ name, updatedDescription }) => {
    const body = {
      name: name,
      description: updatedDescription,
      hotelId: user.hotelId,
    };
    try {
      const res = await addCategory({ body: body });
      toast.loading("Creating category....");
      if (res.data.status.toLowerCase() === "success") {
        toast.dismiss();
        toast.success("Category created.");
        navigate(routesEnum.ALL_CATEGORIES);
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <AdminLayout title="Product Categories">
      <CategoryForm
        handleFormSubmit={handleFormSubmit}
        name={""}
        description={""}
      />
    </AdminLayout>
  );
};

export default NewCategory;
