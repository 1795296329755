import { Box, MenuItem, Stack, TextField } from "@mui/material";
import { CustomButton, CustomModal } from "../../../components";
import React from "react";
import { useUpdateIngredientMutation } from "../../../store/apiSlice";
import toast from "react-hot-toast";

export const EditIngredientModal = ({ handleClose, open, ingredient }) => {
  const [name, setName] = React.useState(ingredient.name);
  const [price, setPrice] = React.useState(ingredient.price);
  const [quantity, setQuantity] = React.useState(ingredient.quantity);
  const [status, setStatus] = React.useState(ingredient.status);
  const [updateIngredient] = useUpdateIngredientMutation();

  const statusList = [
    {
      value: "In Stock",
      label: "In Stock",
    },
    {
      value: "Out of Stock",
      label: "Out of Stock",
    },
  ];
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      const body = {
        name: name,
        status: status,
        price: price,
        quantity: quantity,
      };
      toast.loading("Updating Ingredient...");
      const response = await updateIngredient({
        id: ingredient.id,
        body: body,
      });
      if (response.data.status.toLowerCase() === "success") {
        toast.dismiss();
        toast.success("Update Successfull!");
        handleClose();
      }
    } catch (error) {
      toast.dismiss();
      toast.error("Update Successfull!");
      handleClose();
    }
  };

  return (
    <CustomModal handleClose={handleClose} open={open}>
      <Box
        onSubmit={handleFormSubmit}
        enctype="multipart/form-data"
        component={"form"}
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          padding: "1rem",
        }}
      >
        <Stack direction={"row"} gap={2}>
          <TextField
            id="name"
            value={name}
            label="Name"
            variant="standard"
            onChange={(e) => setName(e.target.value)}
          />

          <TextField
            id="status"
            select
            label="Status"
            value={status}
            helperText="Please select the product status"
            variant="standard"
            onChange={(e) => setStatus(e.target.value)}
          >
            {statusList.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Stack>
        <Stack direction={"row"} gap={2}>
          <TextField
            id="unit-price"
            value={price}
            label="Unit Price"
            variant="standard"
            onChange={(e) => setPrice(e.target.value)}
          />
          <TextField
            id="quantity"
            value={quantity}
            label="Amount in Stock"
            variant="standard"
            onChange={(e) => setQuantity(e.target.value)}
          />
        </Stack>

        <CustomButton color="success" variant="contained" type={"submit"}>
          Submit
        </CustomButton>
      </Box>
    </CustomModal>
  );
};
