import React from "react";
import { Box, Typography } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import Wrapper from "./UI/Wrapper";

function ReusableStatsCard({
  icon: IconComponent,
  title,
  percentage,
  value,
  progressValue,
  progressColor,
  color,
  iconColor,
}) {
  return (
    <Wrapper>
      <Box
        sx={{
          width:"100%",
          height:"100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          padding: "20px",
          gap: "20px"
        }}
      >
        <Box
          sx={{
            display: "flex",
            width: "100%",
            alignItems: "start",
            justifyContent: "center",
            gap: "20px",
            paddingX: '20px'
          }}
        >
          {IconComponent && (
            <IconComponent
              sx={{
                fontSize: 32,
                backgroundColor: iconColor,
                padding: "5px",
                borderRadius: "5px",
                color: "white",
              }}
            />
          )}
          <Box sx={{ width: '100%' }}>
            <Typography
              variant="h6"
              sx={{
                fontSize: '1.15rem',
              }}
              fontWeight="bold"
            >
              {title}
            </Typography>
            <Typography
              variant="span"
              sx={{
                color: color,
                fontSize: '1rem',
              }}
            >
              {percentage > 0? `+${percentage}`: percentage}%
            </Typography>
          </Box>
        </Box>
        <Box sx={{width: "75%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems:"center", gap:"20px"}}>
        <Typography variant="h3" sx={{
          fontSize: "2rem",
        }} fontWeight="bold">
          {value}
        </Typography>
        <LinearProgress
          color={progressColor}
          variant="determinate"
          value={progressValue}
          sx={{ width: "100%" }}
        />
        </Box>
      </Box>
    </Wrapper>
  );
}

export default ReusableStatsCard;
