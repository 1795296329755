import React from "react";
import Wrapper from "./UI/Wrapper";
import {
  List,
  ListItem,
  ListItemText,
  Typography,
  Box,
  ListItemAvatar,
  Avatar,
} from "@mui/material";
import ReusableCardHeader from "./UI/ReusableCardHeader";
import theme from "../../../Themes/Default";
import { currency } from "../../../utils/currency";

const EmployeesList = [
  {
    id: 1,
    role: "waiter",
    name: "sakura haruno",
    earnings: 23700,
    image: "https://randomuser.me/api/portraits/women/50.jpg",
  },
  {
    id: 2,
    role: "waiter",
    name: "naruto uzumaki",
    earnings: 8900,
    image: "https://randomuser.me/api/portraits/men/5.jpg",
  },
  {
    id: 3,
    role: "chef",
    name: "hinata hyuga",
    earnings: 21389,
    image: "https://randomuser.me/api/portraits/women/62.jpg",
  },
  {
    id: 4,
    role: "chef",
    name: "sasuke uchiha",
    earnings: 10000,
    image: "https://randomuser.me/api/portraits/men/54.jpg",
  },
];

function Employees() {
  return (
    <Wrapper>
      <List sx={{ width: "90%" }}>
        <ReusableCardHeader title={"Top Employees"} />
        <ListItem
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <ListItemText
            primary={
              <Typography
                variant="span"
                sx={{
                  fontWeight: "bold",
                  fontSize: 16,
                  color: theme.palette.text.light,
                }}
              >
                Employee
              </Typography>
            }
          />
          <Box>
            <ListItemText
              primary={
                <Typography
                  variant="span"
                  sx={{
                    fontWeight: "bold",
                    fontSize: 16,
                    color: theme.palette.text.light,
                  }}
                >
                  Earnings
                </Typography>
              }
            />
          </Box>
        </ListItem>
        {EmployeesList.map((row) => (
          <ListItem
            key={row.id}
            sx={{
              justifyContent: "space-between",
              display: "flex",
              alignItems: "center",
              paddingRight: "30px",
            }}
          >
            <Box sx={{ display: "flex" }}>
              <ListItemAvatar>
                <Avatar src={`${row.image}`}></Avatar>
              </ListItemAvatar>
              <Box
                sx={{
                  textTransform: "capitalize",
                  flex: 1,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Typography
                  variant="span"
                  sx={{
                    flex: 1,
                    fontWeight: "bold",
                    fontSize: 16,
                    color: theme.palette.text.dark,
                  }}
                >
                  {row.name}
                </Typography>
                <Typography
                  sx={{
                    width: "max-content",
                    paddingX: "5px",
                    borderRadius: "10px",
                    display: "inline-block",
                  }}
                  variant="caption"
                >
                  {row.role}
                </Typography>
              </Box>
            </Box>
            <Typography
              variant="span"
              sx={{
                fontWeight: "bold",
                fontSize: 16,
                color: theme.palette.text.dark,
                textAlign: "right",
              }}
            >
              {currency(row.earnings)}
            </Typography>
          </ListItem>
        ))}
      </List>
    </Wrapper>
  );
}

export default Employees;
