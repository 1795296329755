import { ImageList, ImageListItem } from "@mui/material";
import theme from "../../../Themes/Default";

const IngredientImageList = ({ imageUrl, height, width }) => (
  <ImageList
    sx={{
      width: width,
      height: height,
      borderRadius: "20px",
    }}
    cols={1}
  >
    <ImageListItem>
      <img
        src={imageUrl}
        style={{ height: height, width: width }}
        alt="product"
        loading="lazy"
      />
    </ImageListItem>
  </ImageList>
);

export default IngredientImageList;
