import { useLocation, useNavigate } from "react-router";
import {
  useCategoriesListQuery,
  useEditCategoryMutation,
} from "../../../store/apiSlice";
import { AdminLayout } from "../../../layout";
import { CategoryForm } from "../shared/CategoryForm";
import toast from "react-hot-toast";
import { routesEnum } from "../../../utils/routes";

export const EditCategory = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const categoryId = location.pathname.split("/")[2];
  const hotelId = JSON.parse(localStorage.getItem("user")).hotelId;
  const { data, isSuccess } = useCategoriesListQuery({
    hotelId: hotelId,
  });
  const [editCategory] = useEditCategoryMutation();

  if (isSuccess) {
    const category = data.$values.filter((item) => item.id === categoryId)[0];
    const handleFormSubmit = async ({ name, updatedDescription }) => {
      try {
        toast.loading("updating category...");
        const response = await editCategory({
          body: { name: name, description: updatedDescription },
          id: categoryId,
        });
        if (response.data.status.toLowerCase() === "success") {
          toast.dismiss();
          toast.success("Update sucessful!");
          navigate(routesEnum.ALL_CATEGORIES);
        }
      } catch (error) {
        toast.dismiss();
        toast.error("Unable to update category");
      }
    };
    return (
      <AdminLayout title={"Product Category"}>
        <CategoryForm
          handleFormSubmit={handleFormSubmit}
          name={category.name}
          description={category.description}
        />
      </AdminLayout>
    );
  }
};
