import { createSlice } from "@reduxjs/toolkit";

const selectedItemsSlice = createSlice({
  name: "selectedItems",
  initialState: {
    items: 0,
  },
  reducers: {
    updateItems: (state, action) => {
      state.items = action.payload;
    },
  },
});

export const { updateItems } = selectedItemsSlice.actions;

export default selectedItemsSlice.reducer;
