import React from "react";
import { Box, Button, Typography } from "@mui/material";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import theme from "../../Themes/Default";
import styled from "@emotion/styled";

const StyledLogo = styled(Box)({
  display: 'flex',
})

function Logo() {
  return (
    <StyledLogo >
      <Button
        variant="text"
        aria-label="menu"
        sx={{
          cursor:'text'
        }}
      >
        <BusinessCenterIcon sx={{
          fontSize: '1.5rem'
        }} color="secondary" />
      </Button>
      <Typography
        variant="h3"
        sx={{display: "flex", alignItems: "center" }}
        fontWeight="bold"
        fontSize='1.5rem'
      >
        Smart<span style={{color: theme.palette.secondary.main}}>POS</span>
      </Typography>
    </StyledLogo>
  );
}

export default Logo;
