import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  name: null,
  id: null,
  items: [],
};

const categorySlice = createSlice({
  name: "category",
  initialState,
  reducers: {
    setCategory: (state, action) => {
      state.name = action.payload;
    },
    setCategoryId: (state, action) => {
      state.id = action.payload;
    },
    setItems: (state, action) => {
      state.items = action.payload;
    },
    reset: (state) => {
      state.name = null;
    },
  },
});

export const { setCategory, setItems, reset, setCategoryId } = categorySlice.actions;

export default categorySlice.reducer;
