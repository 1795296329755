import { useLocation, useNavigate } from "react-router";
import { AdminLayout } from "../../../layout";
import { ProductsForm } from "../shared/ProductForm";
import {
  useProductsListQuery,
  useUpdateProductMutation,
  useUploadProductImageMutation,
} from "../../../store/apiSlice";
import { Typography } from "@mui/material";
import toast from "react-hot-toast";

export const EditProduct = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const productId = location.pathname.split("/")[2];
  const hotelId = JSON.parse(localStorage.getItem("user")).hotelId;
  const { data, isSuccess, isLoading, refetch } = useProductsListQuery({
    hotelId: hotelId,
  });

  const [updateProduct] = useUpdateProductMutation();
  const [uploadImage] = useUploadProductImageMutation();

  const handleFormSubmit = async (body) => {
    try {
      const result = await updateProduct({ body: body, id: productId });
      toast.loading("Updating product...");
      if (result.data.status.toLowerCase() === "success") {
        toast.dismiss();
        toast.success("Product Updated successfully");
        navigate(`/products/${productId}`);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleImageUpload = async (image) => {
    try {
      const formData = new FormData();
      formData.append("image", image);
      console.log(formData);
      //   const result = await uploadImage(formData);
      //   console.log(result);
    } catch (error) {
      console.error(error);
    }
  };
  if (isLoading) {
    return (
      <AdminLayout title={"Edit Product"}>
        <Typography variant="h3">Loading....</Typography>
      </AdminLayout>
    );
  }

  if (isSuccess) {
    const product = data.$values.filter((item) => item.id === productId)[0];

    return (
      <AdminLayout title={"Edit Product"}>
        <ProductsForm
          handleFormSubmit={handleFormSubmit}
          onUpload={handleImageUpload}
          data={product}
        />
      </AdminLayout>
    );
  }
};
