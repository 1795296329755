import React from "react";
import InventorySummary from "./InventorySummary";
import { AdminLayout } from "../../../layout";
import { ButtonComponent } from "./ButtonComponent";
import { useSelector, useDispatch } from "react-redux";
import { setClose } from "../../../store/admin/products/importProductsModal";
import IngredientsList from "./IngredientsList";
import ImportIngredientsModal from "../import-ingredients/ImportIngredientsModal";

export function IngredientsListHome() {
  const isOpen = useSelector((state) => state.importProductsModal.open);
  const dispatch = useDispatch();
  const handleClose = () => dispatch(setClose());

  return (
    <>
      <AdminLayout title="Ingredients" ButtonComponent={ButtonComponent}>
        <InventorySummary />
        <IngredientsList />
      </AdminLayout>
      {isOpen && (
        <ImportIngredientsModal handleClose={handleClose} open={isOpen} />
      )}
    </>
  );
}
