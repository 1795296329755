import * as XLSX from "xlsx/xlsx.mjs";

export const handleExportCategoriesData = ({ productItems }) => {
  try {
    if (productItems?.length === 0) {
      console.warn("No data available to export.");
      return;
    }
    const worksheet = XLSX.utils.json_to_sheet(productItems);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "ProductCategories");
    XLSX.writeFile(workbook, "ProductCategories.xlsx");
  } catch (error) {
    console.error("Error Exporting Excel file:", error);
  }
};
