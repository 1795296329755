import React from "react";
import {
  Typography,
  FormControl,
  ListItem,
  ListItemText,
} from "@mui/material";
import Select from "react-select";
import { useSelector } from "react-redux";

const options = [
  { value: 0, label: "Yesterday" },
  { value: 1, label: "Today" },
  { value: 2, label: "Week" },
  { value: 3, label: "Month" },
  { value: 4, label: "Year" },
];
function ReusableCardHeader({ title }) {
  const filter = useSelector((state) => state.dashboard.filter);
  return (
    <ListItem
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <ListItemText
        primary={
          <Typography
            variant="h6"
            sx={{
              fontWeight: "bold",
              fontSize: "1rem",
            }}
          >
            {title}
          </Typography>
        }
      />
      <FormControl
        style={{ width: "30%", backgroundColor: "white" }}
        variant="outlined"
      >
        <Select
          className="item"
          styles={{ width: "100px" }}
          value={options[filter]}
          options={options}
        />
      </FormControl>
    </ListItem>
  );
}

export default ReusableCardHeader;
