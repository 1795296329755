import { Box, Button, Stack, Typography, Avatar } from "@mui/material";
import React, { useState } from "react";
import DataTable from "react-data-table-component";
import theme from "../../Themes/Default";
import { useDispatch, useSelector } from "react-redux";
import { updateUsers } from "../../store/selectedUsersSlice";
import placeholderImage from "./assets/placeholderImage.png";
import { useLoaderData } from "react-router-dom";

const columns = [
  {
    name: "Image",
    cell: (row) => (
      <Box sx={{ padding: "5px" }}>
        <Avatar
          height="40px"
          width="40px"
          // style={{ borderRadius: "50%" }}
          alt={placeholderImage}
          // src={placeholderImage}
          src={row.profileImageUrl}
        />
      </Box>
    ),
  },
  {
    name: "firstName",
    selector: (row) => row.firstName,
    sortable: true,
  },
  {
    name: "lastName",
    selector: (row) => row.lastName,
    sortable: true,
  },
  {
    name: "Role",
    selector: (row) => row.role,
    sortable: true,
  },
  {
    name: "Email",
    selector: (row) => row.email,
    sortable: true,
  },
];

export default function UsersList() {
  const selectedusers = useSelector((state) => state.selectedUsers.users);
  const [users, setUsers] = useState([]);
  const dispatch = useDispatch();
  const data = useLoaderData();
  //  const [
  //    deleteUser,
  //    {
  //      isLoading: isdeleteUserLoading,
  //      data: deleteUserData,
  //      isSuccess: isdeleteUserSuccess,
  //      isError: isdeleteUserError,
  //      error: deleteUserError,
  //    },
  //  ] = useDeleteUserMutation();

  const handleRowSelectChange = ({ selectedRows }) => {
    dispatch(updateUsers(selectedRows.length));
    setUsers(selectedRows);
    console.log("users", data.$values);
  };

  // function deleteUserHandler() {
  //   dispatch(deleteUser())
  // }

  function cancelHandler() {
    setUsers([]);
    handleRowSelectChange({ selectedRows: [] });
  }
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "start",
        alignItems: "start",
        height: "100%",
        width: "100%",
      }}
    >
      {selectedusers > 0 && (
        <Box
          sx={{
            width: "70%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "1rem 3rem",
            textTransform: "capitalize",
            backgroundColor: theme.palette.background.dark,
            position: "absolute",
            borderRadius: "10px",
            zIndex: "999",
            bottom: 10,
          }}
        >
          <Typography variant="h3">{selectedusers} users selected</Typography>
          <Stack direction={"row"} sx={{ gap: "10px" }}></Stack>
          <Button
            onClick={cancelHandler}
            variant="contained"
            disableElevation
            color="error"
          >
            Cancel
          </Button>
        </Box>
      )}

      <Box
        sx={{ marginTop: "1.5rem", width: "100%", textTransform: "capitalize" }}
      >
        <DataTable
          columns={columns}
          data={data.$values}
          pagination
          selectableRows
          onSelectedRowsChange={handleRowSelectChange}
        />
      </Box>
    </Box>
  );
}
