import React from "react";
import { Grid,Divider } from "@mui/material";

import LeftSideProfile from "./components/LeftSideProfile";
import RightSideForm from "./components/RightSideForm";
import Header from "./components/Header";
import { useLoaderData } from "react-router-dom";

const UserProfile = () => {
  const loadedData = useLoaderData();
  
  return (
    <>
      <Divider orientation="vertical" flexItem />

      <Grid container spacing={2}>
        <Grid item sm={12} md={12} lg={12} xl={12} xxl={12}>
          <Header />
        </Grid>
        <Grid
          item
          sm={12}
          md={4}
          lg={4}
          xl={4}
          xxl={4}
          sx={{ paddingBottom: "1rem" }}
        >
          <LeftSideProfile data={loadedData.user} />
        </Grid>
        <Grid
          item
          sm={12}
          md={8}
          lg={8}
          xl={8}
          xxl={8}
          sx={{ paddingBottom: "1rem" }}
        >
          <RightSideForm data={loadedData.user} />
        </Grid>
      </Grid>
    </>
  );
};

export default UserProfile;
