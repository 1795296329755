import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import navigationReducer from "./navigationSlice";
import rightSideBarReducer from "./rightSideBarSlice";
import categoryReducer from "./categorySlice";
import dashboardReducer from "./dashboardSlice";
import itemsViewReducer from "./itemsViewSlice";
import selectedItemsReducer from "./selectedItemsSlice";
import selectedUsersReducer from "./selectedUsersSlice";
import selectedSubcategoriesReducer from "./selectedSubcategoriesSlice";
import { apiSlice } from "./apiSlice";
import authReducer from "./authSlice";
import itemReducer from "./itemSlice";
import NewProductFormDataReducer from "./admin/products/NewProductFormData";
import importProductsModalReducer from "./admin/products/importProductsModal";
import editProductModalReducer from "./admin/products/editmodal";
import deleteModalReducer from "./admin/shared/deleteModal";
import editCategoryReducer from "./admin/shared/deleteModal";
import editCategoryData from "./admin/categories/editCategory";
import newSubCategoryReducer from "./admin/subCategories/newSubCategorySlice";
import { orderReducer } from "./order-slice";

export const store = configureStore({
  reducer: {
    navigation: navigationReducer,
    rightSideBar: rightSideBarReducer,
    category: categoryReducer,
    dashboard: dashboardReducer,
    itemsView: itemsViewReducer,
    selectedItems: selectedItemsReducer,
    selectedUsers: selectedUsersReducer,
    selectedSubcategories: selectedSubcategoriesReducer,
    auth: authReducer,
    item: itemReducer,
    newProductFormData: NewProductFormDataReducer,
    importProductsModal: importProductsModalReducer,
    editProductModal: editProductModalReducer,
    deleteModal: deleteModalReducer,
    editCategory: editCategoryReducer,
    editCategoryData: editCategoryData,
    newSubCategory: newSubCategoryReducer,
    [apiSlice.reducerPath]: apiSlice.reducer,
    order: orderReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(apiSlice.middleware),
});

export const AppDispatch = store.dispatch;
export const RootState = store.getState;
setupListeners(store.dispatch);
