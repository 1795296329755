import DashboardIcon from "@mui/icons-material/Dashboard";
import MarkEmailUnreadIcon from "@mui/icons-material/MarkEmailUnread";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import LayersIcon from "@mui/icons-material/Layers";
import RestaurantMenuIcon from "@mui/icons-material/RestaurantMenu";
import { ShoppingBag } from "@mui/icons-material";
import AutoGraphIcon from "@mui/icons-material/AutoGraph";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import PrintIcon from "@mui/icons-material/Print";
import { routesEnum } from "./routes";

export const adminNavItems = [
  {
    id: 1,
    icon: DashboardIcon,
    name: "Dashboard",
    path: routesEnum.DASHBOARD,
    childName: "sales statistics",
  },

  {
    id: 2,
    icon: LayersIcon,
    name: "Categories",
    path: routesEnum.ALL_CATEGORIES,
    childName: "userAdmin",
  },
  {
    id: 3,
    icon: LayersIcon,
    name: "Products",
    path: routesEnum.ALL_PRODUCTS,
    childName: "products",
  },
  {
    id: 4,
    icon: ShoppingBag,
    name: "Ingredients",
    path: routesEnum.ALL_INGREDIENTS,
    childName: "ingredients",
  },
  {
    id: 5,
    icon: RestaurantMenuIcon,
    name: "Menu",
    childName: "Menu",
    path: routesEnum.MENU,
  },
  {
    id: 6,
    icon: AutoGraphIcon,
    name: "Transactions",
    childName: "Transactions",
    path: routesEnum.TRANSACTIONS,
  },
  {
    id: 7,
    icon: PrintIcon,
    name: "Invoices",
    childName: "Invoices",
    path: routesEnum.INVOICES,
  },
  {
    id: 8,
    icon: ManageAccountsIcon,
    name: "Users",
    childName: "Invoices",
    path: routesEnum.USERS,
  },
  {
    id: 9,
    icon: MarkEmailUnreadIcon,
    name: "Restaurant Profile",
    childName: "Restaurant Profile",
    path: routesEnum.RESTAURANT_PROFILE,
  },
  {
    id: 10,
    icon: SupportAgentIcon,
    name: "Support",
    childName: "Support",
    path: routesEnum.SUPPORT,
  },
];
