import { MenuItem, Stack, TextField, Typography } from "@mui/material";
import { ProductCard } from "../product-card";
import { useDispatch } from "react-redux";
import { setOrderType } from "../../../store/order-slice";
import { useState } from "react";

export const CategoryProductsList = ({ category }) => {
  const [type, setType] = useState("dine in");
  const dispatch = useDispatch();
  dispatch(setOrderType(type));
  const products = category.products.$values;

  return (
    <>
      <Stack
        direction={"row"}
        sx={{ width: "100%" }}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Typography variant="h1" sx={{ fontWeight: "bold" }}>
          Choose An Item to order
        </Typography>
        <TextField
          select
          label="Order Type"
          onChange={(e) => setType(e.target.value)}
          value={type}
          sx={{ width: "10rem" }}
        >
          <MenuItem value="dine in">Dine In</MenuItem>
          <MenuItem value="take away">Take Away</MenuItem>
        </TextField>
      </Stack>
      <Stack
        direction={"row"}
        sx={{ flexWrap: "wrap", paddingY: "1rem" }}
        gap={2}
      >
        {products.map((product, index) => (
          <ProductCard key={index} product={product} />
        ))}
      </Stack>
    </>
  );
};
