import { List, ListItem, ListItemText, Stack, Typography } from "@mui/material";
import theme from "../../../Themes/Default";
import { currency } from "../../../utils/currency";
import { CustomButton } from "../../../components";
import { useReactToPrint } from "react-to-print";
import { useRef } from "react";

export const OrderInvoicePreview = ({ order }) => {
  const contentToPrint = useRef(null);
  const handlePrint = useReactToPrint({
    onBeforePrint: () => console.log("before printing..."),
    onAfterPrint: () => console.log("after printing..."),
    removeAfterPrint: true,
  });
  return (
    <Stack
      sx={{
        border: "2px solid",
        borderColor: theme.palette.background.dark,
        borderRadius: "2rem",
        height: "90%",
        padding: "1rem",
      }}
      alignItems="center"
    >
      <Stack
        direction="row"
        justifyContent="space-around"
        alignItems="center"
        sx={{
          borderBottom: "2px solid",
          borderColor: theme.palette.background.dark,
          padding: "1rem",
          width: "100%",
        }}
      >
        <Typography
          variant="h1"
          sx={{
            fontWeight: "bold",
          }}
        >
          Invoice Preview
        </Typography>
        <CustomButton
          variant="contained"
          color="success"
          onClick={() => {
            handlePrint(null, () => contentToPrint.current);
          }}
        >
          Print
        </CustomButton>
      </Stack>
      <List
        sx={{
          height: "80%",
          overflowY: "auto",
          width: "302px",
          backgroundColor: "white",
        }}
        ref={contentToPrint}
        disablePadding
      >
        <ListItem>
          <ListItemText
            primaryTypographyProps={{
              sx: {
                fontWeight: "bold",
                marginY: "1rem",
                textAlign: "center",
              },
            }}
            primary="SmartPOS Invoice"
          />
        </ListItem>
        <hr />
        <ListItem>
          <ListItemText>
            Order #
            <span style={{ fontWeight: "bold", textTransform: "capitalize" }}>
              {order.orderNumber}
            </span>
          </ListItemText>
          <ListItemText>
            Type :{" "}
            <span style={{ fontWeight: "bold", textTransform: "capitalize" }}>
              {order.orderType}
            </span>
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText>
            Table :{" "}
            <span style={{ fontWeight: "bold", textTransform: "capitalize" }}>
              {order.orderType === "dine in" ? order.table : "N/A"}
            </span>
          </ListItemText>
          <ListItemText>
            Guests :
            <span style={{ fontWeight: "bold", textTransform: "capitalize" }}>
              {order.orderType === "dine in" ? order.guests : "N/A"}
            </span>
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText>
            Payment :
            <span style={{ fontWeight: "bold", textTransform: "capitalize" }}>
              {order.paymentType}
            </span>
          </ListItemText>
        </ListItem>
        <hr />
        <ListItem secondaryAction={<ListItemText>Price</ListItemText>}>
          <ListItemText>Item x Quantity</ListItemText>
        </ListItem>
        {order.orderItems.$values.map((item) => (
          <ListItem
            key={item.products.id}
            secondaryAction={
              <ListItemText
                primaryTypographyProps={{
                  sx: {
                    fontWeight: "bold",
                  },
                }}
                primary={item.products.price * item.quantity}
              />
            }
          >
            <ListItemText
              primaryTypographyProps={{
                sx: {
                  fontWeight: "bold",
                  textTransform: "capitalize",
                },
              }}
              primary={`${item.products.name} x ${item.quantity}`}
            />
          </ListItem>
        ))}
        <hr />
        <ListItem
          secondaryAction={
            <ListItemText
              primaryTypographyProps={{
                sx: {
                  fontWeight: "bold",
                },
              }}
              primary={currency(order.totalPrice - order.totalVAT)}
            />
          }
        >
          <ListItemText primary="Sub Total" />
        </ListItem>
        <ListItem
          secondaryAction={
            <ListItemText
              primaryTypographyProps={{
                sx: {
                  fontWeight: "bold",
                },
              }}
              primary={currency(order.totalVAT)}
            />
          }
        >
          <ListItemText primary="Tax" />
        </ListItem>
        <ListItem
          secondaryAction={
            <ListItemText
              primaryTypographyProps={{
                sx: {
                  fontWeight: "bold",
                },
              }}
              primary={currency(order.totalPrice)}
            />
          }
        >
          <ListItemText primary="Grand Total" />
        </ListItem>
        <ListItem sx={{ marginTop: "2rem" }}>
          <ListItemText
            primary="Powered by: Azgard Technologies"
            secondary="email: business@azgard.co.ke"
          />
        </ListItem>
      </List>
    </Stack>
  );
};
