import React, { useState } from "react";
import { Box, Stack, Typography } from "@mui/material";
import { AdminLayout } from "../../../layout";
import { useLocation, useNavigate } from "react-router";
import { useUploadProductImageMutation } from "../../../store/apiSlice";
import { CustomButton } from "../../../components";
import theme from "../../../Themes/Default";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import toast from "react-hot-toast";

export const AddProductImage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const productId = location.pathname.split("/")[2];
  const [uploadProductImage] = useUploadProductImageMutation();
  const [selectedImage, setSelectedImage] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (!file) return;

    setSelectedImage(file);

    const reader = new FileReader();
    reader.onloadend = () => {
      setPreviewImage(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const handleImageUpload = async (e) => {
    e.preventDefault();
    if (!selectedImage) return;

    const formData = new FormData();
    formData.append("images", selectedImage);

    try {
      toast.loading("Uploading image...");
      const response = await uploadProductImage({
        id: productId,
        body: formData,
      });

      if (response.data.status.toLowerCase() === "success") {
        toast.dismiss();
        toast.success("Image uploaded Successfully.");
        navigate(`/products/${productId}`);
      }
    } catch (error) {
      toast.dismiss();
      toast.error("Unable to Upload Image.");
    }
  };

  return (
    <AdminLayout title="Add Product Image">
      <Stack
        sx={{ height: "70vh", justifyContent: "center", alignItems: "center" }}
      >
        <Box
          component="form"
          encType="multipart/form-data"
          onSubmit={handleImageUpload}
          sx={{
            display: "flex",
            flexDirection: "column",
            border: "2px solid",
            borderColor: theme.palette.background.dark,
            padding: "1rem",
            borderRadius: "1rem",
            gap: "2rem",
          }}
        >
          <Box
            sx={{
              border: "2px solid",
              borderColor: theme.palette.background.dark,
              backgroundColor: theme.palette.background.dark,
              borderRadius: "10px",
              padding: "10px",
            }}
          >
            <label
              htmlFor="file-input"
              style={{ cursor: "pointer", display: "flex", gap: "1rem" }}
            >
              <Typography>Choose File</Typography> <CloudUploadIcon />
            </label>
            <input
              id="file-input"
              type="file"
              accept="image/*"
              style={{ display: "none" }}
              onChange={handleImageChange}
            />
          </Box>
          {previewImage && (
            <img
              src={previewImage}
              alt="Preview"
              style={{
                maxWidth: "100%",
                maxHeight: "300px",
                marginTop: "10px",
              }}
            />
          )}
          <CustomButton variant="contained" type="submit">
            Upload Image
          </CustomButton>
        </Box>
      </Stack>
    </AdminLayout>
  );
};
