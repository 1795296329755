import React from "react";
import ReusableStatsCard from "./ReusableStatsCard";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
function Customers() {
  return (
    <ReusableStatsCard
      icon={PersonAddAltIcon}
      title={"New Customers"}
      percentage={34.2}
      value={250}
      progressValue={34}
      progressColor={"success"}
      color={"green"}
      iconColor={"#FFCA40"}
    />
  );
}

export default Customers;
