import { Stack, Typography } from "@mui/material";
import React from "react";
import { useProductsListQuery } from "../../../store/apiSlice";

function InventorySummary() {
  const { data, isSuccess } = useProductsListQuery();
  if (isSuccess) {
    return (
      <Stack spacing={2}>
        <Typography variant="h2">Overal Inventory</Typography>
        <Stack direction="row" spacing={4} alignItems="start">
          <Stack
            spacing={1}
            sx={{
              width: "300px",
              backgroundColor: "#F5F1EB",
              padding: "20px",
              borderRadius: "10px",
            }}
          >
            <Typography>Total Sales</Typography>
            <Stack direction="row" justifyContent="space-between">
              <Stack spacing={2} justifyContent="space-between">
                <Typography
                  variant="h1"
                  sx={{ textAlign: "start", fontWeight: "600" }}
                >
                  868
                </Typography>
                <Typography variant="h4" sx={{ textAlign: "start" }}>
                  Last 7 days
                </Typography>
              </Stack>
              <Stack spacing={2} justifyContent="space-between">
                <Typography
                  variant="h1"
                  sx={{
                    textAlign: "start",
                    fontWeight: "600",
                  }}
                >
                  KES 460
                </Typography>
                <Typography variant="h4" sx={{ textAlign: "start" }}>
                  Revenue
                </Typography>
              </Stack>
            </Stack>
          </Stack>

          <Stack
            spacing={1}
            sx={{
              width: "300px",
              backgroundColor: "#F5F1EB",
              padding: "20px",
              borderRadius: "10px",
            }}
          >
            <Typography>Product Inventory</Typography>
            <Stack direction="row" justifyContent="space-between">
              <Stack spacing={2} justifyContent="space-between">
                <Typography
                  variant="h1"
                  sx={{ textAlign: "start", fontWeight: "600" }}
                >
                  {data.$values.length}
                </Typography>
                <Typography variant="h4" sx={{ textAlign: "start" }}>
                  In Stock
                </Typography>
              </Stack>
              <Stack spacing={2} justifyContent="space-between">
                <Typography
                  variant="h1"
                  sx={{
                    textAlign: "start",
                    fontWeight: "600",

                    color: "#E31B0C",
                  }}
                >
                  0
                </Typography>
                <Typography variant="h4" sx={{ textAlign: "start" }}>
                  Out of Stock
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    );
  }
}

export default InventorySummary;
