import { Box, Typography } from "@mui/material";
import React from "react";
import { Chart } from "react-google-charts";
import { currency } from "../../../utils/currency";

const TotalValueOverlay = ({ totalValue }) => (
  <div
    style={{
      position: "absolute",
      top: "50%",
      left: "50%",
      width: '100%',
      height: '100%',
      transform: "translate(-50%, -50%)",
      textAlign: "center",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      zIndex: 2,
      // backgroundColor: 'brown'
    }}
  >
    <Typography
      variant="h6"
      fontWeight="bold"
      style={{ fontSize: 28, color: "#333333" }}
    >
      {currency(totalValue)}
    </Typography>
  </div>
);

const data = [
  ["Category", "value"],
  ["Food", 11],
  ["Drinks", 2],
  ["Others", 2],
];

const options = {
  pieHole: 0.8,
  is3D: false,
  legend: { position: "bottom" },
  colors: ["#C7161E", "#FFCC3B", "#010302"],
  pieSliceText: "none",
  chartArea: { width: "100%", height: "80%" },
};

function IncomeChart() {
  return (
    <Box
      position="relative"
      sx={{
        height: "90%",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        overflow: "hidden",
      }}
    >
      <TotalValueOverlay totalValue={`${77541}`} />
      <Chart
        chartType="PieChart"
        width="100%"
        height="100%"
        data={data}
        options={options}
      />
    </Box>
  );
}

export default IncomeChart;
