import {
  Badge,
  Box,
  Breadcrumbs,
  IconButton,
  InputBase,
  Stack,
  Typography,
} from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import SearchIcon from "@mui/icons-material/Search";
import React from "react";
import styled from "@emotion/styled";
import {
  AccessTimeSharp,
  NotificationsNoneOutlined,
} from "@mui/icons-material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import theme from "../../Themes/Default";

const StyledBox = styled(Box)({
  display: "flex",
  gap: "10px",
  alignItems: "center",
});

const Search = styled("div")({
  position: "relative",
  display: "flex",
  borderRadius: "10px",
  backgroundColor: "#f0f0f0",
  "&:hover": {
    backgroundColor: "#e0e0e0",
  },
  marginLeft: 0,
  width: "100%",
});

const SearchIconWrapper = styled("div")({
  height: "100%",
  position: "absolute",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: "0 8px",
});

const StyledInputBase = styled(InputBase)({
  color: "inherit",
  width: "100%",
  paddingLeft: "32px",
});

const StyledNavLink = styled(NavLink)({
  textDecoration: "none",
  textTransform: "capitalize",
  cursor: "pointer",
});

function isUUID(segment) {
  const uuidRegex =
    /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
  return uuidRegex.test(segment);
}

function CustomBreadCrumbs() {
  const location = useLocation();
  const pathSegments = location.pathname.split("/").filter(Boolean);
  const breadcrumbs = pathSegments
    .map((segment, index) => {
      if (isUUID(segment)) {
        return null;
      }
      const url = `/${pathSegments.slice(0, index + 1).join("/")}`;
      return (
        <StyledNavLink key={index} sx={{}} to={url}>
          <Typography
            variant="h4"
            sx={{
              fontWeight: "700",
              color: theme.palette.text.main,
            }}
          >
            {segment.replace(/-/g, " ")}
          </Typography>
        </StyledNavLink>
      );
    })
    .filter(Boolean);

  return (
    <Breadcrumbs
      separator={<NavigateNextIcon fontSize="small" />}
      aria-label="breadcrumb"
    >
      {breadcrumbs.map((breadcrumb, index) => (
        <Typography sx={{}} key={index} variant="h4">
          {index === breadcrumbs.length - 1 ? (
            breadcrumb
          ) : (
            <React.Fragment>{breadcrumb}</React.Fragment>
          )}
        </Typography>
      ))}
    </Breadcrumbs>
  );
}

const StyledHeader = styled(Box)({
  backgroundColor: theme.palette.background.dark,
  padding: "0.5rem 1rem",
  width: "100%",
  gap: "5px",
});

function Header() {
  const navigate = useNavigate();
  return (
    <StyledHeader component={"header"} width="100%">
      <Stack direction="row" justifyContent="space-between">
        <Stack direction={"row"} width="100%" justifyContent="space-between">
          <StyledBox>
            <IconButton
              onClick={() => navigate(-1)}
              size="large"
              edge="start"
              sx={{ mr: 2 }}
            >
              <KeyboardBackspaceIcon />
            </IconButton>

            {/* breadcrumbs */}
            <CustomBreadCrumbs />
          </StyledBox>
          {/* search */}
          <StyledBox>
            <IconButton
              size="large"
              aria-label="show 17 new notifications"
              color="inherit"
            >
              <Badge badgeContent={1} color="primary">
                <NotificationsNoneOutlined />
              </Badge>
            </IconButton>
            <IconButton
              size="large"
              aria-label="show 17 new notifications"
              color="inherit"
            >
              <AccessTimeSharp />
            </IconButton>
            <Search>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder="Search…"
                inputProps={{ "aria-label": "search" }}
              />
            </Search>
          </StyledBox>
        </Stack>
      </Stack>
    </StyledHeader>
  );
}

export default Header;
