import { Box, CardActions, TextField, Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import { routesEnum } from "../../../utils/routes";
import { useNavigate } from "react-router";
import { useState } from "react";

export const CategoryForm = ({ handleFormSubmit, name, description }) => {
  const navigate = useNavigate();
  const [category, setCategory] = useState(name ? name : "");
  const [desc, setDesc] = useState(description ? description : "");
  const handleCategoryChange = (e) => {
    setCategory(e.target.value);
  };

  const handleDescriptionChange = (e) => {
    setDesc(e.target.value);
  };

  return (
    <Box
      sx={{
        padding: "1rem",
        borderRadius: "0.75rem",
        border: "1px solid gray",
      }}
      component={"form"}
      onSubmit={(e) => {
        e.preventDefault();
        handleFormSubmit({ name: category, updatedDescription: desc });
      }}
    >
      <TextField
        fullWidth
        margin="dense"
        label="Category Name"
        value={category}
        onChange={handleCategoryChange}
        placeholder="Food"
        sx={{ marginY: 2 }}
      />
      <TextField
        fullWidth
        margin="dense"
        label="Description"
        value={desc}
        onChange={handleDescriptionChange}
        sx={{ marginY: 2 }}
      />
      <CardActions sx={{ justifyContent: "flex-end" }}>
        <Button
          variant="outlined"
          endIcon={<CloseIcon />}
          onClick={() => {
            navigate(routesEnum.ALL_CATEGORIES);
          }}
        >
          Cancel
        </Button>
        <Button variant="contained" type="submit" endIcon={<DoneIcon />}>
          Submit
        </Button>
      </CardActions>
    </Box>
  );
};
