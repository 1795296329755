import React from "react";

//IMPORT MEDIA
import { useNavigate } from "react-router";
import { AdminLayout } from "../../../layout";
import { ProductsForm } from "../shared/ProductForm";
import { useAddProductMutation } from "../../../store/apiSlice";
import toast from "react-hot-toast";
import { routesEnum } from "../../../utils/routes";

const NewProduct = () => {
  const navigate = useNavigate();
  const [addProduct] = useAddProductMutation();

  const handleFormSubmit = async (body) => {
    try {
      const result = await addProduct({ body: body });
      toast.loading("Adding Product");
      if (result.data?.status.toLowerCase() === "success") {
        toast.dismiss();
        toast.success("Product Added successfully");
        navigate(routesEnum.ALL_PRODUCTS);
      }
      if (result.error) {
        throw new Error("Unable to add product");
      }
    } catch (error) {
      console.log(error);
      toast.dismiss();
      toast.error("Unable to add product");
    }
  };

  return (
    <AdminLayout title={"New Product"}>
      <ProductsForm handleFormSubmit={handleFormSubmit} />
    </AdminLayout>
  );
};

export default NewProduct;
