import {
  Box,
  Button,
  Card,
  List,
  ListItem,
  Stack,
  Typography,
} from "@mui/material";
import { CustomModal } from "../../../components";
import EditIcon from "@mui/icons-material/Edit";
// import { useCategoryItemsListQuery } from "../../../store/apiSlice";

export const CategoryDetailsModal = ({ categoryItems, open, handleClose }) => {
  // const {data} = useCategoryItemsListQuery()
  return (
    <CustomModal open={open} handleClose={handleClose}>
      <List>
        {categoryItems.map((categoryItem, index) => (
          <ListItem key={index}>
            <Card>
              <Box sx={{ padding: "10px" }}>
                <Stack
                  direction="row"
                  justifyContent="space-evenly"
                  spacing={6}
                  alignItems="center"
                >
                  <img
                    style={{
                      width: "70px",
                      height: "70px",
                      padding: "10px",
                      borderRadius: "5px",
                    }}
                    src={categoryItem.image}
                    alt="categoryItem"
                  />
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography>{categoryItem.name}</Typography>
                    <Typography>
                      Added Date <span>{categoryItem.dateAdded}</span>
                    </Typography>
                  </Box>
                  <Box>
                    <Typography>Price {categoryItem.price}</Typography>
                  </Box>
                  <Box>
                    <Button
                      variant="outlined"
                      sx={{
                        color: "#808080",
                        border: "1px solid #808080",
                      }}
                    >
                      <EditIcon />
                    </Button>
                  </Box>
                </Stack>
              </Box>
            </Card>
          </ListItem>
        ))}
      </List>
    </CustomModal>
  );
};
