import { Modal, Box } from "@mui/material";
import React from "react";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  bgcolor: "background.paper",
  border: "2px solid #808080",
  borderRadius: "10px",
  boxShadow: 24,
  padding: "10px",
};

export const CustomModal = ({ handleClose, open, ...rest }) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>{rest.children}</Box>
    </Modal>
  );
};
