import React from "react";
import { Box, Typography, Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

export const UploadMediaFile = ({
  onUpload, // Function to handle the file upload
  accept = "*", // Types of files to accept (e.g., 'image/*', '.pdf')
  buttonText = "Upload", // Button text, can be any text
  label = "Image", // Label text, can be anything
  ...rest
}) => {
  // Handle file change
  const handleChange = (event) => {
    const files = event.target.files;
    if (files.length > 0 && onUpload) {
      onUpload(files);
    }
  };

  return (
    <Box bgcolor="#DCDCDC" sx={{ borderRadius: 1.5, padding: 2 }} {...rest}>
      <Typography variant="body2">{label}</Typography>
      <Button
        component="label"
        role={undefined}
        variant="text"
        tabIndex={-1}
        startIcon={<CloudUploadIcon />}
        color="text"
        disableRipple
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          background: (theme) => theme.palette.background.main,
          padding: 4,
          borderRadius: 2,
        }}
      >
        <Typography>
          <span style={{ fontWeight: "bold" }}>{buttonText}</span> or drag and
          drop
        </Typography>
        <VisuallyHiddenInput
          type="file"
          onChange={handleChange}
          accept={accept}
        />
      </Button>
    </Box>
  );
};