import { Avatar, Typography, Chip } from "@mui/material";
import { ActionMenu } from "../../../features";
import { CheckBox } from "../../../components";
import moment from "moment/moment";

export const columns = [
  {
    id: "select",
    header: ({ table }) => (
      <CheckBox
        {...{
          checked: table.getIsAllRowsSelected(),
          indeterminate: table.getIsSomeRowsSelected(),
          onChange: table.getToggleAllRowsSelectedHandler(),
        }}
      />
    ),
    cell: ({ row }) => (
      <CheckBox
        {...{
          checked: row.getIsSelected(),
          disabled: !row.getCanSelect(),
          indeterminate: row.getIsSomeSelected(),
          onChange: row.getToggleSelectedHandler(),
        }}
      />
    ),
  },
  {
    header: "Image",
    accessorKey: "image",
    cell: (info) => <Avatar src={info?.getValue()} alt={info?.name} />,
  },
  {
    header: "Name",
    accessorKey: "name",
    cell: (info) => (
      <Typography sx={{ textTransform: "capitalize" }}>
        {info.getValue()}
      </Typography>
    ),
  },
  {
    header: "Price",
    accessorKey: "price",
  },
  {
    header: "Status",
    cell: ({ row }) => <Chip label="In Stock" color="success" />,
  },
  {
    header: "Date",
    accessorKey: "updatedOn",
    cell: (info) => moment(info?.getValue()).format("Do MMMM, YYYY"),
  },
  {
    header: "Action",
    accessorKey: "id",
    cell: ({ row }) => (
      <ActionMenu row={row} viewRoute={`/ingredients/${row.original.id}`} />
    ),
  },
];
